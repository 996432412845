import React from "react";
import InputSearch from "../../../common/components/InputSearch";
import { Button } from "antd";
import { JobSectorTable, AddJobSectorDrawer } from "../components";
import { useListJobSector } from "../hooks/useListJobSectors";
import { renderNavLink } from "../../../common/components/NavLink";
import { useApplicationStore } from "../../../store/applicationStore";

const ListJobSectors = () => {
  const {
    openDrawerAdd,
    jobSearch,
    handleOpenDrawerAddJobSector,
    onCloseDrawerAddJobSectors,
    handleJobSearch,

    isJobSectorsLoading,
    jobSectorData,
    handlePageChange,
    handlePageSizeChange,
    selectedPage,
    itemsPerPage,
    openDrawerEdit,
    onCloseDrawerEditJobSectors,
    handleOpenDrawerEditJobSector,
    dataToEdit,
    modalOpen,
    setModalOpen,
    handleDeleteJobSector,
    handleConfirmDelete,
  } = useListJobSector();

  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([
      renderNavLink("/admin/jobs-companies/job-roles", "Jobs"),
      renderNavLink("/admin/jobs-companies/sectors", "Sectors"),
    ]);
  }, [setLinks]);
  return (
    <div>
      <p className="text-xl font-extrabold text-backdrop mb-3">
        Manage Sectors
      </p>
      <div className="grid grid-cols-12 gap-7 mt-7">
        <div className="col-span-10">
          <InputSearch
            value={jobSearch}
            handleSearchChange={handleJobSearch}
            placeholder={"Search by name"}
          />
        </div>

        <div className="col-span-2">
          <Button
            className="bg-primary text-white w-full"
            onClick={handleOpenDrawerAddJobSector}>
            Add New Sector
          </Button>
        </div>
      </div>

      {/* TABLE */}
      <JobSectorTable
        isJobSectorsLoading={isJobSectorsLoading}
        jobSectorData={jobSectorData}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        selectedPage={selectedPage}
        itemsPerPage={itemsPerPage}
        openDrawerEdit={openDrawerEdit}
        onCloseDrawerEditJobSectors={onCloseDrawerEditJobSectors}
        handleOpenDrawerEditJobSector={handleOpenDrawerEditJobSector}
        dataToEdit={dataToEdit}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleDeleteJobSector={handleDeleteJobSector}
        handleConfirmDelete={handleConfirmDelete}
      />
      <AddJobSectorDrawer
        onClose={onCloseDrawerAddJobSectors}
        open={openDrawerAdd}
      />
    </div>
  );
};

export default ListJobSectors;

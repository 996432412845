import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

export const useHeader = () => {
  const { instance } = useMsal();
  // const activeAccount = instance.getActiveAccount();
  const handleLoginRedirect = () => {
    instance
      .loginRedirect(loginRequest)

      .catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: process.env.REACT_APP_SPA + "/logout",
    });
  };

  // const handleLoginPopup = () => {
  //   instance
  //     .loginPopup({
  //       ...loginRequest,
  //       redirectUri: 'http://localhost:3000',
  //     })
  //     .catch((error) => console.log(error))
  // }

  // const handleLogoutPopup = () => {
  //   instance.logoutPopup({
  //     mainWindowRedirectUri: '/', // redirects the top level app after logout
  //   })
  // }

  return {
    handleLogoutRedirect,
    handleLoginRedirect,
  };
};

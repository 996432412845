import React, { useState } from "react";
import InputSearch from "../../../common/components/InputSearch";
import { Button } from "antd";
import CustomHeader from "../../../common/layout/CustomHeader";
import TrainerTable from "../components/TrainerTable";
import EditTrainerDrawer from "../components/EditTrainerDrawer";
import { useTrainers } from "../hooks/useTrainers";
import { AddTrainerDrawer } from "../components";
import { useApplicationStore } from "../../../store/applicationStore";

const ListTrainers = () => {
  const [openDrawerAdd, setOpenDrawerAdd] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const { data: trainersQuery, params, handleSearch } = useTrainers();

  const handleOpenDrawerAddTrainer = () => {
    setOpenDrawerAdd(true);
  };
  const onCloseDrawerAdd = () => {
    setOpenDrawerAdd(false);
  };
  const [openDrawerEdit, setOpenDrawerEdit] = useState(false);

  const handleEditDrawerTrainer = (trainer) => {
    setSelectedTrainer(trainer);
    setOpenDrawerEdit(true);
  };
  const onCloseDrawerEdit = () => {
    setOpenDrawerEdit(false);
    setSelectedTrainer(null);
  };

  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([]);
  }, [setLinks]);

  return (
    <div>
      <div className="ml-[-16.5rem]">
        <CustomHeader />
      </div>

      <p className="text-xl font-extrabold text-backdrop mb-3">
        Manage School Admins
      </p>

      <div className="flex items-center jusitfy-start gap-x-7 mt-7">
        <div className="flex-grow">
          <InputSearch
            value={params.name}
            handleSearchChange={handleSearch}
            placeholder={"Input search text"}
          />
        </div>
        <div className="col-span-2 text-right">
          {/* <InputDropdown
            from={'career'}
            defaultValue={'Select Trainer'}
            data={memoizedDataJobLevel}
            handleChangeFilter={handleChangeFilterCareerLevel}
            selected={selectedLevel}
          /> */}
          <Button
            className="bg-primary text-white"
            onClick={handleOpenDrawerAddTrainer}
          >
            Add New
          </Button>
        </div>
      </div>

      {/* TABLE */}
      <TrainerTable
        handleEditDrawerTrainer={handleEditDrawerTrainer}
        trainers={trainersQuery.data?.data?.data}
        isLoading={trainersQuery.isLoading}
      />

      {/* <div className="text-right mt-4">
        <Pagination
        //  current={page}
        //  pageSize={limit}
        //  total={listMyskill?.list?.count || 0}
        //  onChange={(page, pageSize) =>
        //    handlePageChangePagination(page, pageSize)
        //  }
        />
      </div> */}

      {openDrawerAdd && (
        <AddTrainerDrawer open={openDrawerAdd} onClose={onCloseDrawerAdd} />
      )}

      {selectedTrainer && (
        <EditTrainerDrawer
          open={openDrawerEdit}
          onClose={onCloseDrawerEdit}
          trainer={selectedTrainer} // Pass the selected skill object here
        />
      )}
    </div>
  );
};

export default ListTrainers;

import { useState } from "react";
import { debounce } from "lodash";
import { useDeleteJobSector, useJobSectors } from "./useJobSectors";

export const useListJobSector = () => {
  const [openDrawerAdd, setOpenDrawerAdd] = useState(false);
  const [openDrawerEdit, setOpenDrawerEdit] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [jobSearch, setJobSearch] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sectorSelected, setSectorSelected] = useState(null);
  const [trainerMissing, setTrainerMissing] = useState(1);
  const [publishedLevel, setPublishedLevel] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);

  const { data: jobSectorData, isLoading: isJobSectorsLoading } = useJobSectors(
    {
      limit: itemsPerPage,
      page: selectedPage,
      name: jobSearch,
    }
  );

  const handlePageChange = (page) => {
    setSelectedPage(page);
  };
  const handlePageSizeChange = (current, size) => {
    setSelectedPage(1);
    setItemsPerPage(size);
  };

  const handleOpenDrawerAddJobSector = () => {
    setOpenDrawerAdd(true);
  };

  const onCloseDrawerAddJobSectors = () => {
    setOpenDrawerAdd(false);
  };
  const handleOpenDrawerEditJobSector = (dataToEdit) => {
    setOpenDrawerEdit(true);
    setDataToEdit(dataToEdit);
  };
  const handleFilterSelectorJobSector = (value) => {
    console.log("id", value);
    setSectorSelected(value);
  };

  const onCloseDrawerEditJobSectors = () => {
    setOpenDrawerEdit(false);
    setDataToEdit(null);
  };

  // DELETE
  const deleteJobMutate = useDeleteJobSector();

  const onDeleteSubmit = async () => {
    console.log("slug 2", dataToDelete);
    deleteJobMutate.mutate(dataToDelete);
  };

  const handleDeleteJobSector = () => {
    console.log("click delete");
    onDeleteSubmit();
    setModalOpen(false);
  };
  const handleConfirmDelete = (slug) => {
    console.log("slug 1", slug);
    setDataToDelete(slug);
    setModalOpen(true);
  };

  const handleJobSearch = debounce((value) => {
    setJobSearch(
      value?.target?.value === null ||
        value?.target?.value?.trim() === "" ||
        value?.target?.value === undefined
        ? null
        : value?.target?.value
    );
  }, 1000);

  return {
    openDrawerAdd,
    openDrawerEdit,
    jobSearch,
    handleOpenDrawerAddJobSector,
    handleOpenDrawerEditJobSector,
    onCloseDrawerAddJobSectors,
    onCloseDrawerEditJobSectors,
    dataToEdit,
    selectedPage,
    handlePageChange,
    handlePageSizeChange,
    itemsPerPage,
    setItemsPerPage,
    handleJobSearch,
    sectorSelected,
    setSectorSelected,
    trainerMissing,
    setTrainerMissing,
    publishedLevel,
    setPublishedLevel,
    jobSectorData,
    isJobSectorsLoading,
    modalOpen,
    setModalOpen,
    handleDeleteJobSector,
    handleConfirmDelete,
    handleFilterSelectorJobSector,
  };
};

import { Controller } from "react-hook-form";
import { Drawer, Input, Button, Select } from "antd";
import { useAddSkillDrawer } from "../hooks/useAddSkillDrawer";
import { useSchool } from "../hooks/useTrainers";
import { PlusOutlined } from "@ant-design/icons";
import { beforeUpload } from "../utils/imageUploadFuncs";
import { UploadWithFocus } from "../../../common/components/UploadWithFocus";

export function AddSkillDrawer({ onClose, open }) {
  const {
    handleSubmit,
    control,
    errors,
    data,
    isLoading,
    handleScroll,
    onSaveSubmit,
    addSkillMutate,
    createDataSourceMutate,
    dataSource,
    dataSourceSearch,
    setDataSourceSearch,
  } = useAddSkillDrawer(onClose);

  const schoolsQuery = useSchool();

  // this is not really being used, onChange on component is the one actually being called
  // we define it here to stop the automatic call that component does
  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess((field) => field.onChange(file));
    }, 0);
  };

  return (
    <Drawer
      closable={false}
      placement='right'
      onClose={onClose}
      open={open}>
      <p className='font-bold text-xl text-backdrop mb-5'>Add a Skill</p>
      <form onSubmit={handleSubmit(onSaveSubmit)}>
        <div className='mb-5 space-y-6'>
          <div className='flex items-center gap-5'>
            <div className='w-[102px] h-full'>
              {/* <Controller
                  name='image'
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <Upload
                      {...field}
                      // disabled={field.value}
                      showUploadList={{
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                      }}
                      beforeUpload={(file) => {
                        beforeUpload(file).then((filez) => {
                          if (filez) {
                            field.onChange(filez);
                          }
                        });
                      }}
                      onChange={async (file) => {
                        field.onChange();
                      }}
                      fileList={field.value ? [field.value] : []}
                      onRemove={() => field.onChange(null)}
                      maxCount={1}
                      customRequest={customRequest}
                      className='h-full'
                      listType='picture-card'>
                      <div>
                        {<PlusOutlined />}
                        <div
                          style={{
                            marginTop: 8,
                          }}>
                          Upload
                        </div>
                      </div>
                    </Upload>
                  )}
                /> */}
              <Controller
                name='image'
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <UploadWithFocus
                    {...field}
                    // rest of your props
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                    }}
                    beforeUpload={(file) => {
                      beforeUpload(file).then((filez) => {
                        if (filez) {
                          field.onChange(filez);
                        }
                      });
                    }}
                    onChange={async (file) => {
                      field.onChange();
                    }}
                    fileList={field.value ? [field.value] : []}
                    onRemove={() => field.onChange(null)}
                    maxCount={1}
                    customRequest={customRequest}
                    className='h-full'
                    listType='picture-card'>
                    <div>
                      {<PlusOutlined />}
                      <div
                        style={{
                          marginTop: 8,
                        }}>
                        Upload
                      </div>
                    </div>
                  </UploadWithFocus>
                )}
              />
              {errors.image && (
                <p className='text-danger w-full animate-pulse'>
                  {errors.image.message}
                </p>
              )}
            </div>
            {/* <div className='text-xl font-semibold'>Image for Skill</div> */}
          </div>

          <div className='flex flex-col items-center-justify-start gap-2'>
            <Controller
              control={control}
              name='name'
              render={({ field }) => (
                <Input
                  placeholder='Skill Name'
                  {...field}
                />
              )}
            />
            {errors.name && (
              <p className='text-red-500'>{errors.name.message}</p>
            )}
          </div>

          <div className='flex items-center gap-4'>
            <div className='flex flex-col items-center-justify-start gap-2 w-1/2'>
              <Controller
                control={control}
                className='w-full'
                name='data_source'
                render={({ field }) => (
                  <Select
                    placeholder={"Data Source"}
                    {...field}
                    loading={isLoading}
                    showSearch
                    searchValue={dataSourceSearch}
                    onSearch={(value) => {
                      setDataSourceSearch(value);
                    }}
                    notFoundContent={
                      <Button
                        className='w-full'
                        onClick={async () => {
                          const data = await createDataSourceMutate.mutateAsync(
                            { name: dataSourceSearch },
                            {
                              onSuccess: (data) => {
                                setDataSourceSearch(data?.data?.data?.name);
                              },
                            }
                          );
                          field.onChange(data);
                        }}>
                        Click To Add : {dataSourceSearch}
                      </Button>
                    }
                    onChange={async (value) => {
                      field.onChange(value);
                    }}
                    onPopupScroll={handleScroll}>
                    {dataSource?.data?.data?.map((data) => (
                      <Select.Option
                        key={data}
                        value={data}>
                        {data}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
              {errors?.group_id && (
                <p className='text-red-500'>{errors?.group_id?.message}</p>
              )}
            </div>
            <div className='flex flex-col items-center-justify-start gap-2 w-1/2'>
              <Controller
                control={control}
                className='w-full'
                name='group_id'
                render={({ field }) => (
                  <Select
                    placeholder={"Skill Group"}
                    {...field}
                    loading={isLoading}
                    onChange={(value) => field.onChange(value)}
                    onPopupScroll={handleScroll}>
                    {data?.pages.map((page) =>
                      page?.data?.data?.list.map((group) => (
                        <Select.Option
                          key={group.id}
                          value={group.id}>
                          {group.name}
                        </Select.Option>
                      ))
                    )}
                  </Select>
                )}
              />
              {errors?.group_id && (
                <p className='text-red-500'>{errors?.group_id?.message}</p>
              )}
            </div>
          </div>
          <div className='flex flex-col items-center-justify-start gap-2'>
            <Controller
              control={control}
              name='description'
              render={({ field }) => (
                <Input.TextArea
                  rows={5}
                  style={{ resize: "none" }}
                  placeholder='Description'
                  {...field}
                />
              )}
            />
            {errors.description && (
              <p className='text-red-500'>{errors.description.message}</p>
            )}
          </div>
          <div className='flex flex-col items-center-justify-start gap-2'>
            <Controller
              control={control}
              name='price_per_level'
              render={({ field }) => (
                <Input
                  placeholder='Price per level'
                  {...field}
                />
              )}
            />
            {errors.price_per_level && (
              <p className='text-red-500'>{errors.price_per_level.message}</p>
            )}
          </div>
          <div className='flex flex-col justify-start gap-2 w-full'>
            <Controller
              control={control}
              className='w-full'
              name='school_id'
              render={({ field }) => (
                <Select
                  placeholder='Select a School'
                  {...field}
                  allowClear
                  loading={isLoading}
                  onChange={(value) => field.onChange(value)}
                  onPopupScroll={handleScroll}>
                  {schoolsQuery?.data?.data?.data.map((school) => (
                    <Select.Option
                      key={school.id}
                      value={school.id}>
                      {school.name || "unkown"}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
            {errors.school_id && (
              <p className='text-red-500'>{errors.school_id.message}</p>
            )}
          </div>
        </div>
        <div className='flex items-center justify-end gap-3'>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            loading={addSkillMutate.isLoading}
            type='primary'
            className='bg-primary'
            htmlType='submit'>
            Save
          </Button>
        </div>
      </form>
    </Drawer>
  );
}

import { useQuery } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";
import { useEffect, useState } from "react";
import { debounce } from "lodash";

export const useCurrenciesReferences = () => {
  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    limit: 20,
    search: null,
  });

  const { data, isLoading } = useQuery(
    ["list-currencies-dropdown", params],
    () => httpRequest.get("/references/currencies", { params }),
    {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching currencies"
        );
      },
    }
  );

  const handleScroll = (e) => {
    const target = e.target;
    if (target instanceof HTMLElement) {
      const maxScrollPosition =
        target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;
      if (
        target.scrollTop >= maxScrollPosition &&
        !isLoading &&
        total !== dataList.length
      ) {
        setParams((prev) => ({ ...prev, page: prev.page + 1 }));
      }
    }
  };

  const handleSearch = debounce((value) => {
    setParams((prev) => ({ ...prev, search: value, page: 1 }));
  }, 1000);

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data.data.list || []);
        setTotal(data?.data.data.total_data || 0);
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        data?.data.data.list?.[data?.data.data.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(data?.data.data.list || [])]);
        setTotal(data?.data.data.total_data || 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data.data.list]);

  return {
    data: dataList,
    isLoading,
    handleScroll,
    handleSearch,
  };
};

import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { Spin, Pagination, Empty } from "antd";
import { useNavigate } from "react-router-dom";

export function JobsTable({
  isJobsLoading,
  jobsData,
  handlePageChange,
  handlePageSizeChange,
  selectedPage,
  itemsPerPage,
  clone,
}) {
  const navigate = useNavigate();

  return (
    <>
      <table className="w-full mt-6">
        <thead className="grid grid-cols-12 bg-[#D4F5C5]">
          <td className="font-bold border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-4">
            Job Name
          </td>

          <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2">
            Sector
          </td>

          <td className="font-bold border-t border-b  border-gray-300 py-3 px-5 text-backdrop col-span-2">
            Green Skills
          </td>
          <td className="font-bold border-t border-b  border-gray-300 py-3 px-5 text-backdrop col-span-2">
            Job Posts
          </td>

          <td className="font-bold border-t border-b  border-gray-300 py-3 px-5 text-backdrop col-span-1">
            Talents
          </td>

          <td className="font-bold border-t border-b border-r border-gray-300 py-3 px-5 text-backdrop col-span-1"></td>
        </thead>
        <tbody>
          <Spin spinning={isJobsLoading}>
            {/* {isJobsLoading && <div className="h-16 w-full"></div>} */}
            {(jobsData?.data?.data?.list?.length || 0) === 0 ? (
              <tr className="grid grid-cols-12">
                <td className="border-l border-b border-r col-span-12 w-full py-6">
                  <Empty />
                </td>
              </tr>
            ) : (
              jobsData?.data?.data?.list?.map((item, index) => (
                <tr className="grid grid-cols-12" key={index}>
                  <td className="font-medium border-l border-b border-gray-300 py-2 px-5 text-backdrop col-span-4 flex items-center">
                    <div className="truncate">{item?.name}</div>
                  </td>
                  <td className="font-medium border-b border-gray-300 py-2 px-5 text-backdrop col-span-2 flex items-center">
                    <div className="truncate">{item?.job_sector?.name}</div>
                  </td>

                  <td className="font-medium border-b border-gray-300 py-2 px-5 text-backdrop col-span-2 flex items-center">
                    {item?.green_skill_count}
                  </td>
                  <td className="font-medium border-b border-gray-300 py-2 px-5 text-backdrop col-span-2 flex items-center">
                    {item?.job_posts}
                  </td>
                  <td className="font-medium border-b border-gray-300 py-2 px-5 text-backdrop col-span-1">
                    {/* <div className="flex items-center justify-between"> */}
                    <p>{item?.talents}</p>

                    {/* </div> */}
                  </td>
                  <td className="font-medium border-b border-gray-300 py-2 px-5 text-backdrop col-span-1 border-r flex justify-center gap-3">
                    {/* <Button
                      disabled={clone.isLoading}
                      onClick={() => clone.mutate(item?.id)}
                    > */}
                    <CopyOutlined
                      className=" cursor-pointer"
                      onClick={() => clone.mutate(item?.id)}
                    />
                    {/* </Button> */}
                    {/* <Button
                      onClick={() => navigate(`/admin//jobs-companies/job-roles/edit/${item?.slug}`)}
                    > */}
                    <EditOutlined
                      className=" cursor-pointer"
                      onClick={() => navigate(`/admin/jobs-companies/job-roles/edit/${item?.slug}`)}
                    />
                    {/* </Button> */}
                  </td>
                </tr>
              ))
            )}
          </Spin>
        </tbody>
      </table>
      <div className="text-right mt-4">
        <Pagination
          current={selectedPage}
          onChange={handlePageChange}
          onShowSizeChange={handlePageSizeChange}
          total={jobsData?.data?.data?.total_data}
          pageSize={itemsPerPage}
          showSizeChanger
          showTotal={(total) => `Total ${total} jobs`}
          defaultPageSize={20}
          pageSizeOptions={[20, 50]}
        />
      </div>
    </>
  );
}

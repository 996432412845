import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from "react-query";
import { message } from "antd";
import httpRequest from "../../../config/config";

const addJob = (data) => {
  return httpRequest.post("/skilledin/job-roles", data);
};

const fetchJobs = (params) => {
  return httpRequest.get("/skilledin/job-roles", { params });
};

const editJobs = (data) => {
  return httpRequest.patch(`/skilledin/job-roles/${data.id}`, {
    ...data,
  });
};

const fetchJobsDetail = (slug) => {
  return httpRequest.get(`/skilledin/job-roles/${slug}`);
};

const fetchJobGroups = ({ pageParam = 1 }) => {
  return httpRequest.get("/references/job-sectors", {
    params: {
      page: pageParam,
      limit: 10,
    },
  });
};

const useJobGroup = () => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching jobs: ${error}`);
  };

  return useInfiniteQuery(
    ["job-sectors"],
    ({ pageParam }) => fetchJobGroups({ pageParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    }
  );
};

const useJobs = (params = {}) => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching jobs: ${error}`);
  };

  return useQuery(["admin-jobs", params], () => fetchJobs(params), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
};

const useJobsDetail = (slug) => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching jobs: ${error}`);
  };
  return useQuery(["admin-jobs-detail", slug], () => fetchJobsDetail(slug), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
};

const useAddJob = () => {
  const queryClient = useQueryClient();

  const handleAddSuccess = () => {
    message.success("Job successfully added");
  };

  const handleAddError = (error) => {
    message.error(`An error occurred while adding a job: ${error}`);
  };

  return useMutation((jobData) => addJob(jobData), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["admin-jobs"]);
      handleAddSuccess();
    },
    onError: handleAddError,
  });
};

const useEditJob = () => {
  const queryClient = useQueryClient();

  const handleEditSuccess = () => {
    message.success("Job successfully edited");
  };

  const handleEditError = (error) => {
    message.error(`An error occurred while editing a job: ${error}`);
  };

  return useMutation((data) => editJobs(data), {
    refetchOnWindowFocus: false,

    onSuccess: () => {
      queryClient.invalidateQueries(["admin-jobs-detail"]);
      queryClient.invalidateQueries(["admin-job"]);
      handleEditSuccess();
    },
    onError: handleEditError,
  });
};

// const useJobGroup = () => {
//   const handleFetchError = (error) => {
//     message.error(`An error occurred while fetching jobs: ${error}`);
//   };

//   return useQuery(["job-sectors"], () => fetchJobGroups(), {
//     refetchOnWindowFocus: false,
//     onError: handleFetchError,
//   });
// };

const useClone = () => {
  const queryClient = useQueryClient();
  const cloneJobs = (id) => {
    message.open({
      key: "cloning-job",
      type: "loading",
      content: "cloning Job",
    });
    return httpRequest.get(`/skilledin/job-roles/clone/${id}`);
  };

  return useMutation((id) => cloneJobs(id), {
    refetchOnWindowFocus: false,

    onSuccess: () => {
      queryClient.invalidateQueries(["admin-jobs"]);
      message.open({
        key: "cloning-job",
        type: "success",
        content: "cloning job done!",
      });
    },
    onError: (err) =>
      message.open({
        key: "cloning-job",
        type: "error",
        content: err?.response?.data?.data || "something wrong",
      }),
  });
};

export { useJobs, useAddJob, useEditJob, useJobsDetail, useJobGroup, useClone };

import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; //import styles
import "quill/dist/quill.core.css"; //import core styles
import "quill/dist/quill.bubble.css"; //import bubble theme

export const modules = {
  toolbar: [
    [{ header: [1, 2, false] }, { font: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
};
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const RichText = ({ handleChange, className, value, placeholder }) => {
  return (
    <ReactQuill
      className={className}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      modules={modules}
      formats={formats}
    />
  );
};
// export const RichText = React.forwardRef(
//   ({ handleChange, className, value, placeholder }, ref) => {
//     const quillRef = useRef(); // Create a ref

//     useEffect(() => {
//       if (ref) {
//         console.log(ref);
//         ref.current = quillRef.current.getEditor(); // Assign the Quill instance to the ref passed from Controller
//       }
//     }, [ref]);

//     return (
//       <ReactQuill
//         className={className}
//         onChange={handleChange}
//         value={value}
//         placeholder={placeholder}
//         modules={modules}
//         formats={formats}
//         ref={quillRef} // Attach the ref
//       />
//     );
//   }
// );

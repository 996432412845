import httpRequest from "../../../config/config";
import { message } from "antd";
import { useQuery } from "react-query";

const fetchTalentAnalytics = ({
  pageParam = 1,
  search,
  order_by,
  countries,
}) => {
  return httpRequest.get("/skilledin/talent-analytics", {
    params: {
      name: search,
      page: pageParam,
      limit: 100,
      order_by,
      countries,
    },
  });
};

export const useTalentAnalytics = (params) => {
  const handleFetchError = (error) => {
    message.error(
      `An error occurred while fetching talent analytics: ${error}`
    );
  };

  return useQuery(
    ["admin-talent-analytics", params],
    () => fetchTalentAnalytics(params),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    }
  );
};

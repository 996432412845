import React from "react";
import { Button, Table } from "antd";

const TrainerTable = ({ trainers, handleEditDrawerTrainer, isLoading }) => {
  const columns = [
    {
      title: "Admin Name",
      dataIndex: "display_name",
      key: "display_name",
      render: (text) => <div className="font-medium text-backdrop">{text}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <div className="font-medium text-backdrop">{text}</div>,
    },
    {
      title: "School",
      dataIndex: "school",
      key: "school",
      render: (text) => (
        <div className="font-medium text-backdrop">{text?.name ?? "-"}</div>
      ),
    },
    {
      title: "Assigned Skills",
      dataIndex: "school",
      key: "skills",
      render: (text) => (
        <div className="font-medium text-backdrop">
          {text?.total_assigned_skill ?? "-"}
        </div>
      ),
    },
    {
      title: "School's Profit",
      dataIndex: "school",
      key: "school_profit",
      render: (text) => (
        <div className="font-medium text-backdrop">
          {text?.sharing_profit ? text?.sharing_profit + "%" : "-"}
        </div>
      ),
    },
    {
      title: "Trainers",
      dataIndex: "school",
      key: "trainers",
      render: (text) => (
        <div className="font-medium text-backdrop">
          {text?.total_trainers ?? "-"}
        </div>
      ),
    },
    // {
    //   title: "Skill Teaching",
    //   dataIndex: "total_teaching_skill",
    //   key: "total_teaching_skill",
    //   render: (text) => (
    //     <div className='font-medium text-backdrop'>{text ?? "None"}</div>
    //   ),
    // },
    // {
    //   title: "Contract Expiry",
    //   dataIndex: "contract_expired.datetime",
    //   key: "contract_expired.datetime",
    //   render: (text) => (
    //     <div className='font-medium text-backdrop'>
    //       {text
    //         ? new Intl.DateTimeFormat("en-US", {
    //             year: "numeric",
    //             month: "long",
    //             day: "numeric",
    //           }).format(new Date(text))
    //         : "Not set"}
    //     </div>
    //   ),
    // },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div className="flex items-center justify-between">
          <Button onClick={() => handleEditDrawerTrainer(record)}>Edit</Button>
        </div>
      ),
    },
  ];
  return (
    <div className="admin-table mt-6">
      <Table
        dataSource={trainers}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
    </div>
  );
};

export default TrainerTable;

import React from "react";
import { Button, Divider, Pagination, Spin } from "antd";
import { useApplicationStore } from "../../../store/applicationStore";
import { LoadingOutlined } from "@ant-design/icons";
import { useMailing } from "../hooks/useMailing";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export const ListEmail = () => {
  const { setLinks } = useApplicationStore();
  React.useEffect(() => {
    setLinks([]);
  }, [setLinks]);
  const { data, isLoading } = useMailing();
  const navigate = useNavigate();
  return (
    <div>
      <div className=" flex justify-between items-center mb-5">
        <h1 className="m-0 text-2xl font-semibold">Email</h1>
        <Button
          className=""
          type="primary"
          onClick={() => navigate("/admin/marketing/mail/add")}
        >
          New Email
        </Button>
      </div>
      <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
        <div className=" bg-white rounded-xl shadow-md p-5">
          <div>
            <div className=" grid grid-cols-6 px-2 gap-5 text-darkGrey">
              <div className=" col-span-2">Email title</div>
              <div className=" col-span-1">Audience</div>
              <div className=" col-span-1">Sent | Fail | Skip</div>
              <div className=" col-span-1">Data Sent</div>
              <div className=" col-span-1"></div>
            </div>
            <Divider className=" my-3 border-1 border-darkGrey" />
          </div>
          {data?.total_data > 0 ? (
            data?.list?.map((email, i) => (
              <div key={i}>
                <div className={` grid grid-cols-6  gap-5 px-2`}>
                  <div className=" col-span-2">{email?.subject}</div>
                  <div className=" col-span-1 capitalize">{`${
                    email?.target?.app || "unknown"
                  } - ${email?.target?.audience || "unknown"}`}</div>
                  <div className=" col-span-1 capitalize">
                    <div>{`${email?.log?.success} | ${email?.log?.failed} | ${email?.log?.skipped}`}</div>
                    <div className=" mt-2 capitalize">
                      {email?.status.toLowerCase()}
                    </div>
                  </div>
                  <div className=" col-span-1">
                    {dayjs(email?.created_at).format("DD MMM YYYY")}
                  </div>
                  <div className=" col-span-1 text-right">
                    <Button
                      onClick={() =>
                        navigate(`/admin/marketing/mail/detail/${email?.id}`)
                      }
                    >
                      View
                    </Button>
                  </div>
                </div>
                <Divider className=" my-3 border-1 border-backdrop" />
              </div>
            ))
          ) : (
            <div>No new email</div>
          )}
        </div>
      </Spin>
      <div className=" text-right my-5">
        <Pagination
          defaultCurrent={data?.pagination?.page || 1}
          current={data?.pagination?.page || 1}
          total={data?.total_data}
          hideOnSinglePage
        />
      </div>
    </div>
  );
};

import { Radio, Space } from "antd";
import React from "react";

export const CardQuestion = ({ name, existingData, newData }) => {
  return (
    <div className="my-5">
      <p className="capitalize mb-3">{name}</p>
      <div className=" grid grid-cols-2 gap-5 ">
        <div className="border min-h-[80px] rounded-sm p-2">
          {existingData ? (
            <>
              <div>
                <p className=" first-letter:capitalize">question:</p>
                <p
                  className=" first-letter:capitalize"
                  dangerouslySetInnerHTML={{ __html: existingData?.question }}
                ></p>
              </div>
              <div className="mt-2">
                <p className=" first-letter:capitalize">answer:</p>
                <Radio.Group
                  value={
                    existingData?.answers?.find((itm) => itm.correct_answer)
                      ?.answer
                  }
                >
                  <Space direction="vertical">
                    {existingData?.answers?.map((item, index) => (
                      <Radio
                        key={index}
                        className=" first-letter:capitalize"
                        value={item?.answer}
                      >
                        {item?.answer}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </div>
            </>
          ) : (
            "--none--"
          )}
        </div>
        <div className="border min-h-[80px] rounded-sm p-2">
          <div className="">
            <p className=" first-letter:capitalize">question:</p>
            <p
              className=" first-letter:capitalize"
              dangerouslySetInnerHTML={{ __html: newData?.question }}
            ></p>
          </div>
          <div className="mt-2">
            <p className=" first-letter:capitalize">answer:</p>
            <Radio.Group
              value={
                newData?.answers?.find((itm) => itm.correct_answer)?.answer
              }
              className=""
            >
              <Space direction="vertical">
                {newData?.answers?.map((item, index) => (
                  <Radio
                    className=" first-letter:capitalize"
                    value={item?.answer}
                    key={index}
                  >
                    {item?.answer}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import httpRequest from "../../../config/config";
import { debounce } from "lodash";
import { useAddJob } from "./useJobs";
import { useNavigate } from "react-router-dom";
import { useAddOtherSkill } from "./useSkills";
import { message } from "antd";

export const useJobFormV2Add = () => {
  const navigate = useNavigate();
  const {
    dataList: dataListGS,
    isLoading: isLoadingGS,
    handleSearch: handleSearchGS,
    handleClear: handleClearGS,
    handleScroll: handleScrollGS,
    params: paramsGS,
  } = useGreenSkill();

  const {
    dataList: dataListTS,
    isLoading: isLoadingTS,
    handleSearch: handleSearchTS,
    handleClear: handleClearTS,
    handleScroll: handleScrollTS,
    params: paramsTS,
  } = useTechnicalSkill();

  const {
    dataList: dataListCS,
    isLoading: isLoadingCS,
    handleSearch: handleSearchCS,
    handleClear: handleClearCS,
    handleScroll: handleScrollCS,
    params: paramsCS,
  } = useCriticalSkill();

  const {
    dataList: dataListJS,
    isLoading: isLoadingJS,
    handleSearch: handleSearchJS,
    handleClear: handleClearJS,
    handleScroll: handleScrollJS,
    params: paramsJS,
  } = useSector();

  const addJobMutate = useAddJob();

  const addOtherSkillMutate = useAddOtherSkill();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({});

  //   <========== state start ==========>
  const [greenSkillData, setGreenSkillData] = useState([]);
  const [criticalSkillData, setCriticalSkillData] = useState([]);
  const [otherSkillData, setOtherSkillData] = useState([]);
  const [jobDetailText, setJobDetailText] = useState("");
  const [options, setOption] = useState({
    GS: [],
    CS: [],
    TS: [],
  });

  //   <========== state end ==========>

  const onSaveSubmit = async (data) => {
    data.green_skills = greenSkillData;
    data.critical_skills = criticalSkillData;
    data.other_skills = otherSkillData;
    data.job_detail = jobDetailText;
    data.slug = data?.name?.toLocaleLowerCase()?.replaceAll(" ", "_");

    await addJobMutate.mutateAsync(data);
    navigate("/admin/jobs-companies/job-roles");
  };

  //   <========== green skill start ==========>
  const handleSetValueGS = (value, id) => {
    const updatedData = greenSkillData.map((item) => {
      if (item.id === id) {
        return { ...item, level: parseInt(value) };
      }
      return item;
    });
    setGreenSkillData(updatedData);
  };

  const handleSelectGS = (value) => {
    const formatedData = {
      id: value.value,
      name: value.label,
      level: 1,
    };
    setGreenSkillData((prevSelectedItems) => [
      ...prevSelectedItems,
      formatedData,
    ]);
  };

  const handleDeleteGS = (id) => {
    const updatedData = greenSkillData.filter((item) => item.id !== id);
    setGreenSkillData(updatedData);
  };

  useEffect(() => {
    // if (dataListGS?.length > 0) {
    setOption((prev) => ({
      ...prev,
      GS: dataListGS?.map((item) => ({
        ...item,
        disabled: greenSkillData?.find((gs) => gs?.id === item?.id),
      })),
    }));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListGS, greenSkillData]);
  //   <========== green skill end ==========>

  //   <========== technical skill start ==========>
  const handleDeleteTS = (id) => {
    const updatedData = otherSkillData.filter((item) => item.id !== id);
    setOtherSkillData(updatedData);
  };
  const handleChangeLevelTS = (id, value) => {
    const updatedData = otherSkillData.map((item) => {
      if (item.id === id) {
        return { ...item, level: parseInt(value.target.value || 0) };
      }
      return item;
    });

    setOtherSkillData(updatedData);
  };
  const handleSelectTS = (value) => {
    const formatedData = {
      id: value.value,
      name: value.label,
      level: 1,
    };
    setOtherSkillData((prevSelectedItems) => [
      ...prevSelectedItems,
      formatedData,
    ]);
  };

  const handleAddTS = async (e) => {
    message.open({
      type: "loading",
      key: "addOtherSkill",
      content: "Loading...",
    });
    await addOtherSkillMutate
      .mutateAsync(e)
      .then((res) => {
        handleClearTS();
        setOtherSkillData((prevSelectedItems) => [
          ...prevSelectedItems,
          { id: res?.data?.[0]?.id, name: res?.data?.[0]?.name, level: 1 },
        ]);
      })
      .catch((err) => {
        return err;
      });
  };
  useEffect(() => {
    // if (dataListTS?.length > 0) {
    setOption((prev) => ({
      ...prev,
      TS: dataListTS?.map((item) => ({
        ...item,
        disabled: otherSkillData?.find((os) => os?.id === item?.id),
      })),
    }));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListTS, otherSkillData]);
  //   <========== technical skill end ==========>

  //   <========== critical skill start ==========>
  const handleDeleteCS = (id) => {
    const updatedData = criticalSkillData.filter((item) => item.id !== id);
    setCriticalSkillData(updatedData);
  };
  const handleChangeLevelCS = (id, value) => {
    const updatedData = criticalSkillData.map((item) => {
      if (item.id === id) {
        return { ...item, level: value };
      }
      return item;
    });

    setCriticalSkillData(updatedData);
  };

  const handleSelectCS = (value) => {
    const formatedData = {
      id: value.value,
      name: value.label,
      level: 1,
    };
    setCriticalSkillData((prevSelectedItems) => [
      ...prevSelectedItems,
      formatedData,
    ]);
  };
  useEffect(() => {
    // if (dataListCS?.length > 0) {
    setOption((prev) => ({
      ...prev,
      CS: dataListCS?.map((item) => ({
        ...item,
        disabled: criticalSkillData?.find((cs) => cs?.id === item?.id),
      })),
    }));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListCS, criticalSkillData]);
  //   <========== technical skill end ==========>

  return {
    greenSkills: {
      data: options?.GS,
      isLoading: isLoadingGS,
      handleSearch: handleSearchGS,
      handleClear: handleClearGS,
      handleScroll: handleScrollGS,
      params: paramsGS,
      handleSetValueGS,
      handleSelectGS,
      handleDeleteGS,
    },
    technicalSkills: {
      data: options?.TS,
      isLoading: isLoadingTS,
      handleSearch: handleSearchTS,
      handleClear: handleClearTS,
      handleScroll: handleScrollTS,
      params: paramsTS,
      handleDeleteTS,
      handleSelectTS,
      handleChangeLevelTS,
      handleAddTS,
    },
    criticalSkills: {
      data: options.CS,
      isLoading: isLoadingCS,
      handleSearch: handleSearchCS,
      handleClear: handleClearCS,
      handleScroll: handleScrollCS,
      params: paramsCS,
      handleDeleteCS,
      handleSelectCS,
      handleChangeLevelCS,
    },
    jobSector: {
      data: dataListJS,
      isLoading: isLoadingJS,
      handleSearch: handleSearchJS,
      handleClear: handleClearJS,
      handleScroll: handleScrollJS,
      params: paramsJS,
    },
    detail: {
      jobDetailText,
      setJobDetailText,
      onSaveSubmit,
      greenSkillData,
      technicalSkillData: otherSkillData,
      criticalSkillData,
      isLoadingAdd: addJobMutate.isLoading,
    },
    hookForm: {
      handleSubmit,
      control,
      errors,
      setValue,
    },
  };
};

const useGreenSkill = () => {
  const [params, setParams] = useState({
    limit: 20,
    page: 1,
    name: null,
  });
  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);

  const { data, isLoading } = useQuery(
    ["green-skills-list-dropdown", params],
    () =>
      httpRequest.get("references/green-skills", {
        params,
      })
  );
  const handleSearch = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, name: e, page: 1 }));
  }, 1000);

  const handleClear = (e) => {
    if (params?.page !== 1 || params?.name !== "") {
      setParams((prevParams) => ({ ...prevParams, name: "", page: 1 }));
    }
  };

  const handleScroll = (e) => {
    const { target } = e;
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };
  useEffect(() => {
    if (!isLoading) {
      if (params?.page === 1) {
        setDataList(data?.data?.data?.list);
        setTotal(data?.data?.data?.total_data);
      } else {
        setDataList((prev) => [...prev, ...(data?.data?.data?.list || [])]);
        setTotal(data?.data?.data?.total_data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.data?.list]);
  return {
    dataList,
    isLoading,
    handleSearch,
    handleClear,
    handleScroll,
    params,
    setDataList,
  };
};

const useTechnicalSkill = () => {
  const [params, setParams] = useState({
    limit: 20,
    page: 1,
    name: null,
  });
  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);

  const { data, isLoading } = useQuery(
    ["other-skills-list-dropdown", params],
    () =>
      httpRequest.get("references/other-skills", {
        params,
      })
  );
  const handleSearch = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, name: e, page: 1 }));
  }, 1000);

  const handleClear = (e) => {
    if (params?.page !== 1 || params?.name !== "") {
      setParams((prevParams) => ({ ...prevParams, name: "", page: 1 }));
    }
  };

  const handleScroll = (e) => {
    const { target } = e;
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };
  useEffect(() => {
    if (!isLoading) {
      if (params?.page === 1) {
        setDataList(data?.data?.data?.list);
        setTotal(data?.data?.data?.total_data);
      } else {
        setDataList((prev) => [...prev, ...(data?.data?.data?.list || [])]);
        setTotal(data?.data?.data?.total_data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.data?.list]);
  return {
    dataList,
    setDataList,
    isLoading,
    handleSearch,
    handleClear,
    handleScroll,
    params,
  };
};

const useSector = () => {
  const [params, setParams] = useState({
    limit: 20,
    page: 1,
    name: null,
  });
  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);

  const { data, isLoading } = useQuery(
    ["job-sectors-list-dropdown", params],
    () =>
      httpRequest.get("/skilledin/job-sectors", {
        params,
      })
  );
  const handleSearch = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, name: e, page: 1 }));
  }, 1000);

  const handleClear = (e) => {
    if (params?.page !== 1 || params?.name !== "") {
      setParams((prevParams) => ({ ...prevParams, name: "", page: 1 }));
    }
  };

  const handleScroll = (e) => {
    const { target } = e;
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };
  useEffect(() => {
    if (!isLoading) {
      if (params?.page === 1) {
        setDataList(data?.data?.data?.list);
        setTotal(data?.data?.data?.total_data);
      } else {
        setDataList((prev) => [...prev, ...(data?.data?.data?.list || [])]);
        setTotal(data?.data?.data?.total_data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.data?.list]);

  useEffect(() => {
    if (total > dataList.length) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);
  return {
    dataList,
    isLoading,
    handleSearch,
    handleClear,
    handleScroll,
    params,
  };
};

const useCriticalSkill = () => {
  const [params, setParams] = useState({
    limit: 20,
    page: 1,
    name: null,
  });
  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);

  const { data, isLoading } = useQuery(
    ["critical-skills-list-dropdown", params],
    () =>
      httpRequest.get("/references/critical-skills", {
        params,
      })
  );
  const handleSearch = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, name: e, page: 1 }));
  }, 1000);

  const handleClear = (e) => {
    if (params?.page !== 1 || params?.name !== "") {
      setParams((prevParams) => ({ ...prevParams, name: "", page: 1 }));
    }
  };

  const handleScroll = (e) => {
    const { target } = e;
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };
  useEffect(() => {
    if (!isLoading) {
      if (params?.page === 1) {
        setDataList(data?.data?.data?.list);
        setTotal(data?.data?.data?.total_data);
      } else {
        setDataList((prev) => [...prev, ...(data?.data?.data?.list || [])]);
        setTotal(data?.data?.data?.total_data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.data?.list]);
  return {
    dataList,
    setDataList,
    isLoading,
    handleSearch,
    handleClear,
    handleScroll,
    params,
  };
};

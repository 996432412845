import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from "react-query";
import { message } from "antd";
import httpRequest from "../../../config/config";
import { useEffect, useState } from "react";
import { debounce } from "lodash";

const addSkill = (data) => {
  message.open({
    key: "add-skill",
    type: "loading",
    content: "Adding skill...",
  });
  return httpRequest.post("skilledin/green-skills", data);
};
const addOtherSkill = async (data) => {
  try {
    const response = await httpRequest.post("/references/other-skills", {
      skills: data,
    });
    return response.data; // Return the response data
  } catch (error) {
    throw error;
  }
};

const fetchSkills = (params) => {
  return httpRequest.get("skilledin/green-skills", { params });
};

const editSkills = (data) => {
  message.open({
    key: "update-skill",
    type: "loading",
    content: "Updating skill...",
  });
  return httpRequest.patch(`skilledin/green-skills/${data.id}`, data.skillData);
};
const setfeatureSkill = (data) => {
  message.open({
    key: "set-feature-skill",
    type: "loading",
    content: "Set feature skill...",
  });
  return httpRequest.patch(
    `skilledin/green-skills/feature-skill/${data.id}`,
    data.id
  );
};

const fetchSkillsDetail = (id) => {
  return httpRequest.get(`skilledin/green-skills/${id}`);
};

const fetchSkillGroups = ({ pageParam = 1 }) => {
  return httpRequest.get("skilledin/green-skill-groups", {
    params: {
      page: pageParam,
      limit: 10,
    },
  });
};
const fetchGreenSkills = ({ pageParam, search }) => {
  return httpRequest.get("skilledin/green-skills", {
    params: {
      search: search,
      page: pageParam,
      limit: 20,
    },
  });
};
const fetchCriticalSkills = ({ pageParam = 1, search }) => {
  console.log("searchX", search);
  return httpRequest.get("/references/critical-skills", {
    params: {
      name: search,
      page: pageParam,
      limit: 10,
    },
  });
};
const fetchOtherSkills = ({ pageParam = 1, search }) => {
  console.log("pageParam", pageParam);
  return httpRequest.get("/references/other-skills", {
    params: {
      name: search,
      page: pageParam,
      limit: 20,
    },
  });
};

const fetchDataSource = () => {
  return httpRequest.get("/skilledin/green-skill-data-sources");
};

const createDataSource = (data) => {
  return httpRequest.post("/skilledin/green-skill-data-sources", data);
};

export const useDataSource = () => {
  const handleFetchError = (error) => {
    message.error(
      `An error occurred while fetching skills: ${error?.response?.data?.data}`
    );
  };

  return useQuery(["green-skill-data-sources"], () => fetchDataSource(), {
    refetchOnWindowFocus: false,

    onError: handleFetchError,
  });
};

export const useCreateDataSource = () => {
  const queryClient = useQueryClient();

  const handleAddSuccess = () => {
    message.open({
      key: "add-data-source",
      type: "success",
      content: "Data source successfully added",
    });

    queryClient.invalidateQueries(["green-skill-data-sources"]);
  };

  const handleAddError = (error) => {
    message.open({
      key: "add-data-source",
      type: "error",
      content: error?.response?.data?.message || error?.message,
    });
  };

  return useMutation((data) => createDataSource(data), {
    refetchOnWindowFocus: false,
    onSuccess: handleAddSuccess,
    onError: handleAddError,
  });
};

const useGreenSkill = (searchParam) => {
  const handleFetchError = (error) => {
    message.error(
      `An error occurred while fetching skills: ${error?.response?.data?.data}`
    );
  };

  return useInfiniteQuery(
    ["green-skills-list", searchParam],
    ({ pageParam }) => fetchGreenSkills({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    }
  );
};
const useCriticalSkill = (searchParam) => {
  const handleFetchError = (error) => {
    message.error(
      `An error occurred while fetching skills: ${error?.response?.data?.data}`
    );
  };

  return useInfiniteQuery(
    ["critical-skills-list", searchParam],
    ({ pageParam }) => fetchCriticalSkills({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    }
  );
};
const useOtherSkill = (searchParam) => {
  console.log("search", searchParam);
  const handleFetchError = (error) => {
    message.error(
      `An error occurred while fetching skills: ${error?.response?.data?.data}`
    );
  };

  return useInfiniteQuery(
    ["other-skills-list", searchParam],
    ({ pageParam }) => fetchOtherSkills({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    }
  );
};

const useSkillGroupInfinite = () => {
  const handleFetchError = (error) => {
    message.error(
      `An error occurred while fetching skills: ${error?.response?.data?.data}`
    );
  };

  return useInfiniteQuery(
    ["skill-groups"],
    ({ pageParam }) => fetchSkillGroups({ pageParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.pagination?.page < lastPage?.pagination?.total_page
          ? lastPage?.pagination?.page + 1
          : undefined,
    }
  );
};

const useSkills = (params = {}) => {
  const handleFetchError = (error) => {
    message.error(
      `An error occurred while fetching skills: ${error?.response?.data?.data}`
    );
  };

  return useQuery(["admin-skills", params], () => fetchSkills(params), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
};

const useSkillsDetail = (id) => {
  const handleFetchError = (error) => {
    message.error(
      `An error occurred while fetching skills: ${error?.response?.data?.data}`
    );
  };
  return useQuery(["admin-skills-detail", id], () => fetchSkillsDetail(id), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
};

const useAddSkill = () => {
  const queryClient = useQueryClient();

  const handleAddSuccess = () => {
    message.open({
      key: "add-skill",
      type: "success",
      content: "Skill successfully added",
    });
  };

  const handleAddError = (error) => {
    message.open({
      key: "add-skill",
      type: "error",
      content: error?.response?.data?.message || error?.message,
    });
  };

  return useMutation((skillData) => addSkill(skillData), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["admin-skills"]);
      handleAddSuccess();
    },
    onError: handleAddError,
  });
};

const useAddOtherSkill = () => {
  const queryClient = useQueryClient();

  const handleAddSuccess = () => {
    message.open({
      key: "addOtherSkill",
      content: "techincal skill successfully added",
      type: "success",
    });
  };

  const handleAddError = (error) => {
    message.open({
      key: "addOtherSkill",
      content: error?.response?.data?.message || error?.message,
      type: "error",
    });
  };

  return useMutation((skillData) => addOtherSkill(skillData), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["other-skills-list"]);
      queryClient.invalidateQueries(["  other-skills-list-dropdown"]);

      handleAddSuccess();
      return data;
    },
    onError: handleAddError,
  });
};

const useEditSkill = () => {
  const queryClient = useQueryClient();

  const handleEditSuccess = () => {
    message.open({
      key: "update-skill",
      type: "success",
      content: "Skill successfully updated",
    });
  };

  const handleEditError = (error) => {
    message.open({
      key: "update-skill",
      type: "error",
      content: `An error occurred while editing a skill: ${error?.response?.data?.data}`,
    });
  };

  return useMutation((data) => editSkills(data), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["admin-skills-detail"]);
      queryClient.refetchQueries("admin-skills");
      handleEditSuccess();
    },
    onError: handleEditError,
  });
};
const useSetFeaturekill = () => {
  const queryClient = useQueryClient();

  const handleSetFeatureSuccess = () => {
    message.open({
      key: "set-feature-skill",
      type: "success",
      content: "Skill successfully updated",
    });
  };

  const handleSetFeatureError = (error) => {
    message.open({
      key: "set-feature-skill",
      type: "error",
      content: `An error occurred while editing a skill: ${error?.response?.data?.data}`,
    });
  };

  return useMutation((data) => setfeatureSkill(data), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      handleSetFeatureSuccess();
      queryClient.invalidateQueries("admin-skills");
    },
    onError: handleSetFeatureError,
  });
};

// const useSkillGroupInfinite = () => {
//   const handleFetchError = (error) => {
//     message.error(`An error occurred while fetching skills: ${error?.response?.data?.data}`);
//   };

//   return useQuery(["skill-groups"], () => fetchSkillGroups(), {
//     refetchOnWindowFocus: false,
//     onError: handleFetchError,
//   });
// };

const useGreenSkillForAdmin = () => {
  const [params, setParams] = useState({
    limit: 20,
    page: 1,
    search: null,
    school_not_assigned: "1",
    with_owned_skill: "",
  });
  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);

  const { data, isLoading } = useQuery(["green-skills-list-add", params], () =>
    httpRequest.get("skilledin/green-skills", {
      params,
    })
  );
  const handleSearch = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, search: e, page: 1 }));
    setDataList([]);
  }, 1000);
  const handleClear = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, search: "", page: 1 }));
    setDataList([]);
  }, 500);

  const handleOwnesSkill = (id) => {
    setParams((prev) => ({ ...prev, with_owned_skill: id }));
    setDataList([]);
  };

  const handleScroll = (e) => {
    const { target } = e;
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };
  useEffect(() => {
    if (data?.data?.data?.list) {
      setDataList((prev) => [...prev, ...data?.data?.data?.list]);
      setTotal(data?.data?.data?.total_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.data?.list]);
  return {
    params,
    total,
    dataList,
    setDataList,
    setParams,
    handleSearch,
    isLoading,
    handleScroll,
    handleClear,
    handleOwnesSkill,
  };
};

const useGreenSkillForAdminEdit = () => {
  const [params, setParams] = useState({
    limit: 20,
    page: 1,
    search: null,
    school_not_assigned: "1",
    // with_owned_skill: "",
  });
  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);

  const { data, isLoading } = useQuery(["green-skills-list", params], () =>
    httpRequest.get("skilledin/green-skills", {
      params,
    })
  );
  const handleSearch = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, search: e, page: 1 }));
    setDataList([]);
  }, 1000);
  const handleClear = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, search: "", page: 1 }));
    setDataList([]);
  }, 500);

  const handleOwnesSkill = (id) => {
    setParams((prev) => ({ ...prev, with_owned_skill: id }));
    setDataList([]);
  };

  const handleScroll = (e) => {
    const { target } = e;
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };
  useEffect(() => {
    if (data?.data?.data?.list) {
      setDataList((prev) => [...prev, ...data?.data?.data?.list]);
      setTotal(data?.data?.data?.total_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.data?.list]);
  return {
    params,
    total,
    dataList,
    setDataList,
    setParams,
    handleSearch,
    isLoading,
    handleScroll,
    handleClear,
    handleOwnesSkill,
  };
};

export {
  useSkills,
  useAddSkill,
  useEditSkill,
  useSetFeaturekill,
  useSkillsDetail,
  useSkillGroupInfinite,
  useGreenSkill,
  useCriticalSkill,
  useOtherSkill,
  useAddOtherSkill,
  useGreenSkillForAdmin,
  useGreenSkillForAdminEdit,
};

import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";
import { useEffect } from "react";

export const usePolicies = (kind = "privacy", app = "talent") => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { policy: [{ title: "", body: "" }] },
  });
  const fieldArray = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "policy", // unique name for your Field Array
  });
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(`${kind}-${app}`, () =>
    httpRequest.get(`/skilledin//policies/${kind}/${app}`, {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching policies"
        );
      },
    })
  );
  useEffect(() => {
    console.log(data?.data?.data?.contents);
    if (data?.data?.data?.contents?.length > 0) {
      const fixData = data?.data?.data?.contents.sort((a, b) => {
        return a - b;
      });
      reset({
        policy: fixData.map((item) => ({ title: item.title, body: item.body })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.data?.contents, kind, app]);

  const { mutateAsync, isLoading: isLoadingMutate } = useMutation(
    `${kind}-${app}-update`,
    (data) => {
      return httpRequest.put(`/skilledin/policies/${kind}/${app}`, data);
    }
  );

  const handleUpdate = async (data) => {
    const fixData = data.policy.map((item, index) => ({
      ...item,
      order: index,
    }));
    try {
      await mutateAsync({ kind, app, contents: fixData });
      message.success("Policies updated successfully");
      queryClient.invalidateQueries(`${kind}-${app}`);
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "An error occurred while updating policies"
      );
    }
  };

  return {
    fieldArray,
    control,
    handleSubmit,
    errors,
    data: data?.data?.data,
    isLoading,
    action: {
      handleUpdate,
      isLoading: isLoadingMutate,
    },
  };
};

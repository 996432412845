import React from "react";
import { renderNavLink } from "../../../common/components/NavLink";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Controller } from "react-hook-form";
import { Button, Empty, Input, Select, Skeleton } from "antd";
import TextArea from "antd/es/input/TextArea";
import DotList from "../../../common/components/DotList";
import { RichText } from "../../../common/components/RichText";
import { useJobFormV2 } from "../hooks/useJobFormV2";
import { useApplicationStore } from "../../../store/applicationStore";

const EditJobV2 = () => {
  const {
    greenSkills,
    technicalSkills,
    criticalSkills,
    jobSector,
    detail,
    hookForm,
  } = useJobFormV2();
  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([
      renderNavLink("/admin/jobs-companies/job-roles", "Jobs"),
      renderNavLink("/admin/jobs-companies/sectors", "Sectors"),
    ]);
  }, [setLinks]);

  return (
    <div>
      {detail?.isLoading ? (
        <Skeleton active title={false} paragraph={{ rows: 15 }} />
      ) : (
        <>
          <div className="flex items-center gap-3 mb-10">
            <Link to="/admin/jobs-companies/job-roles">
              <ArrowLeftOutlined className="text-backdrop text-lg" />
            </Link>
            <span className="text-xl font-extrabold text-backdrop mb-1">
              Edit Job Roles
            </span>
          </div>

          <form onSubmit={hookForm?.handleSubmit(detail.onSaveSubmit)}>
            <div className="grid grid-cols-12">
              <div className="col-span-8">
                <div className="mb-5 space-y-6">
                  <div className="flex flex-col items-center-justify-start gap-2">
                    <label>Job Role Name</label>
                    <Controller
                      control={hookForm?.control}
                      rules={{ required: "This field is required" }}
                      name="name"
                      render={({ field }) => (
                        <Input placeholder="Sector Name" {...field} />
                      )}
                    />
                    {hookForm?.errors.name && (
                      <p className="text-red-500">
                        {hookForm?.errors.name.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-5 flex flex-col justify-start gap-2 w-full">
                  <label>Sector</label>
                  <Controller
                    control={hookForm?.control}
                    className="w-full"
                    name="sector_id"
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <Select
                        placeholder="Job Sector"
                        {...field}
                        loading={jobSector?.isLoading}
                        onChange={(value) => field.onChange(value)}
                        onPopupScroll={jobSector?.handleScroll}
                        filterOption={false}
                        onSearch={jobSector?.handleSearch}
                        allowClear
                        showSearch
                        onClear={jobSector?.handleClear}
                        onDropdownVisibleChange={jobSector?.handleClear}
                        options={jobSector?.data?.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}></Select>
                    )}
                  />
                  {hookForm?.errors.sector_id && (
                    <p className="text-red-500">
                      {hookForm?.errors.sector_id.message}
                    </p>
                  )}
                </div>
                <div className="mb-5 space-y-6">
                  <div className="flex flex-col items-center-justify-start gap-2">
                    <label>Job Description</label>
                    <Controller
                      control={hookForm?.control}
                      name="description"
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <TextArea
                          rows={5}
                          placeholder="Job Description"
                          {...field}
                        />
                      )}
                    />
                    {hookForm?.errors.description && (
                      <p className="text-red-500">
                        {hookForm?.errors.description.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-5 space-y-3">
                  <h2 className="font-bold text-lg">Green Skills</h2>
                  <Select
                    showSearch
                    className="w-full"
                    mode="multiple"
                    value={[]}
                    // allowClear
                    onDropdownVisibleChange={greenSkills?.handleClear}
                    loading={greenSkills?.isLoading}
                    filterOption={false}
                    labelInValue
                    options={greenSkills?.data?.map((item) => ({
                      label: item.name,
                      value: item.id,
                      disabled: item?.disabled,
                    }))}
                    notFoundContent={
                      <div className="py-1 px-3">skill not found!</div>
                    }
                    // searchValue={greenSkills?.params?.name}
                    onPopupScroll={greenSkills?.handleScroll}
                    placeholder="Type to search"
                    // placement="bottomLeft"
                    onSearch={greenSkills?.handleSearch}
                    onClear={greenSkills?.handleClear}
                    onSelect={greenSkills?.handleSelectGS}
                  />

                  <table className="w-full mt-3">
                    <thead className="grid grid-cols-12 bg-[#E2EBFA]">
                      <td className="font-bold border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-8 capitalize">
                        skill name
                      </td>

                      <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-3 capitalize">
                        proficiency
                      </td>

                      <td className="font-bold border-t border-b border-r border-gray-300 py-3 px-5 text-backdrop col-span-1"></td>
                    </thead>
                    <tbody>
                      {detail?.greenSkillData?.length === 0 ? (
                        <tr className="border-l border-b border-r p-2">
                          <Empty
                            description="No data"
                            className="text-sm  my-3"
                          />
                        </tr>
                      ) : (
                        detail?.greenSkillData?.map((skill, index) => (
                          <tr className="grid grid-cols-12" key={index}>
                            <td className="font-medium border-l border-b border-gray-300 py-2 px-5 text-backdrop col-span-8 flex items-center">
                              {skill?.name}
                            </td>

                            <td className="font-bold border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
                              <DotList
                                value={skill?.level}
                                status="LIVE"
                                handleClick={(e) =>
                                  greenSkills?.handleSetValueGS(e, skill?.id)
                                }
                              />
                            </td>

                            <td className="font-medium border-b border-r border-gray-300 py-2 px-5 text-backdrop col-span-1">
                              <div
                                onClick={() =>
                                  greenSkills?.handleDeleteGS(skill?.id)
                                }
                                className="pt-1 cursor-pointer">
                                <DeleteOutlined className="text-lg" />
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mb-5 space-y-3">
                  <h2 className="font-bold text-lg">Technical Skills</h2>
                  <Select
                    showSearch
                    className="w-full"
                    mode="multiple"
                    value={[]}
                    // allowClear
                    loading={technicalSkills?.isLoading}
                    filterOption={false}
                    labelInValue
                    options={technicalSkills?.data?.map((item) => ({
                      label: item.name,
                      value: item.id,
                      disabled: item?.disabled,
                    }))}
                    notFoundContent={
                      <div>Not found! press enter to create!</div>
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !technicalSkills?.data) {
                        technicalSkills?.handleAddTS(e.target.value);
                      }
                    }}
                    onDropdownVisibleChange={technicalSkills?.handleClear}
                    // searchValue={technicalSkills?.params?.name}
                    onPopupScroll={technicalSkills?.handleScroll}
                    placeholder="Type to search"
                    // placement="bottomLeft"
                    onSearch={technicalSkills?.handleSearch}
                    onClear={technicalSkills?.handleClear}
                    onSelect={technicalSkills?.handleSelectTS}
                  />

                  <table className="w-full mt-3">
                    <thead className="grid grid-cols-12 bg-[#E2EBFA]">
                      <td className="font-bold border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-8 capitalize">
                        skill name
                      </td>

                      <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-3 capitalize">
                        proficiency
                      </td>

                      <td className="font-bold border-t border-b border-r border-gray-300 py-3 px-5 text-backdrop col-span-1"></td>
                    </thead>
                    <tbody>
                      {detail?.technicalSkillData?.length === 0 ? (
                        <tr className="border-l border-b border-r p-2">
                          <Empty
                            description="No data"
                            className="text-sm my-3"
                          />
                        </tr>
                      ) : (
                        detail?.technicalSkillData?.map((skill, index) => (
                          <tr className="grid grid-cols-12" key={index}>
                            <td className="font-medium border-l border-b border-gray-300 py-2 px-5 text-backdrop col-span-8 flex items-center">
                              {skill?.name}
                            </td>

                            <td className="border-b border-gray-300 py-3 px-5 text-backdrop col-span-3">
                              <Input
                                type="number"
                                min={0}
                                max={6}
                                value={skill?.level}
                                className="w-1/2 bg-gray-200 text-center"
                                onChange={(e) =>
                                  technicalSkills?.handleChangeLevelTS(
                                    skill?.id,
                                    e
                                  )
                                }
                              />
                            </td>

                            <td className="font-medium border-b border-r border-gray-300 py-2 px-5 text-backdrop col-span-1">
                              <div
                                onClick={() =>
                                  technicalSkills?.handleDeleteTS(skill?.id)
                                }
                                className="pt-1 cursor-pointer">
                                <DeleteOutlined className="text-lg" />
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mb-5 space-y-3">
                  <h2 className="font-bold text-lg">Critical Skills</h2>
                  <Select
                    showSearch
                    className="w-full"
                    mode="multiple"
                    value={[]}
                    // allowClear
                    loading={criticalSkills?.isLoading}
                    filterOption={false}
                    labelInValue
                    options={criticalSkills?.data?.map((item) => ({
                      label: item.name,
                      value: item.id,
                      disabled: item?.disabled,
                    }))}
                    notFoundContent={
                      <div className="py-1 px-3">skill not found!</div>
                    }
                    // autoClearSearchValue={false}
                    onDropdownVisibleChange={criticalSkills?.handleClear}
                    // searchValue={criticalSkills?.params?.search}
                    onPopupScroll={criticalSkills?.handleScroll}
                    placeholder="Type to search"
                    // placement="bottomLeft"
                    onSearch={criticalSkills?.handleSearch}
                    onClear={criticalSkills?.handleClear}
                    onSelect={criticalSkills?.handleSelectCS}
                  />

                  <table className="w-full mt-3">
                    <thead className="grid grid-cols-12 bg-[#E2EBFA]">
                      <td className="font-bold border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-8 capitalize">
                        skill name
                      </td>

                      <td className="font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-3 capitalize">
                        proficiency
                      </td>

                      <td className="font-bold border-t border-b border-r border-gray-300 py-3 px-5 text-backdrop col-span-1"></td>
                    </thead>
                    <tbody>
                      {detail?.criticalSkillData?.length === 0 ? (
                        <tr className="border-l border-b border-r p-2">
                          <Empty
                            description="No data"
                            className="text-sm my-3"
                          />
                        </tr>
                      ) : (
                        detail?.criticalSkillData?.map((skill, index) => (
                          <tr
                            className="grid grid-cols-12 justify-center"
                            key={index}>
                            <td className="font-medium border-l border-b border-gray-300  px-5 py-3 text-backdrop col-span-8 flex items-center">
                              {skill?.name}
                            </td>
                            <td className="font-bold border-b border-gray-300  px-5  py-3  text-backdrop col-span-3">
                              <Select
                                placeholder="Choose Level"
                                className="w-full"
                                loading={hookForm?.isLoading}
                                onPopupScroll={hookForm?.handleScroll}
                                onChange={(e) =>
                                  criticalSkills?.handleChangeLevelCS(
                                    skill?.id,
                                    e
                                  )
                                }
                                defaultValue={skill?.level}>
                                {[
                                  { id: 1, name: "Basic", level: 1 },
                                  { id: 2, name: "Intermediate", level: 2 },
                                  { id: 3, name: "Advanced", level: 3 },
                                ]?.map((skill) => (
                                  <Select.Option
                                    key={skill.id}
                                    value={skill.level}>
                                    {skill?.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </td>

                            <td className="font-medium border-b border-r border-gray-300  px-5 text-backdrop col-span-1  py-3">
                              <div
                                onClick={() =>
                                  criticalSkills?.handleDeleteCS(skill?.id)
                                }
                                className="pt-1 cursor-pointer">
                                <DeleteOutlined className="text-lg" />
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="mb-10 space-y-6">
                  <h2 className="font-bold text-lg">Job Details</h2>
                  <div>
                    <Controller
                      control={hookForm?.control}
                      name="job_detail"
                      rules={{ required: "This field is required" }}
                      render={({ field }) => (
                        <RichText
                          className={`h-[250px] pb-[2.43rem] font-default mb-3  font-normal`}
                          placeholder={"Type Job detail"}
                          name={`job_detail`}
                          value={detail?.jobDetailText}
                          handleChange={(e) => {
                            hookForm?.setValue("job_detail", e);
                            detail?.setJobDetailText(e);
                          }}
                        />
                      )}
                    />
                    {hookForm?.errors.job_detail && (
                      <p className="text-red-500">
                        {hookForm?.errors.job_detail.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-4 text-right">
                <Button
                  loading={detail?.isLoadingEdit}
                  type="primary"
                  className="bg-primary mt-6"
                  htmlType="submit">
                  Save Changes
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default EditJobV2;

import React from 'react'
import {
  CheckOutlined,
  CloseOutlined,
  LinkedinOutlined,
  EnvironmentOutlined,
  FieldTimeOutlined
} from '@ant-design/icons'
import { Popconfirm, Input, Divider } from 'antd'
import { useUpdateMentorRequest } from '../hooks/useMentorRequest'

export const RequestMentor = ({ request }) => {
  const [reason, setReason] = React.useState(null)
  const updateMentorRequest = useUpdateMentorRequest()

  const openNewTab = (url) => {
    window.open(url, '_blank').focus()
  }

  const mappedNamesExpertise = request?.domain_expertise_data?.map(expertise => expertise.name).join(", ")


  const mappedNamesArea = request?.mentorship_area_data?.map(area => area.name).join(", ")



  return (
    <div
      className="bg-white-custom px-3 py-2 rounded"
      style={{ border: '2px solid #EDEDED' }}
    >
      <div className="flex items-center w-full flex-wrap sm:flex-nowrap">
        <div className="w-4/5 flex items-center gap-x-3">
          <div className="font-bold">{request?.username}</div>
          <Divider type="vertical" />
          <div className="flex gap-x-2">
            <LinkedinOutlined className="cursor-pointer"
              onClick={() => openNewTab(request?.linkedin)}
            />
          </div>
          <Divider type="vertical" />
          <div className="flex gap-x-2">
            <EnvironmentOutlined className="cursor-pointer" />
            {request?.location}
          </div>
          <Divider type="vertical" />
          <div className="flex gap-x-2">
            <FieldTimeOutlined className="cursor-pointer" />
            {request?.monthly_availability} hours/month
          </div>
        </div>
        <div className="w-1/5 flex gap-x-7 justify-end">
          <div
            onClick={() => {
              updateMentorRequest.mutate({
                id: request?.id,
                data: { status: 'APPROVED', user_id: request?.user_id },
              })
            }}
            className="bg-primary text-white text-sm rounded-full flex items-center justify-center p-4 w-8 h-8 hover:scale-105 cursor-pointer transition-all duration-200"
          >
            <CheckOutlined />
          </div>
          <Popconfirm
            title="Reject Request"
            icon={null}
            description={
              <div>
                <Input.TextArea
                  rows={3}
                  className="w-full mt-2"
                  placeholder="Enter reason for rejection"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
            }
            onConfirm={() => {
              updateMentorRequest.mutate({
                id: request?.id,
                data: { reason, status: 'REJECTED', user_id: request?.user_id },
              })
            }}
            okButtonProps={{
              loading: updateMentorRequest.isLoading,
              type: 'default',
              disabled:
                reason?.trim() === '' ||
                reason === null ||
                reason === undefined,
              className: 'bg-danger text-white',
            }}
            okText="Reject"
            cancelText="Cancel"
          >
            <div
              className="bg-white text-backdrop text-sm rounded-full flex items-center justify-center p-4 w-8 h-8 hover:scale-105 cursor-pointer transition-all duration-200"
              style={{ border: '1px dashed red' }}
            >
              <CloseOutlined />
            </div>
          </Popconfirm>
        </div>
      </div>
      <div className="mt-5">
        <p className='m-0 leading-6'><b>Domain Expertise:</b> {mappedNamesExpertise}</p>
        <p className='m-0 leading-6'><b>Mentorship Areas:</b> {mappedNamesArea}</p>
        <p className='m-0 pt-2 leading-6'><b>Motivation:</b> {request?.motivation_letter}</p>
      </div>
    </div>
  )
}

import { Button, Input, Result, Select, Spin } from "antd";
import React from "react";
import ReactQuill from "react-quill";
import { useMailForm } from "../hooks/useMailForm";
import { Controller } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";

export const Email = ({ isDetail = false }) => {
  const { state, form, targetApps, userGroups, emailTypes, actionAddEmail } =
    useMailForm();
  console.log(state.isError);
  return state.isLoading ? (
    <div className=" flex h-[calc(100vh-15rem)] w-full items-center justify-center ">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  ) : state.isError ? (
    <Result title="Data not found" status={404} />
  ) : (
    <div className=" bg-[#FAFAFA] w-full rounded-md shadow-custom-sm p-5">
      <div className=" grid grid-cols-3 gap-5">
        <Controller
          name="target_app"
          control={form.control}
          rules={{ required: "Target app is required" }}
          render={({ field }) => (
            <Select
              {...field}
              disabled={isDetail}
              className="w-full custom-rounded capitalize"
              placeholder="Email type"
              loading={targetApps.isLoading}
              options={targetApps.data?.map((app) => ({
                label: app,
                value: app,
              }))}
              status={form.errors.target_app ? "error" : ""}
              dropdownStyle={{
                borderRadius: "0.375rem",
                textTransform: "capitalize",
              }}
              onSelect={() => {
                form.setValue("user_group", null);
                form.setValue("mail_category", null);
              }}
            />
          )}
        />
        <Controller
          name="user_group"
          control={form.control}
          rules={{ required: "User group is required" }}
          render={({ field }) => (
            <Select
              {...field}
              disabled={isDetail}
              className="w-full custom-rounded capitalize"
              placeholder="User group"
              loading={userGroups.isLoading}
              options={userGroups.data?.map((app) => ({
                label: app,
                value: app,
              }))}
              status={form.errors.user_group ? "error" : ""}
              dropdownStyle={{
                borderRadius: "0.375rem",
                textTransform: "capitalize",
              }}
              onSelect={() => form.setValue("mail_category", null)}
            />
          )}
        />
        <Controller
          name="mail_category"
          control={form.control}
          rules={{ required: "Email type is required" }}
          render={({ field }) => (
            <Select
              {...field}
              disabled={isDetail}
              className="w-full custom-rounded capitalize"
              placeholder="Email type"
              loading={emailTypes.isLoading}
              options={emailTypes.data?.map((app) => ({
                label: app,
                value: app,
              }))}
              status={form.errors.mail_category ? "error" : ""}
              dropdownStyle={{
                borderRadius: "0.375rem",
                textTransform: "capitalize",
              }}
            />
          )}
        />
      </div>
      <Controller
        name="subject"
        control={form.control}
        rules={{ required: "Email title is required" }}
        render={({ field }) => (
          <Input
            {...field}
            disabled={isDetail}
            className="w-full rounded-md my-3"
            placeholder="Email title"
            status={form.errors.subject ? "error" : ""}
          />
        )}
      />

      <div className="mt-3 font-semibold px-1.5">Hi, [fisrt_name]</div>
      <Controller
        name="content"
        control={form.control}
        rules={{
          required: "Content is required",
          validate: (val) => val !== "<p><br></p>",
        }}
        render={({ field }) => (
          <ReactQuill
            {...field}
            readOnly={isDetail}
            className={`custom ${
              form.errors.content ? "error" : ""
            }  bg-white my-3`}
            placeholder="Type here"
          />
        )}
      />
      {!isDetail && (
        <div className=" text-end">
          <Button
            type="primary"
            loading={actionAddEmail.isLoading}
            onClick={form.handleSubmit(actionAddEmail.handleSendEmail)}
          >
            Send
          </Button>
        </div>
      )}
    </div>
  );
};

import React from "react";
import { Avatar, Layout } from "antd";
import {
  BellOutlined,
  SearchOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useHeader } from "../hooks/useCustomHeader";
import { useIsAuthenticated } from "@azure/msal-react";
import { useApplicationStore } from "../../store/applicationStore";

function CustomHeader() {
  const { handleLogoutRedirect } = useHeader();
  const isAuthenticated = useIsAuthenticated();

  const { Header } = Layout;
  const { openSidebar, links: pageLinks } = useApplicationStore();

  return (
    <Header
      style={{
        width: isAuthenticated
          ? openSidebar
            ? "calc(100% - 210px)"
            : "calc(100% - 70px)"
          : "100%",
        marginLeft: isAuthenticated ? (openSidebar ? "210px" : "70px") : "",
      }}
      className="h-10 py-6  flex items-center justify-between bg-white w-full border-b shadow z-20 top-0 fixed">
      <div className="flex items-center gap-16">
        <div className="flex gap-4">
          {pageLinks?.map((link, i) => (
            <div key={i}>{link}</div>
          ))}
        </div>
      </div>

      <div className="flex gap-x-5 justify-end items-center">
        <SearchOutlined className="text-base" />
        <BellOutlined className="text-base" />
        <Avatar
          size="small"
          icon={<UserOutlined className="text-base" />}
          className=" cursor-pointer"
        />
        {/* )}
        </Dropdown> */}
        <LogoutOutlined onClick={handleLogoutRedirect} className="text-lg" />
      </div>
      {/* <Drawer
        title='My Instructor Profile'
        placement='right'
        closable={false}
        onClose={handleClose}
        open={isOpen}
        width={'736px'}
        extra={
          <CloseOutlined className=' cursor-pointer' onClick={handleClose} />
        }
      >
        <Profile
          handleClose={handleClose}
          isEdit={isEdit}
          handleEdit={handleEdit}
          data={user}
          countries={countries}
          handleUpdate={handleUpdate}
          loading={loadingUpdateUser}
          handleImageChange={handleImageChange}
          imageUrl={imageUrl}
        />
      </Drawer> */}
    </Header>
  );
}

export default CustomHeader;

import {
  CloseOutlined,
  LikeOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { Avatar, Drawer, List } from "antd";
import React from "react";
import { ThisWeeksDiscussion } from "../api/this-weeks-discussion";
import useInfiniteComments from "../hooks/useInfiniteComments.ts";

interface DetailDiscussionDrawerProps {
  handleCloseDetailDiscussion: () => void;
  isOpen: boolean;
  thisWeekDiscussion: ThisWeeksDiscussion | null;
}

const DetailDiscussionDrawer: React.FC<DetailDiscussionDrawerProps> = ({
  handleCloseDetailDiscussion,
  isOpen,
  thisWeekDiscussion,
}) => {
  const { comments, commentsQuery, lastCardElementRef } = useInfiniteComments(
    thisWeekDiscussion?.id
  );
  return (
    <Drawer
      closable={false}
      open={isOpen}
      width={600}
      onClose={handleCloseDetailDiscussion}>
      <div className="flex flex-wrap items-center justify-between ">
        <h3 className="text-xl font-bold">This Week's Discussion</h3>
        <CloseOutlined onClick={handleCloseDetailDiscussion} />
      </div>

      <>
        <div className="mt-4 space-y-3">
          <h4 className="text-lg font-semibold">
            Q: {thisWeekDiscussion?.title}
          </h4>
          <div>{thisWeekDiscussion?.description}</div>
          <div>Leave a comment below and let us know!</div>
          <div className="text-lg text-primary">
            {thisWeekDiscussion?.hashtags}
          </div>
        </div>

        <div className="flex items-center gap-3 mt-4">
          <div className="bg-bg py-[10px] px-[20px] flex rounded-[10px] cursor-pointer hover:scale-110 transition-all duration-300 gap-2 items-center">
            <LikeOutlined className="transition-all duration-200  " />
            <span>{thisWeekDiscussion?.total_reaction}</span>
          </div>
          <div className="bg-bg py-[10px] px-[20px] flex rounded-[10px] cursor-pointer hover:scale-110 transition-all duration-300 gap-2 items-center">
            <CommentOutlined />
            <span>{thisWeekDiscussion?.total_comments}</span>
          </div>
        </div>

        {/* <div className="flex items-center gap-2 mt-4">
          <label className="text-gray-500">Sort by:</label>
          <Select
            className="rounded min-w-[100px]"
            //   {...field}
            suffixIcon={<CaretDownOutlined className="text-backdrop" />}>
            <Select.Option value="newest">Newest</Select.Option>
            <Select.Option value="oldest">Oldest</Select.Option>
            <Select.Option value="popular">Most Popular</Select.Option>
          </Select>
        </div> */}

        <div className="mt-6 space-y-6">
          <List
            itemLayout="vertical"
            loading={commentsQuery.isLoading}
            dataSource={comments}
            renderItem={(comment) => (
              <List.Item ref={lastCardElementRef} key={comment.id}>
                <List.Item.Meta
                  avatar={<Avatar src={comment.user.avatar} />}
                  title={comment.user.username}
                  description={new Date(comment.created_at).toLocaleString()}
                />
                {comment.body}
              </List.Item>
            )}
          />
        </div>
      </>
    </Drawer>
  );
};

export default DetailDiscussionDrawer;

import { ConfigProvider } from "antd";
import PageLayout from "./common/layout/PageLayout";
import GeneralRoute from "./routes";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#86C769",
          fontFamily: "Sen",
          borderRadius: "0px",
          colorText: "#36505E",
          colorError: "EF7574",
          fontSizeHeading1: "24px",
          fontSizeHeading2: "20px",
          fontSizeHeading3: "18px",
          fontSizeHeading4: "16px",
        },
        components: {
          Dropdown: {
            borderRadiusLG: 8,
            borderRadiusSM: 4,
            borderRadiusOuter: 4,
            borderRadiusXS: 2,
          },
          Popover: {
            borderRadiusOuter: 4,
            borderRadiusXS: 2,
          },
          Pagination: {
            colorPrimary: "#36505E",
          },
          Tag: {
            colorBgContainer: "#86C769",
            colorBgBase: "#86C769",
            colorPrimaryBg: "#86C769",
            borderRadiusSM: 3,
            colorText: "#36505E",
            colorBorder: "#36505E",
          },
          Tabs: {
            colorBgContainer: "#D4F5C5",
            colorBgTextActive: "#D4F5C5",
            colorTextBase: "#36505E",
            colorText: "#36505E",
            colorTextHeading: "#36505E",
            margin: 0,
            marginXL: 0,
            marginXS: 0,
            marginXXS: 0,
            marginXXL: 0,
            controlHeightLG: 60,
          },
          Empty: {
            colorTextDescription: "#36505E",
            colorTextLabel: "#36505E",
            colorTextBase: "#36505E",
          },
          Button: {
            colorText: "#36505E",
          },
          Input: {
            colorPrimary: "#009ACE",
            colorPrimaryActive: "#009ACE",
            colorPrimaryHover: "#009ACE",
            controlOutlineWidth: 0,
            colorText: "#36505E",
          },
          Select: {
            colorPrimary: "#009ACE",
            colorPrimaryActive: "#009ACE",
            colorPrimaryHover: "#009ACE",
            controlOutlineWidth: 0,
            colorText: "#36505E",
          },
          Tooltip: {
            borderRadius: 6,
            borderRadiusOuter: 4,
            borderRadiusXS: 2,
          },
        },
      }}
    >
      <PageLayout>
        <GeneralRoute />
      </PageLayout>
    </ConfigProvider>
  );
}

export default App;

import axios from "axios";
import { msalInstance } from "../index";

export const httpRequest = axios.create({
  baseURL: process.env.REACT_APP_API,
  // baseURL: "http://localhost:3002/api/v1",
});

httpRequest.interceptors.request.use(
  async (config) => {
    // if (config.headers) {
    //   config.headers["Content-Type"] = "application/json";
    // }

    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      const accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: [`api://${process.env.REACT_APP_BE_APP_ID}/user_impersonation`],
        account: account,
      });

      // console.log(accessTokenResponse);

      if (accessTokenResponse) {
        const accessToken = accessTokenResponse.accessToken;

        if (config.headers && accessToken) {
          config.headers["Authorization"] = "Bearer " + accessToken;
        }
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default httpRequest;

import React from "react";
import { Button, Select, Table } from "antd";
import { useTalentAnalytics } from "../hooks/useTalentAnalytics";
import { useInfinteLoading } from "../hooks/useInfiniteLoading";

const columns = [
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Location",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Points",
    dataIndex: "points",
    key: "points",
  },
  {
    title: "Courses",
    dataIndex: "total_bought_skill",
    key: "total_bought_skill",
  },
  {
    title: "Sales",
    dataIndex: "total_amount_spent",
    key: "total_amount_spent",
  },
  {
    title: "",
    dataIndex: "",
    width: "10%",
    render: () => <Button>View</Button>,
  },
];

const sort_by = [
  { value: "account_point_asc", label: "Point Ascending" },
  { value: "account_point_desc", label: "Point Descending" },
  { value: "account_creation_asc", label: "Create Ascending" },
  { value: "account_creation_desc", label: "Create Descending" },
];

export const TalentUsersTable = () => {
  const [param, setParam] = React.useState({
    page: 1,
    order_by: undefined,
    countries: undefined,
  });
  const talentAnalytics = useTalentAnalytics({
    pageParam: param.page,
    search: null,
    order_by: param.order_by,
    countries: param.countries,
  });
  const talData = talentAnalytics.data?.data?.data;
  const { data, isLoading, handleScroll, handleSearch } = useInfinteLoading({
    key: "country-ref",
    path: "/references/countries",
    queryParams: { page: 1, limit: 20, name: "" },
    searchBy: "name",
  });

  console.log("param", param);
  return (
    <>
      <div className="text-backdrop font-bold">
        Total: {talData?.total_data}
      </div>
      <div className=" flex justify-end gap-5">
        <Select
          className=" w-40"
          options={sort_by}
          placeholder="Sort by"
          allowClear
          onClear={() => setParam((prev) => ({ ...prev, order_by: undefined }))}
          onSelect={(e) => setParam((prev) => ({ ...prev, order_by: e }))}
        />
        <Select
          filterOption={false}
          loading={isLoading}
          onPopupScroll={handleScroll}
          showSearch
          onSelect={(e) => setParam((prev) => ({ ...prev, countries: e }))}
          onSearch={handleSearch}
          options={data?.map((item) => ({
            label: item?.name,
            value: item?.name,
          }))}
          allowClear
          onClear={() => {
            handleSearch();
            setParam((prev) => ({ ...prev, countries: undefined }));
          }}
          className=" w-40"
          placeholder="Select country"
        />
      </div>
      <div className="admin-table mt-6">
        <Table
          dataSource={talData?.users_data}
          columns={columns}
          loading={talentAnalytics.isLoading}
          pagination={{
            current: param.page,
            pageSize: 100,
            hideOnSinglePage: true,
            total: talData?.total_data,
            onChange: (page) => {
              setParam((prev) => ({ ...prev, page: page }));
            },
          }}
        />
      </div>
    </>
  );
};

import React from "react";
import { useCoupon } from "../hooks/useCoupon";
import { Button, Divider, Spin } from "antd";
import dayjs from "dayjs";
import { useApplicationStore } from "../../../store/applicationStore";
import { renderNavLink } from "../../../common/components/NavLink";
import { LoadingOutlined } from "@ant-design/icons";

export const RecruiterListCoupon = () => {
  const { setLinks } = useApplicationStore();
  React.useEffect(() => {
    setLinks([
      renderNavLink("/admin/jobs-companies/coupons", "Coupons"),
      renderNavLink("/admin/jobs-companies/trial-setting", "Trial Setting"),
    ]);
  }, [setLinks]);
  const { data, isLoading } = useCoupon();
  return (
    <div>
      <div className=" flex justify-between items-center mb-5">
        <h1 className="m-0 text-2xl font-semibold">Coupon</h1>
        <Button
          className=""
          type="primary"
          onClick={() => {
            window.open(
              "https://dashboard.stripe.com/coupons/create",
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        >
          Add
        </Button>
      </div>
      <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
        <div className=" bg-white rounded-xl shadow-md p-5">
          <div>
            <div className=" grid grid-cols-4 px-2 gap-5 text-darkGrey">
              <div className=" col-span-2">Offer title</div>
              <div className=" col-span-1">Redemptions</div>
              <div className=" col-span-1">Expires</div>
            </div>
            <Divider className=" my-3 border-1 border-darkGrey" />
          </div>
          {data?.total_count > 0 ? (
            data?.data?.map((coupon, i) => (
              <div key={i}>
                <div className=" grid grid-cols-4  gap-5 px-2 items-center">
                  <div className=" col-span-2">
                    <div>{coupon?.name}</div>
                    <div className=" mt-2 text-darkGrey">
                      {coupon?.duration === "forever"
                        ? coupon?.percent_off + "% off forever"
                        : coupon?.percent_off +
                          "% for" +
                          coupon?.duration_in_months +
                          " months"}
                    </div>
                  </div>

                  <div className=" col-span-1 px-2">
                    {coupon?.times_redeemed}
                  </div>
                  <div className=" col-span-1">
                    {coupon?.redeem_by
                      ? dayjs.unix(coupon?.redeem_by).format("DD MMM YYYY")
                      : "-"}
                  </div>
                </div>
                <Divider className=" my-3 border-1 border-backdrop" />
              </div>
            ))
          ) : (
            <div>No coupon available</div>
          )}
        </div>
      </Spin>
    </div>
  );
};

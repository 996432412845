import React from "react";

const Cardv2 = ({ name, existingData = "--none--", newData = "--none--" }) => {
  return (
    <div className="my-5">
      <p className="mb-3 capitalize">{name}</p>
      <div className="grid grid-cols-2 gap-5">
        <div
          className="border min-h-[80px] rounded-sm p-2"
          dangerouslySetInnerHTML={{ __html: existingData }}
        ></div>
        <div
          className="border min-h-[80px] rounded-sm p-2"
          dangerouslySetInnerHTML={{ __html: newData }}
        ></div>
      </div>
    </div>
  );
};

export default Cardv2;

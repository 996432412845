import React from "react";
import { Modal, Button, Form, Input } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import {
  createDiscussion,
  ThisWeeksDiscussion,
  ThisWeeksDiscussionPost,
  updateDiscussion,
} from "../api/this-weeks-discussion.ts";
import RHFDatePickerField from "./RHFDatepickerField.tsx";

// Validation schema
const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  display_at: yup.string().required("Display date is required"),
  expires_at: yup.string().required("Expiry date is required"),
  hashtags: yup
    .string()
    .required("Hashtags are required")
    .matches(/^(\w+(,\w+)*)?$/, "Hashtags must be comma-separated words"),
});

interface CreateDiscussionModalProps {
  thisWeekDiscussion: ThisWeeksDiscussion | null;
  openModal: boolean;
  closeModal: () => void;
}

const CreateDiscussionModal: React.FC<CreateDiscussionModalProps> = ({
  openModal,
  closeModal,
  thisWeekDiscussion,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ThisWeeksDiscussionPost>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: thisWeekDiscussion?.description ?? "",
      display_at:  thisWeekDiscussion?  new Date(thisWeekDiscussion?.display_at).getTime() : undefined,
      expires_at:thisWeekDiscussion? new Date(thisWeekDiscussion.expired_at).getTime() : undefined,
      hashtags:  thisWeekDiscussion?.hashtags && thisWeekDiscussion.hashtags.length > 0 ? thisWeekDiscussion?.hashtags.map(item => item.replace("#","")).toString() : undefined,
      title: thisWeekDiscussion?.title,
    },
  });

  React.useEffect(() => {
    if (!thisWeekDiscussion) {
      reset({
        description: "",
        display_at: undefined,
        expires_at: undefined,
        hashtags: [],
        title: "",
      });
    } else {
      reset({
        description: thisWeekDiscussion?.description,
        display_at:  new Date(thisWeekDiscussion?.display_at).getTime(),
        expires_at: new Date(thisWeekDiscussion.expired_at).getTime(),
        hashtags: thisWeekDiscussion?.hashtags && thisWeekDiscussion.hashtags.length > 0 ? thisWeekDiscussion?.hashtags.map(item => item.replace("#","")).toString() : undefined,
        title: thisWeekDiscussion?.title,
      });
    }
  }, [openModal, reset, thisWeekDiscussion]);


  const queryClient = useQueryClient();

  const mutation = useMutation(createDiscussion, {
    onSuccess: () => {
      queryClient.invalidateQueries("discussions");
      closeModal();
      reset();
    },
  });

  const mutationUpdate = useMutation(
    ({ data, id }: { data: ThisWeeksDiscussionPost; id: string }) =>
      updateDiscussion(data, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("discussions");
        closeModal();
        reset();
      },
    }
  );

  const onSubmit = (data: any) => {
    const formattedData = {
      ...data,
      hashtags: data?.hashtags?.split(",").map((tag: string) => tag.trim()),
      display_at: Math.floor(Number(data?.display_at) / 1000),
      expires_at: Math.floor(Number(data?.expires_at) / 1000),
    };
    if (thisWeekDiscussion) {
      mutationUpdate.mutate({
        data: formattedData,
        id: thisWeekDiscussion?.id ?? "",
      });
    } else {
      mutation.mutate(formattedData);
    }
  };

  const handleCancel = () => {
    closeModal();
    reset();
  };

  return (
    <>
      <Modal
        title="Create New Discussion"
        open={openModal}
        onCancel={handleCancel}
        footer={null}>
        <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Form.Item
            label="Title"
            validateStatus={errors.title ? "error" : ""}
            help={errors.title?.message}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => <Input {...field} placeholder={"Title"} />}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            validateStatus={errors.description ? "error" : ""}
            help={errors.description?.message}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => <Input.TextArea {...field}  placeholder={"Description"}/>}
            />
          </Form.Item>
          <Form.Item
            label="Display At"
            // validateStatus={errors.display_at ? "error" : ""}
            // help={errors.display_at?.message}
          >
            <RHFDatePickerField isUtc control={control} name="display_at" />
          </Form.Item>
          <Form.Item
            label="Expires At"
            // validateStatus={errors.expires_at ? "error" : ""}
            // help={errors.expires_at?.message}
          >
            <RHFDatePickerField isUtc control={control} name="expires_at" />
          </Form.Item>
          <Form.Item
            label="Hashtags (comma-separated)"
            validateStatus={errors.hashtags ? "error" : ""}
            help={errors.hashtags?.message}>
            <Controller
              name="hashtags"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter hashtags separated by commas without spaces (e.g., tag1,tag2,tag3)"
                />
              )}
            />
          </Form.Item>
          <Button
            className="ml-auto"
            type="primary"
            htmlType="submit"
            loading={mutation.isLoading || mutationUpdate.isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default CreateDiscussionModal;

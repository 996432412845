import { useQuery, useMutation, useQueryClient } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";

const getPerkList = async () => {
  return httpRequest.get("skilledin/perks");
};

/**
 * Adds a new perk
 * @param {{app: string, company_name: string, expire_at: Date, offer_desc: string, offer_link: string, offer_title: string}} data - The perk data
 */
const addPerk = async (data) => {
  return httpRequest.post("skilledin/perks", data);
};

const perkDetail = async (id) => {
  return httpRequest.get(`skilledin/perks/${id}`);
};

const deletePerk = async (id) => {
  return httpRequest.delete(`skilledin/perks/${id}`);
};

const updatePerkVisibility = async ({ id, data }) => {
  return httpRequest.patch(`skilledin/perks/${id}`, data);
};

const updatePerk = async (data) => {
  return httpRequest.put(`skilledin/perks/${data.id}`, data.data);
};

const duplicatePerk = async (id) => {
  return httpRequest.post(`skilledin/perks/${id}/duplicate`);
};

/** Not to be used yet */
// const usePerks = () => {
//   const queryClient = useQueryClient();
//   return {
//     getPerkList: () => queryClient.fetchQuery("perks", getPerkList),
//     addPerk: (data) => addPerk(data),
//     perkDetail: (id) => queryClient.fetchQuery(["perk", id], () => perkDetail(id)),
//     deletePerk: (id) => queryClient.invalidateQueries("perks", deletePerk(id)),
//     updatePerk: (data) => updatePerk(data),
//     duplicatePerk: (id) => queryClient.invalidateQueries("perks", duplicatePerk(id)),
//   };
// export };

export const usePerkList = (params = {}) => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching perks: ${error}`);
  };

  return useQuery(["perks-list", params], () => getPerkList(params), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
};

export const useAddPerk = () => {
  const queryClient = useQueryClient();
  return useMutation(addPerk, {
    onSuccess: () => {
      queryClient.invalidateQueries("perks-list");
      message.success("Perk added successfully");
    },
    onError: () => {
      message.error("An error occurred while adding the perk");
    },
  });
};

export const usePerkDetail = (id, enabled) => {
  return useQuery(["perk-detail"], () => perkDetail(id), {
    enabled,
  });
};

export const useDeletePerk = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePerk, {
    onSuccess: () => {
      queryClient.invalidateQueries("perks-list");
      message.success("Perk deleted successfully");
    },

    onError: () => {
      message.error("An error occurred while deleting the perk");
    },
  });
};

export const useUpdatePerkVisibility = () => {
  const queryClient = useQueryClient();
  return useMutation((id, data) => updatePerkVisibility(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("perks-list");
      message.success("Perk updated successfully");
    },
    onError: () => {
      message.error("An error occurred while updating the perk");
    },
  });
};

export const useDuplicatePerk = () => {
  const queryClient = useQueryClient();
  return useMutation(duplicatePerk, {
    onSuccess: () => {
      queryClient.invalidateQueries("perks-list");
      message.success("Perk duplicated successfully");
    },
    onError: () => {
      message.error("An error occurred while duplicating the perk");
    },
  });
};

export const usePerkUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(updatePerk, {
    onSuccess: () => {
      queryClient.invalidateQueries("perks-list");
      message.success("Perk updated successfully");
    },
    onError: () => {
      message.error("An error occurred while updating the perk");
    },
  });
};

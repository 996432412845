import React from "react";
import { Button, Table, Dropdown, Popconfirm, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { EllipsisOutlined } from "@ant-design/icons";
import {
  usePerkList,
  useDeletePerk,
  useDuplicatePerk,
  useUpdatePerkVisibility,
} from "../hooks/usePerks";
import { useApplicationStore } from "../../../store/applicationStore";

export const Perks = () => {
  const navigate = useNavigate();
  const perkListQuery = usePerkList();
  const deletePerk = useDeletePerk();
  const duplicatePerk = useDuplicatePerk();
  const updatePerkVisibleMutation = useUpdatePerkVisibility();

  const columns = [
    {
      title: "Offer Title",
      dataIndex: "offer_title",
      key: "offer_title",
      render: (_, record) => (
        <>
          <div
            className="text-secondary cursor-pointer font-semibold"
            onClick={() => navigate("/admin/marketing/perks/" + record?.id)}
          >
            {record?.offer_title}
          </div>
        </>
      ),
    },
    {
      title: "Expires On",
      dataIndex: "expire_at",
      key: "expire_at",
      render: (_, record) =>
        record?.expire_at
          ? dayjs(record?.expire_at).format("ddd DD MMMM YYYY")
          : "N/A",
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      render: (company_name) => (company_name === "-" ? "N/A" : company_name),
    },
    {
      title: "App",
      dataIndex: "app",
      key: "app",
      render: (app) => (app === "-" ? "N/A" : app),
    },
    {
      title: "Visibility",
      dataIndex: "visible",
      key: "visible",
      render: (_, record) => (
        <Switch
          checked={record?.visible}
          onChange={(checked) => {
            updatePerkVisibleMutation.mutate({
              id: record?.id,
              data: { visible: checked },
            });
          }}
        />
      ),
    },

    {
      title: "",
      key: "action",
      render: (_, record) => (
        <>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "duplicate",
                  label: "Duplicate",
                  onClick: () =>
                    duplicatePerk.mutate(record?.id, {
                      onSuccess: () => {
                        perkListQuery.refetch();
                      },
                    }),
                },
                {
                  key: "edit",
                  label: "Edit",
                  onClick: () =>
                    navigate(`/admin/marketing/perks/${record?.id}`),
                },
                {
                  key: "remove",
                  label: (
                    <Popconfirm
                      title="Are you sure you want to delete this perk?"
                      onConfirm={() => {
                        deletePerk.mutate(record?.id, {
                          onSuccess: () => {
                            perkListQuery.refetch();
                          },
                        });
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      Delete
                    </Popconfirm>
                  ),
                },
              ],
            }}
          >
            <Button className="text-backdrop border-lightGrey rounded-md">
              <EllipsisOutlined className="text-lg" />
            </Button>
          </Dropdown>
        </>
      ),
      width: 100,
    },
  ];

  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([]);
  }, [setLinks]);
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="text-xl font-bold ">Perks</div>
        <Button
          className="bg-primary text-white"
          type="primary"
          onClick={() => navigate(`/admin/marketing/perks/create`)}
        >
          New Perk
        </Button>
      </div>
      <div className="basic-card bg-white-custom !p-0 mt-6">
        <Table
          columns={columns}
          loading={
            perkListQuery.isLoading ||
            deletePerk.isLoading ||
            duplicatePerk.isLoading ||
            updatePerkVisibleMutation.isLoading
          }
          dataSource={perkListQuery?.data?.data?.data}
          className="transparent-table"
          pagination={false}
        />
      </div>
    </>
  );
};

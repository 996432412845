import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { Divider, Dropdown, Switch, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import httpRequest from "../../../config/config";

export const CurrencyRow = ({
  currency,
  setModal,
  handleRemove,
  isLoadingRemove,
  handleAvailability,
  isLoadingAvailability,
  handleTaxAvailability,
  isLoadingTaxAvailability,
  handleuploadLogo,
}) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (currency?.logo) {
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: currency?.logo,
        },
      ]);
    }
  }, [currency]);

  return (
    <div>
      <div className=" grid grid-cols-6 gap-5 px-2 items-center">
        <div className=" col-span-1">
          <Upload
            maxCount={1}
            accept="image/*"
            multiple={false}
            customRequest={(e) => {
              console.log(httpRequest.defaults);
            }}
            listType="picture-card"
            fileList={fileList}
            onChange={(e) =>
              e.file.status !== "removed" &&
              handleuploadLogo({
                id: currency?.id,
                file: e.file,
                success: () => setFileList([{ ...e.file, status: "done" }]),
              })
            }
            onRemove={() => {
              setFileList([]);
              message.info(
                "just temporary delete, please upload another image"
              );
            }}
          >
            {fileList.length < 1 && (
              <button
                // className="border-dashed border border-primary cursor-pointer"
                style={{ background: "none", border: "none" }}
                type="button"
              >
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </button>
            )}
          </Upload>
        </div>
        <div className=" col-span-1 uppercase flex items-center gap-3">
          <div className=" w-20">{currency.code}</div>
          <Switch
            size="small"
            loading={isLoadingAvailability}
            onChange={() => handleAvailability(currency.id)}
            defaultChecked={currency.is_active}
            disabled={!currency.is_deletable}
          />
        </div>
        <div className=" col-span-1">{currency.name}</div>
        <div className=" col-span-1">{currency.exchange}</div>
        <div className=" col-span-1 flex gap-3 items-center">
          <div className=" w-20">{`${currency.tax} %`}</div>
          <Switch
            size="small"
            loading={isLoadingTaxAvailability}
            onChange={() => handleTaxAvailability(currency.id)}
            defaultChecked={currency.is_tax}
          />
        </div>
        <div className=" col-span-1">
          <Dropdown
            arrow
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <div
                      onClick={() => {
                        currency.is_deletable &&
                          setModal({ isOpen: true, data: currency });
                      }}
                      className={`${
                        !currency.is_deletable ? " cursor-not-allowed" : ""
                      }`}
                    >
                      Update
                    </div>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <div
                      onClick={() => {
                        (currency.is_deletable || isLoadingRemove) &&
                          handleRemove(currency.id);
                      }}
                      className={`${
                        !currency.is_deletable
                          ? " cursor-not-allowed"
                          : isLoadingRemove
                          ? " cursor-wait"
                          : ""
                      }`}
                    >
                      Delete
                    </div>
                  ),
                },
              ],
            }}
            placement="bottomLeft"
          >
            <EllipsisOutlined className=" cursor-pointer text-xl" />
          </Dropdown>
        </div>
      </div>
      <Divider className=" my-3 border-1 border-backdrop" />
    </div>
  );
};

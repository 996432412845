import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useJobs } from "./useJobs";
import { useJobSectorsInfinity } from "./useJobSectors";
import { message } from "antd";
import { useQuery } from "react-query";
import httpRequest from "../../../config/config";

export const useListJobs = () => {
  const [openDrawerAdd, setOpenDrawerAdd] = useState(false);
  const [jobSearch, setJobSearch] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [trainerMissing, setTrainerMissing] = useState(1);
  const [publishedLevel, setPublishedLevel] = useState(null);

  const [sector, setSector] = useState("");
  const [sectorName, setSectorName] = useState("");
  const [isDropdownSectorOpen, setIsDropdownSectorOpen] = useState(false);
  const [searchJobSector, setSearchJobSector] = useState("");
  const [optionJobSectors, setOptionJobSectors] = useState([]);

  const { data: jobsData, isLoading: isJobsLoading } = useJobs({
    limit: itemsPerPage,
    page: selectedPage,
    name: jobSearch,
    sector: sector,
  });

  // console.log('jobsData', jobsData)
  const handlePageChange = (page) => {
    setSelectedPage(page);
  };
  const handlePageSizeChange = (current, size) => {
    setSelectedPage(1);
    setItemsPerPage(size);
  };

  const handleOpenDrawerAddJob = () => {
    setOpenDrawerAdd(true);
  };

  const onCloseDrawerAdd = () => {
    setOpenDrawerAdd(false);
  };

  const handleJobSearch = debounce((value) => {
    setJobSearch(
      value?.target?.value === null ||
        value?.target?.value?.trim() === "" ||
        value?.target?.value === undefined
        ? null
        : value?.target?.value
    );
    setSelectedPage(1);
  }, 1000);

  // // Sector Filter Data
  const handleFilterBySector = (value, option) => {
    if (option.key !== "input-search") {
      if (option.key === "all") {
        setSector("");
        message.info(`Removed Filter`);
      } else {
        setSector(option?.key);
        setSectorName(value);
        message.info(`Filter by ${value}`);
      }
      setIsDropdownSectorOpen(false);
    }
    setIsDropdownSectorOpen(true);
  };

  const {
    data: jobSectors,
    isLoading: isLoadingJobSectors,
    fetchNextPage: fetchNextPageJobSectors,
    isFetchingNextPage: isLoadingFetchingJobSectorsNextPage,
  } = useJobSectorsInfinity(searchJobSector);

  useEffect(() => {
    if (jobSectors?.pages) {
      setOptionJobSectors(() =>
        jobSectors?.pages?.flatMap((page) => page.data.data.list)
      );
    }
  }, [jobSectors?.pages]);

  const handleScrollJobSectors = (e) => {
    fetchNextPageJobSectors();
  };

  const dataSector = useSector();
  const handleSelectSector = (e) => {
    dataSector?.handleClear();
    setSector(e);
    setJobSearch(null);
    setSelectedPage(1);
  };

  const handleRemoveFilter = () => {
    setSector("");
    setJobSearch(null);
    setSelectedPage(1);
  };

  return {
    openDrawerAdd,
    jobSearch,
    handleOpenDrawerAddJob,
    onCloseDrawerAdd,
    selectedPage,
    handlePageChange,
    handlePageSizeChange,
    itemsPerPage,
    setItemsPerPage,
    handleJobSearch,
    trainerMissing,
    setTrainerMissing,
    publishedLevel,
    setPublishedLevel,
    jobsData,
    isJobsLoading,

    // sector
    sector,
    dataSector,
    handleSelectSector,
    handleRemoveFilter,

    // sector,
    sectorName,
    handleFilterBySector,
    optionJobSectors,
    handleScrollJobSectors,
    isLoadingFetchingJobSectorsNextPage,
    isLoadingJobSectors,
    searchJobSector,
    setSearchJobSector,
    isDropdownSectorOpen,
    setIsDropdownSectorOpen,
  };
};

const useSector = () => {
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
    name: null,
  });
  const [total, setTotal] = useState(0);
  const [dataList, setDataList] = useState([]);

  const { data, isLoading } = useQuery(
    ["job-sectors-list-dropdown", params],
    () =>
      httpRequest.get("/skilledin/job-sectors", {
        params,
      })
  );
  const handleSearch = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, name: e, page: 1 }));
  }, 1000);

  const handleClear = (e) => {
    console.log("runnn 2", params?.page, params?.name);
    if (params?.page !== 1 || params?.name !== "") {
      console.log("run 3");
      setParams((prevParams) => ({ ...prevParams, name: "", page: 1 }));
    }
  };

  const handleScroll = (e) => {
    const { target } = e;
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };
  useEffect(() => {
    if (!isLoading) {
      if (params?.page === 1) {
        setDataList(data?.data?.data?.list);
        setTotal(data?.data?.data?.total_data);
      } else {
        setDataList((prev) => [...prev, ...(data?.data?.data?.list || [])]);
        setTotal(data?.data?.data?.total_data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.data?.list]);

  // useEffect(() => {
  //   if (total > dataList.length) {
  //     setParams((prev) => ({ ...prev, page: prev.page + 1 }));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [total]);
  return {
    dataList,
    isLoading,
    handleSearch,
    handleClear,
    handleScroll,
    params,
  };
};

import React from "react"
import { renderNavLink } from "../../../common/components/NavLink"
import { Button, Empty, Modal, Input, Select, Divider } from "antd"
import { useExpertRequest, useInviteExpert } from "../hooks/useExpertRequest"
import { RequestExpert, RequestMentor } from "../components"
import { LoadingOutlined, AppstoreOutlined, MenuOutlined } from "@ant-design/icons"
import { useApplicationStore } from "../../../store/applicationStore"
import { useUserRequestStore } from "../store/userRequest"
import { useMentorRequest } from "../hooks/useMentorRequest"

export const UserRequests = () => {
  const { selectedUserType, setSelectedUserType, selectedLayout, setSelectedLayout } = useUserRequestStore()
  const requestExpertQuery = useExpertRequest()
  const requestMentorQuery = useMentorRequest()
  const inviteExpertMutation = useInviteExpert()
  const [openModal, setOpenModal] = React.useState(false)
  const [expertEmail, setExpertEmail] = React.useState(null)
  const { setLinks } = useApplicationStore()

  React.useEffect(() => {
    setLinks([
      renderNavLink("/admin/talent/users", "Users"),
      renderNavLink("/admin/talent/requests", "Requests"),
      renderNavLink("/admin/sales", "Sales"),
    ])
  }, [setLinks])

  const handleChangeUserType = (value) => {
    setSelectedUserType(value)
  }

  const handleChangeLayoutType = (value) => {
    setSelectedLayout(value)
  }

  console.log('requestMentorQuery?.data?.data?.data?.length', requestMentorQuery?.data?.data?.data)

  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-x-3">
          <Select
            defaultValue={selectedUserType}
            style={{ width: 120 }}
            onChange={handleChangeUserType}
            className="!border-none"
            options={[
              { value: 'mentor', label: 'Mentor' },
              { value: 'expert', label: 'Expert' },
            ]}
          />

          <div className="bg-white flex items-center p-1">
            <div onClick={() => handleChangeLayoutType("grid")} className={`cursor-pointer hover:text-primary ${selectedLayout === "list" ? "bg-white p-1" : "bg-backdrop text-white p-1"}`}>
              <AppstoreOutlined />
            </div>

            <Divider type="vertical" />

            <div onClick={() => handleChangeLayoutType("list")} className={`cursor-pointer hover:text-primary ${selectedLayout === "list" ? "bg-backdrop text-white p-1" : "bg-white p-1"}`}>
              <MenuOutlined />
            </div>
          </div>
        </div>
        {selectedUserType === 'expert' && (
          <Button
            className="bg-primary text-white"
            onClick={() => setOpenModal(true)}
          >
            Invite an Expert
          </Button>
        )}
      </div>

      <div className="px-2 bg-white -mt-3">
        <div className="mt-8 px-1 py-2">
          {selectedUserType === 'expert' ? (
            requestExpertQuery.isLoading ? (
              <div className="flex justify-center items-center h-full w-full">
                <LoadingOutlined className="text-3xl text-primary" />
              </div>
            ) : requestExpertQuery?.data?.data?.data?.length === 0 || requestExpertQuery?.data?.data?.data?.length === undefined ? (
              <Empty description="No Requests." />
            ) : (
              <div className={`${selectedLayout === "list" ? "grid grid-cols-1" : "grid grid-cols-2"} w-full gap-4`}>
                {requestExpertQuery?.data?.data?.data?.map((request) => (
                  <RequestExpert key={request.id} request={request} />
                ))}
              </div>
            )
          ) : requestMentorQuery.isLoading ? (
            <div className="flex justify-center items-center h-full w-full">
              <LoadingOutlined className="text-3xl text-primary" />
            </div>
          ) : requestMentorQuery?.data?.data?.data?.length === 0 || requestMentorQuery?.data?.data?.data?.length === undefined ? (
            <Empty description="No Requests." />
          ) : (
            <div className={`${selectedLayout === "list" ? "grid grid-cols-1" : "grid grid-cols-2"} w-full gap-4`}>
              {requestMentorQuery?.data?.data?.data?.map((request) => (
                <RequestMentor key={request.id} request={request} />
              ))}
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Invite Expert"
        open={openModal}
        onClose={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        onOk={async () => {
          await inviteExpertMutation.mutateAsync({
            email: expertEmail,
          })
          setOpenModal(false)
        }}
        okButtonProps={{
          loading: inviteExpertMutation.isLoading,
          className: "bg-primary text-white",
          disabled:
            expertEmail?.trim() === "" ||
            expertEmail === null ||
            expertEmail === undefined ||
            expertEmail?.includes("@") === false ||
            expertEmail?.length < 5,
        }}
        okText="Invite"
      >
        <Input
          placeholder="Enter Expert Email"
          value={expertEmail}
          onChange={(e) => setExpertEmail(e.target.value)}
        />
      </Modal>
    </div>
  )
}



export const dummyRequests = [
  {
    id: '1',
    username: 'JohnDoe',
    article_link: 'https://www.example.com/article1',
    english_proficiency: 4,
    user_id: 'user_1',
    justification: 'I have extensive experience in this field and have been actively contributing to research and development for the past 10 years. My work has been recognized by various international organizations and I have a deep understanding of the latest technologies and methodologies.',
  },
  {
    id: '2',
    username: 'JaneSmith',
    article_link: 'https://www.example.com/article2',
    english_proficiency: 3,
    user_id: 'user_2',
    justification: 'My background and skills match the requirements perfectly. I have a proven track record of success in similar projects and I am confident that I can bring valuable insights and innovative solutions to this initiative. I am also adept at collaborating with diverse teams.',
  },
  {
    id: '3',
    username: 'AliceJohnson',
    article_link: 'https://www.example.com/article3',
    english_proficiency: 5,
    user_id: 'user_3',
    justification: 'I have published several papers in this area and have been invited to speak at numerous conferences. My expertise lies in leveraging cutting-edge research to solve real-world problems. I am passionate about sharing knowledge and mentoring aspiring professionals.',
  },
  {
    id: '4',
    username: 'BobBrown',
    article_link: 'https://www.example.com/article4',
    english_proficiency: 2,
    user_id: 'user_4',
    justification: 'I am eager to contribute to this project and believe my unique perspective will be an asset. I have been involved in various community outreach programs and have a strong commitment to making a positive impact. My hands-on experience and practical skills are my strengths.',
  },
]


export const dummyMentorRequests = [
  {
    id: '1',
    username: 'JohnDoe',
    location: 'New York, USA',
    hours_per_month: '10 hours/month',
    domain_expertise: 'landscape, green design',
    mentorship_areas: 'career progression, networking',
    motivation: 'I have a passion for mentoring and have been involved in various community outreach programs. I believe my experience can help others achieve their goals.With over 20 years of experience in project management, I am excited to share my knowledge and help mentees navigate their career paths.',
  },
  {
    id: '2',
    username: 'JaneSmith',
    location: 'San Francisco, USA',
    hours_per_month: '8 hours/month',
    domain_expertise: 'software engineering, AI',
    mentorship_areas: 'technical skills, leadership',
    motivation: 'Mentoring is a way for me to give back to the community. I have been in the tech industry for over 15 years and have a wealth of knowledge to share.With over 20 years of experience in project management, I am excited to share my knowledge and help mentees navigate their career paths.',
  },
  {
    id: '3',
    username: 'AliceJohnson',
    location: 'London, UK',
    hours_per_month: '12 hours/month',
    domain_expertise: 'digital marketing, SEO',
    mentorship_areas: 'branding, strategy',
    motivation: 'I enjoy helping others grow and develop their careers. My background in digital marketing allows me to provide valuable insights and guidance. With over 20 years of experience in project management, I am excited to share my knowledge and help mentees navigate their career paths.',
  },
  {
    id: '4',
    username: 'BobBrown',
    location: 'Sydney, Australia',
    hours_per_month: '6 hours/month',
    domain_expertise: 'project management, agile',
    mentorship_areas: 'project planning, execution',
    motivation: 'With over 20 years of experience in project management, I am excited to share my knowledge and help mentees navigate their career paths.With over 20 years of experience in project management, I am excited to share my knowledge and help mentees navigate their career paths.',
  },
]

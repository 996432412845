import React from "react";
import { useApplicationStore } from "../../../store/applicationStore";
import { Email } from "../components/Email";

export const DetailEmail = () => {
  const { setLinks } = useApplicationStore();
  React.useEffect(() => {
    setLinks([]);
  }, [setLinks]);
  return (
    <div>
      <div className=" flex justify-between items-center mb-5">
        <h1 className="m-0 text-2xl font-semibold">Detail Email</h1>
      </div>
      <Email isDetail={true} />
    </div>
  );
};

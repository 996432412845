import { useQuery, useMutation, useQueryClient } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";

const fetchExpertRequests = async () => {
  return httpRequest.get("/skilledin/expert-requests");
};

/**
 * @param {object} data
 * @param {string} data.email
 */
const inviteExpert = (data) => {
  return httpRequest.post("/skilledin/expert-requests/invite", data);
};

/**
 * @param {string} id
 * @param {object} data
 * @param {string} data.reason
 * @param {string} data.status
 * @param {string} data.user_id
 */
const updateExpertRequest = (id, data) => {
  return httpRequest.patch(`/skilledin/expert-requests/${id}`, {
    ...data,
  });
};

export const useExpertRequest = () => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching expert requests: ${error}`);
  };

  return useQuery(["admin-expert-requests"], fetchExpertRequests, {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
};

export const useInviteExpert = () => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching expert requests: ${error}`);
  };

  return useMutation((data) => inviteExpert(data), {
    onError: handleFetchError,
  });
};

export const useUpdateExpertRequest = () => {
  const queryClient = useQueryClient();
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching expert requests: ${error}`);
  };

  return useMutation(({ id, data }) => updateExpertRequest(id, data), {
    onError: handleFetchError,
    onSuccess: () => {
      queryClient.invalidateQueries("admin-expert-requests");
      queryClient.invalidateQueries("admin-talent-analytics");
      message.success("Expert request updated successfully");
    },
  });
};

import React from "react"
import {
  CheckOutlined,
  CloseOutlined,
  LinkedinOutlined,
  LinkOutlined,
} from "@ant-design/icons"
import { SquareRate } from "./SquareRate"
import { Popconfirm, Input } from "antd"
import { useUpdateExpertRequest } from "../hooks/useExpertRequest"

export const RequestExpert = ({ request }) => {
  const [reason, setReason] = React.useState(null)
  const updateExpertRequest = useUpdateExpertRequest()
  return (
    <div className="bg-white-custom px-3 py-2 rounded" style={{ border: "2px solid #EDEDED" }}>
      <div className="flex items-center w-full flex-wrap sm:flex-nowrap">
        <div className="w-4/5 flex items-center gap-x-12">
          <div className="font-bold">{request.username}</div>
          <div className="flex gap-x-2">
            <LinkedinOutlined
              className="cursor-pointer"
              onClick={() => window.open(request.linkedin_link, "_blank")}
            />
            <LinkOutlined
              className="cursor-pointer"
              onClick={() => window.open(request.article_link, "_blank")}
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="">English:</div>
            <SquareRate value={request.english_proficiency} />
          </div>
        </div>
        <div className="w-1/5 flex gap-x-7 justify-end">
          <div
            onClick={() => {
              updateExpertRequest.mutate({
                id: request.id,
                data: { status: "APPROVED", user_id: request.user_id },
              })
            }}
            className="bg-primary text-white text-sm rounded-full flex items-center justify-center p-4 w-8 h-8 hover:scale-105 cursor-pointer transition-all duration-200">
            <CheckOutlined />
          </div>
          <Popconfirm
            title="Reject Request"
            icon={null}
            description={
              <div>
                {
                  <Input.TextArea
                    rows={3}
                    className="w-full mt-2"
                    placeholder="Enter reason for rejection"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                }
              </div>
            }
            onConfirm={() => {
              updateExpertRequest.mutate({
                id: request.id,
                data: { reason, status: "REJECTED", user_id: request.user_id },
              })
            }}
            okButtonProps={{
              loading: updateExpertRequest.isLoading,
              type: "default",
              disabled:
                reason?.trim() === "" ||
                reason === null ||
                reason === undefined,
              className: "bg-danger text-white",
            }}
            okText="Reject"
            cancelText="Cancel">
            <div
              className="bg-white text-backdrop text-sm rounded-full flex items-center justify-center p-4 w-8 h-8 hover:scale-105 cursor-pointer transition-all duration-200"
              style={{ border: "1px dashed red" }}>
              <CloseOutlined />
            </div>
          </Popconfirm>
        </div>
      </div>
      <div className="mt-5 leading-6">{request.justification}</div>
    </div>
  )
}

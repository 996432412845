/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";

export const useRecruiterList = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });

  const [dataList, setDataList] = useState([]);
  const [total, setTotal] = useState(0);

  const { data, isLoading } = useQuery(
    ["list-recruiter", params],
    () => httpRequest.get("/skilledin/subscriptions/companies", { params }),
    {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching companies"
        );
      },
    }
  );

  const handleChageParams = (newParams) => {
    setParams((prev) => ({ ...prev, ...newParams }));
  };

  const observer = useRef(null);
  const lastCardElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          dataList.length &&
          dataList?.length < total &&
          !isLoading
        ) {
          handleChageParams({ page: params.page + 1 });
        }
      });
      if (node) observer.current.observe(node);
    },

    [dataList?.length]
  );

  useEffect(() => {
    if (!isLoading) {
      if (params.page === 1) {
        setDataList(data?.data.data?.list || []);
        setTotal(data?.data.data.total_data || 0);
      } else if (
        dataList?.[dataList?.length - 1]?.id !==
        data?.data.data.list?.[data?.data.data.list?.length - 1]?.id
      ) {
        setDataList((prev) => [...prev, ...(data?.data.data.list || [])]);
        setTotal(data?.data.data.total_data || 0);
      }
    }
  }, [data?.data.data.list]);

  // action for applied coupon
  const [currentSubs, setCurrentSubs] = useState(null);
  const { mutateAsync, isLoading: isLoadingMutate } = useMutation(
    "recruiter-apply-coupon",
    (data) => {
      return httpRequest.patch("/skilledin/subscriptions/coupons/apply", data);
    }
  );

  const handleUpdate = async (data) => {
    try {
      setCurrentSubs(data?.stripe_subscription_id);
      const res = await mutateAsync(data);
      if (res) {
        for (let i = 0; i < dataList.length; i++) {
          if (
            dataList[i]?.subscriptions?.stripe_subscription_id ===
            data?.stripe_subscription_id
          ) {
            dataList[i].subscriptions.metadata_subscription = res?.data?.data;
          }
        }
      }
      setCurrentSubs(null);
      message.success("Trial settings updated successfully");
    } catch (error) {
      setCurrentSubs(null);
      message.error(
        error?.response?.data?.message ||
          "An error occurred while updating trial settings"
      );
    }
  };

  return {
    dataList,
    isLoading,
    lastCardElementRef,
    params,
    setParams,
    actions: {
      currentSubs,
      handleUpdate,
      isLoading: isLoadingMutate,
    },
  };
};

import { Button, Drawer, Input, DatePicker, Tag, Spin, Select } from "antd";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { CloseOutlined } from "@ant-design/icons";
import { useEditTrainerDrawer } from "../hooks/useEditTrainerDrawer";

const EditTrainerDrawer = ({ open, onClose, onSubmit, trainer }) => {
  const {
    control,
    handleSubmit,
    errors,
    greenSkillData,
    greenSkillLoading,
    optionsGS,
    handleSearchGS,
    handleScrollGS,
    onSelectGS,
    handleDeleteGreenSkill,
    onSaveSubmit,
    params,
  } = useEditTrainerDrawer(onClose, trainer);

  return (
    <Drawer
      closable={false}
      placement="right"
      size="large"
      onClose={onClose}
      open={open}
    >
      <form onSubmit={handleSubmit(onSaveSubmit)}>
        <p className="font-bold text-xl text-backdrop mb-5">Edit School</p>
        <div className="mb-5 space-y-6">
          <div className="flex items-center gap-4">
            <div className="flex flex-col items-center-justify-start gap-2 w-1/2">
              <Controller
                control={control}
                name="first_name"
                render={({ field }) => (
                  <Input placeholder="First Name" {...field} />
                )}
              />
              {errors.first_name && (
                <p className="text-red-500">{errors.first_name.message}</p>
              )}
            </div>
            <div className="flex flex-col items-center-justify-start gap-2 w-1/2">
              <Controller
                control={control}
                name="last_name"
                render={({ field }) => (
                  <Input placeholder="Last Name" {...field} />
                )}
              />
              {errors.last_name && (
                <p className="text-red-500">{errors.last_name.message}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center-justify-start gap-2">
            <Controller
              control={control}
              name="display_name"
              render={({ field }) => (
                <Input placeholder="Display Name" {...field} />
              )}
            />
            {errors.display_name && (
              <p className="text-red-500">{errors.display_name.message}</p>
            )}
          </div>
          <div className="flex flex-col items-center-justify-start gap-2">
            <Controller
              control={control}
              name="email"
              render={({ field }) => <Input placeholder="Email" {...field} />}
            />
            {errors.email && (
              <p className="text-red-500">{errors.email.message}</p>
            )}
          </div>

          <div className="flex flex-col items-center-justify-start gap-2">
            <Controller
              control={control}
              name="sharing_profit"
              render={({ field }) => (
                <Input
                  type="number"
                  placeholder="School's share in the profit"
                  {...field}
                />
              )}
            />
            {errors.sharing_profit && (
              <p className="text-red-500">{errors.sharing_profit.message}</p>
            )}
          </div>
          <div className="flex flex-col items-center-justify-start gap-2">
            <Controller
              control={control}
              name="contract_expired_at"
              render={({ field }) => {
                console.log(field.value);
                return (
                  <DatePicker
                    allowClear={false}
                    placeholder="Contract Expiry Date"
                    {...field}
                    defaultValuevalue={field.value && dayjs(field.value)}
                    format="DD MMM YYYY"
                  />
                );
              }}
            />
            {errors?.contract_expired_at && (
              <p className="text-red-500">
                {errors?.contract_expired_at.message}
              </p>
            )}
          </div>

          {/* <AutoComplete
            dropdownMatchSelectWidth={false}
            className="w-full"
            options={
              greenSkillLoading
                ? [
                    ...optionsGS?.map((option) => ({
                      value: option?.name,
                    })),
                    {
                      value: (
                        <div className="py-5" style={{ textAlign: "center" }}>
                          <Spin />
                        </div>
                      ),
                    },
                  ]
                : optionsGS?.map((option) => ({
                    value: option?.name,
                  }))
            }
            onSelect={onSelectGS}
            onSearch={handleSearchGS}
            onPopupScroll={handleScrollGS}
          >
            <Input
              addonBefore={<SearchIcon2 width={20} height={20} />}
              placeholder="Search to add"
              addonAfter={false}
              enterButton
              className="w-full"
              value={searchValueGS}
            />
            {greenSkillLoading && <Spin />}
          </AutoComplete> */}

          <Select
            showSearch
            // dropdownMatchSelectWidth={false}
            className="w-full"
            filterOption={false}
            labelInValue
            options={
              greenSkillLoading
                ? [
                    ...optionsGS?.map((option) => ({
                      label: option.name,
                      value: option.id,
                    })),
                    {
                      value: <Spin />,
                      disabled: true,
                    },
                  ]
                : optionsGS?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
            }
            notFoundContent={<div className="py-1 px-3">skill not found!</div>}
            value={params.search}
            onPopupScroll={handleScrollGS}
            placeholder="Type to search"
            placement="bottomLeft"
            onSearch={handleSearchGS}
            onSelect={onSelectGS}
          />
          <div className="mt-11 flex flex-wrap w-full gap-2">
            {greenSkillData?.map((techSkill, index) => (
              <Tag
                key={index}
                className="bg-primary-bg text-backdrop border !border-backdrop"
                icon={
                  <CloseOutlined
                    onClick={() => handleDeleteGreenSkill(techSkill?.value)}
                  />
                }
              >
                {techSkill.label}
              </Tag>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-end gap-3">
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" className="bg-primary" htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

export default EditTrainerDrawer;

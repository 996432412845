import React from "react";
// import InputSearch from "../../../common/components/InputSearch";
import { TalentUsersTable } from "../components";
import { renderNavLink } from "../../../common/components/NavLink";
import { useApplicationStore } from "../../../store/applicationStore";

export const TalentUsers = () => {
  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([
      renderNavLink("/admin/talent/users", "Users"),
      renderNavLink("/admin/talent/requests", "Requests"),
      renderNavLink("/admin/sales", "Sales"),
    ]);
  }, [setLinks]);
  // const handleSearch = () => {};
  return (
    <div>
      <p className="text-xl font-extrabold text-backdrop mb-3">Users</p>

      {/* <div className='flex items-center jusitfy-start gap-x-7 mt-7'>
        <div className='flex-grow'>
          <InputSearch
            handleSearchChange={handleSearch}
            placeholder={"Input search text"}
          />
        </div>
      </div> */}

      {/* TABLE */}
      <TalentUsersTable />

      {/* <div className="text-right mt-4">
        <Pagination
        //  current={page}
        //  pageSize={limit}
        //  total={listMyskill?.list?.count || 0}
        //  onChange={(page, pageSize) =>
        //    handlePageChangePagination(page, pageSize)
        //  }
        />
      </div> */}
    </div>
  );
};

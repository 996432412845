import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";
import { useNavigate, useParams } from "react-router-dom";

export const useMailForm = () => {
  const {
    handleSubmit,
    control,
    reset,
    trigger,
    setValue,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      target_app: null,
      user_group: null,
      mail_category: null,
      subject: "",
      content: "",
    },
  });

  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data,
    isError,
    isLoading: isLoadingDetail,
    isFetching,
  } = useQuery(
    ["mail", id],
    () => httpRequest.get(`/skilledin/mailings/${id}`),
    {
      enabled: !!id,
    }
  );
  useEffect(() => {
    if (data?.data?.data && id) {
      reset({
        target_app: data?.data?.data?.target?.app,
        user_group: data?.data?.data?.target?.audience,
        mail_category: data?.data?.data?.category,
        subject: data?.data?.data?.subject,
        content: data?.data?.data?.content,
      });
    }
  }, [data?.data?.data, id, reset]);

  const { data: dataTargetApp, isLoading } = useQuery(
    "target-app",
    () => httpRequest.get("/skilledin/mailings/references/apps"),
    {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching target apps"
        );
      },
    }
  );

  const { data: dataUserGroup, isLoadingUserGroup } = useQuery(
    ["user-group", watch("target_app")],
    () =>
      httpRequest.get(
        `/skilledin/mailings/references/groups?key=${watch("target_app")}`
      ),
    {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching user groups"
        );
      },
      enabled: !!getValues("target_app"),
    }
  );

  const { data: dataEmailType, isLoadingEmailType } = useQuery(
    ["email-type", watch("user_group")],
    () =>
      httpRequest.get(
        `/skilledin/mailings/references/categories?key=${watch("user_group")}`
      ),
    {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching user groups"
        );
      },
      enabled: !!getValues("user_group"),
    }
  );

  const { mutateAsync, isLoading: isLoadingSendEmail } = useMutation(
    "create-mail",
    (data) => httpRequest.post("/skilledin/mailings", data)
  );

  const handleSendEmail = async (val) => {
    try {
      message.open({
        content: "Sending email...",
        key: "send-email",
        duration: 0,
      });
      await mutateAsync(val);
      message
        .open({
          content: "Email sent successfully",
          key: "send-email",
        })
        .then(() => navigate("/admin/marketing/mail"));
    } catch (error) {
      message.open({
        key: "send-email",
        content:
          error?.response?.data?.message ||
          "An error occurred while sending email",
      });
    }
  };

  return {
    state: { isError, isLoading: isLoadingDetail || isFetching },
    form: {
      handleSubmit,
      control,
      reset,
      trigger,
      setValue,
      getValues,
      errors,
      isDirty,
    },
    targetApps: {
      data: dataTargetApp?.data?.data,
      isLoading,
    },
    userGroups: {
      data: dataUserGroup?.data?.data,
      isLoading: isLoadingUserGroup,
    },
    emailTypes: {
      data: dataEmailType?.data?.data,
      isLoading: isLoadingEmailType,
    },
    actionAddEmail: {
      handleSendEmail,
      isLoading: isLoadingSendEmail,
    },
  };
};

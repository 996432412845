import React, { useState } from "react";
import CustomHeader from "../../../common/layout/CustomHeader";
import { Link, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  // CheckCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
// import ViewCard from "../components/ViewCard";
import { Skeleton, Button, Popconfirm, Input, Tabs } from "antd";
import {
  useCourseDetail,
  usePublishCourse,
  useRejectCourse,
} from "../hooks/useListCourses";
// import { CourseReviewModules, CourseReviewAssessment } from "../components";
import Cardv2 from "../components/CardDescription";
import Module from "../components/Module";
import Assesment from "../components/Assesment";

export const loader =
  (queryClient) =>
  async ({ params }) => {
    const query = useCourseDetail(params.objectiveId);
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };

export const CourseReview = () => {
  const { id } = useParams();
  const courseReviewQuery = useCourseDetail(id);
  const publishCourseMutate = usePublishCourse();
  const publishRejectMutate = useRejectCourse();

  // const tabs = [
  //   {
  //     label: "Module 1",
  //     component: courseReviewQuery?.isLoading ? (
  //       <div className="grid grid-cols-12 gap-1 mt-7">
  //         <div className="col-span-6">
  //           <Skeleton />
  //         </div>
  //         <div className="col-span-6">
  //           <Skeleton />
  //         </div>
  //       </div>
  //     ) : (
  //       <CourseReviewModules
  //         old_content={
  //           courseReviewQuery?.data?.data?.data?.content_exist_version?.[0]
  //         }
  //         new_content={
  //           courseReviewQuery?.data?.data?.data?.content_new_version?.[0]
  //         }
  //       />
  //     ),
  //     icon: <CheckCircleOutlined />,
  //   },
  //   {
  //     label: "Module 2",
  //     component: courseReviewQuery?.isLoading ? (
  //       <div className="grid grid-cols-12 gap-1 mt-7">
  //         <div className="col-span-6">
  //           <Skeleton />
  //         </div>
  //         <div className="col-span-6">
  //           <Skeleton />
  //         </div>
  //       </div>
  //     ) : (
  //       <CourseReviewModules
  //         old_content={
  //           courseReviewQuery?.data?.data?.data?.content_exist_version?.[1]
  //         }
  //         new_content={
  //           courseReviewQuery?.data?.data?.data?.content_new_version?.[1]
  //         }
  //       />
  //     ),
  //     icon: <CheckCircleOutlined />,
  //   },
  //   {
  //     label: "Module 3",
  //     component: courseReviewQuery?.isLoading ? (
  //       <div className="grid grid-cols-12 gap-1 mt-7">
  //         <div className="col-span-6">
  //           <Skeleton />
  //         </div>
  //         <div className="col-span-6">
  //           <Skeleton />
  //         </div>
  //       </div>
  //     ) : (
  //       <CourseReviewModules
  //         old_content={
  //           courseReviewQuery?.data?.data?.data?.content_exist_version?.[2]
  //         }
  //         new_content={
  //           courseReviewQuery?.data?.data?.data?.content_new_version?.[2]
  //         }
  //       />
  //     ),
  //     icon: <CheckCircleOutlined />,
  //   },
  //   {
  //     label: "Module 4",
  //     component: courseReviewQuery?.isLoading ? (
  //       <div className="grid grid-cols-12 gap-1 mt-7">
  //         <div className="col-span-6">
  //           <Skeleton />
  //         </div>
  //         <div className="col-span-6">
  //           <Skeleton />
  //         </div>
  //       </div>
  //     ) : (
  //       <CourseReviewModules
  //         old_content={
  //           courseReviewQuery?.data?.data?.data?.content_exist_version?.[3]
  //         }
  //         new_content={
  //           courseReviewQuery?.data?.data?.data?.content_new_version?.[3]
  //         }
  //       />
  //     ),
  //     icon: <CheckCircleOutlined />,
  //   },
  //   {
  //     label: "Assessment",
  //     component: (
  //       <CourseReviewAssessment
  //         old_questions={
  //           courseReviewQuery?.data?.data?.data?.content_exist_version?.[4]
  //         }
  //         new_questions={
  //           courseReviewQuery?.data?.data?.data?.content_exist_version?.[4]
  //         }
  //       />
  //     ),
  //     icon: <CheckCircleOutlined />,
  //   },
  // ];

  // const [activeTab, setActiveTab] = useState(0);
  const [reason, setReason] = useState("");

  const tabs2 = [
    {
      label: "Module 1",
      key: "1",
      children: (
        <Module
          existingData={courseReviewQuery?.data?.data?.data?.content_exist_version?.modules?.find(
            (itm) => itm.name === "module 1"
          )}
          newData={courseReviewQuery?.data?.data?.data?.content_new_version?.modules?.find(
            (itm) => itm.name === "module 1"
          )}
        />
      ),
    },
    {
      label: "Module 2",
      key: "2",
      children: (
        <Module
          existingData={courseReviewQuery?.data?.data?.data?.content_exist_version?.modules?.find(
            (itm) => itm.name === "module 2"
          )}
          newData={courseReviewQuery?.data?.data?.data?.content_new_version?.modules?.find(
            (itm) => itm.name === "module 2"
          )}
        />
      ),
    },
    {
      label: "Module 3",
      key: "3",
      children: (
        <Module
          existingData={courseReviewQuery?.data?.data?.data?.content_exist_version?.modules?.find(
            (itm) => itm.name === "module 3"
          )}
          newData={courseReviewQuery?.data?.data?.data?.content_new_version?.modules?.find(
            (itm) => itm.name === "module 3"
          )}
        />
      ),
    },
    {
      label: "Module 4",
      key: "4",
      children: (
        <Module
          existingData={courseReviewQuery?.data?.data?.data?.content_exist_version?.modules?.find(
            (itm) => itm.name === "module 4"
          )}
          newData={courseReviewQuery?.data?.data?.data?.content_new_version?.modules?.find(
            (itm) => itm.name === "module 4"
          )}
        />
      ),
    },
    {
      label: "Assessment",
      key: "5",
      children: (
        <Assesment
          existingData={
            courseReviewQuery?.data?.data?.data?.content_exist_version
              ?.assessments
          }
          newData={
            courseReviewQuery?.data?.data?.data?.content_new_version
              ?.assessments
          }
        />
      ),
    },
  ];

  return (
    <div>
      <div className="ml-[-16.5rem]">
        <CustomHeader />
      </div>
      {courseReviewQuery.isLoading ? (
        <div>
          <Skeleton active title={false} paragraph={{ rows: 15 }} />
        </div>
      ) : (
        <>
          <div className="flex justify-between gap-5">
            <div className="flex gap-3">
              <Link to="/admin/courses" className="p-1">
                <ArrowLeftOutlined className="text-backdrop font-bold text-lg" />
              </Link>
              <span className="text-lg font-bold text-backdrop">
                {courseReviewQuery?.data?.data?.data?.skill_name}
              </span>
            </div>
            <div className=" flex  gap-3">
              <Popconfirm
                title="Reject publishing this Course"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                description={
                  <div>
                    <div className=" text-sm">Reason</div>
                    {
                      <Input.TextArea
                        rows={3}
                        className="w-full mt-2"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    }
                  </div>
                }
                onConfirm={() => {
                  publishRejectMutate.mutate({ id, reason });
                }}
                okButtonProps={{
                  loading: publishRejectMutate.isLoading,
                  type: "default",
                  disabled:
                    reason?.trim() === "" ||
                    reason === null ||
                    reason === undefined,
                  className: "bg-primary text-white",
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>Reject</Button>
              </Popconfirm>
              <Popconfirm
                placement="bottomLeft"
                title="Publish the Level"
                description="Are you sure you want to publish this course?"
                onConfirm={() => {
                  publishCourseMutate.mutate(id);
                }}
                okButtonProps={{
                  loading: publishCourseMutate.isLoading,
                  type: "default",
                  className: "bg-primary text-white",
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" className="bg-primary">
                  Approve
                </Button>
              </Popconfirm>
            </div>
          </div>
          <div className="mt-10">
            {/* header title*/}
            <div className=" grid grid-cols-2 gap-5">
              <div className=" capitalize font-semibold">existing version</div>
              <div className=" capitalize font-semibold">new version</div>
            </div>
            {/* learning outcome*/}
            <Cardv2
              name={"learning outcomes"}
              existingData={
                courseReviewQuery?.data?.data?.data?.content_exist_version
                  ?.learning_outcomes
              }
              newData={
                courseReviewQuery?.data?.data?.data?.content_new_version
                  ?.learning_outcomes
              }
            />
          </div>

          <Tabs defaultActiveKey="1" items={tabs2} />
        </>
      )}

      {/* <div className="grid grid-cols-12 gap-1 mt-7">
        <div className="col-span-6">
          <p className="text-lg font-bold text-backdrop mb-6">
            Existing Version
          </p>
          {courseReviewQuery.isLoading ? (
            <Skeleton />
          ) : (
            <ViewCard
              title="Learning Outcome"
              content={
                courseReviewQuery?.data?.data?.data
                  ?.learning_outcomes_exist_version
              }
            />
          )}
        </div>
        <div className="col-span-6">
          <p className="text-lg font-bold text-backdrop mb-6">New Version</p>
          {courseReviewQuery.isLoading ? (
            <Skeleton />
          ) : (
            <ViewCard
              title="_"
              content={
                courseReviewQuery?.data?.data?.data
                  ?.learning_outcomes_new_version
              }
            />
          )}
        </div>
      </div>

      <div className="mt-5 top-24 sticky">
        <div className="flex flex-col top-24 sticky">
          <div className="flex pb-2 relative">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`pr-12 py-3 relative cursor-pointer ${
                  index === activeTab ? "" : ""
                }`}
                onClick={() => setActiveTab(index)}
              >
                <span
                  className={`absolute bottom-0 left-0 right-12 ${
                    index === activeTab
                      ? "border-b-[3px] border-primary"
                      : "border-b border-gray-300"
                  }`}
                  style={{
                    width: index === activeTab ? "auto" : "0",
                    transition: "width 0.3s ease-in-out",
                  }}
                ></span>

                <div className="flex items-center gap-2">
                  <span
                    className={` ${index === activeTab ? "text-primary" : ""}`}
                  >
                    {tab.label}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="border-b border-gray-300 pb-3 -mt-[21px] inset-x-0 bottom-0 mb-6 w-[45%]"></div>
          <div className="mb-10">{tabs[activeTab].component}</div>
        </div>
      </div> */}
    </div>
  );
};

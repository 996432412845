import React, { useRef, useImperativeHandle } from "react";
import { Upload } from "antd";

export const UploadWithFocus = React.forwardRef((props, ref) => {
  const { onFocus, ...rest } = props;
  const uploadRef = useRef();

  useImperativeHandle(ref, () => ({
    ...uploadRef.current,
    focus: onFocus || (() => {}),
  }));

  return (
    <Upload
      ref={uploadRef}
      {...rest}
    />
  );
});

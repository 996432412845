import { Link } from "react-router-dom";
import {
  BulbOutlined,
  TeamOutlined,
  AppstoreOutlined,
  ShopOutlined,
  LockOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

export const sidebarParentItems = {
  greenSkills: "/admin/green-skills",
  jobsCompanies: "/admin/jobs-companies",
  talent: "/admin/talent",
  marketing: "/admin/marketing",
  community: "/admin/community",
  dataPrivacy: "/admin/data-privacy",
};

export const sidebarChildrenItems = {
  greenSkills: {
    skills: "/green-skills/skills",
    schools: "/green-skills/schools",
    courses: "/green-skills/courses",
  },
  jobsCompanies: {
    jobRoles: "/jobs-companies/job-roles",
    recruiters: "/jobs-companies/recruiters",
    coupons: "/jobs-companies/coupons",
  },
  talent: {
    users: "/talent/users",
    skillVerification: "/talent/skill-verification",
  },
  marketing: {
    perks: "/marketing/perks",
    analytics: "/marketing/analytics",
  },
  dataPrivacy: {
    talentPolicies: "/data-privacy/talent-policies/privacy",
    recruiterPolicies: "/data-privacy/recruiter-policies/privacy",
    trainerPolicies: "/data-privacy/trainer-policies/privacy",
  },
  community: {
    thisWeeksDiscussion: "/community/this-weeks-discussion",
  },
};

export const sidebarItems = [
  {
    label: "Green Skills",
    key: sidebarParentItems.greenSkills,
    icon: <BulbOutlined />,
    children: [
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.greenSkills}/skills`}>
            Skills
          </Link>
        ),
        key: `${sidebarParentItems.greenSkills}/skills`,
      },
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.greenSkills}/trainers`}>
            Schools
          </Link>
        ),
        key: `${sidebarParentItems.greenSkills}/schools`,
      },
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.greenSkills}/courses`}>
            Courses
          </Link>
        ),
        key: `${sidebarParentItems.greenSkills}/courses`,
      },
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.greenSkills}/currencies`}>
            Currencies
          </Link>
        ),
        key: `${sidebarParentItems.greenSkills}/currencies`,
      },
    ],
  },
  {
    label: "Jobs & Companies",
    key: sidebarParentItems.jobsCompanies,
    icon: <ShopOutlined />,
    children: [
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.jobsCompanies}/job-roles`}>
            Job Roles
          </Link>
        ),
        key: `${sidebarParentItems.jobsCompanies}/job-roles`,
      },
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.jobsCompanies}/recruiters`}>
            Recruiters
          </Link>
        ),
        key: `${sidebarParentItems.jobsCompanies}/recruiters`,
      },
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.jobsCompanies}/coupons`}>
            Coupons
          </Link>
        ),
        key: `${sidebarParentItems.jobsCompanies}/coupons`,
      },
    ],
  },
  {
    label: "Talent",
    key: sidebarParentItems.talent,
    icon: <TeamOutlined />,
    children: [
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.talent}/users`}>
            Users
          </Link>
        ),
        key: `${sidebarParentItems.talent}/users`,
      },
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.talent}/skill-verification`}>
            Skill Verification
          </Link>
        ),
        key: `${sidebarParentItems.talent}/skill-verification`,
      },
    ],
  },
  {
    label: "Marketing",
    key: sidebarParentItems.marketing,
    icon: <AppstoreOutlined />,
    children: [
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.marketing}/perks`}>
            Perks
          </Link>
        ),
        key: `${sidebarParentItems.marketing}/perks`,
      },
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.marketing}/mail`}>
            Email
          </Link>
        ),
        key: `${sidebarParentItems.marketing}/mail`,
      },
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.marketing}/analytics`}>
            Analytics
          </Link>
        ),
        key: `${sidebarParentItems.marketing}/analytics`,
      },
    ],
  },
  {
    label: "Community",
    key: sidebarParentItems.community,
    icon: <UsergroupAddOutlined />,
    children: [
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.community}/this-weeks-discussion`}>
            This Weeks Discussion
          </Link>
        ),
        key: `${sidebarParentItems.marketing}`,
      },
    ],
  },
  {
    label: "Data Privacy",
    key: sidebarParentItems.dataPrivacy,
    icon: <LockOutlined />,
    children: [
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.dataPrivacy}/talent-policies/privacy`}>
            Talent policies
          </Link>
        ),
        key: `${sidebarParentItems.dataPrivacy}/talent-policies/privacy`,
      },
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.dataPrivacy}/recruiter-policies/privacy`}>
            Recruiter policies
          </Link>
        ),
        key: `${sidebarParentItems.dataPrivacy}/recruiter-policies/privacy`,
      },
      {
        label: (
          <Link
            className={`text-md text-backdrop`}
            to={`${sidebarParentItems.dataPrivacy}/trainer-policies/privacy`}>
            Trainer policies
          </Link>
        ),
        key: `${sidebarParentItems.dataPrivacy}/trainer-policies/privacy`,
      },
    ],
  },
];

export function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export const items = sidebarItems.map((item) => {
  return getItem(item.label, item.key, item.icon, item.children);
});

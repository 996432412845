import React, { useEffect } from "react";
import { renderNavLink } from "../../../common/components/NavLink";
import { Button, InputNumber, Spin } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useSubscriptionSetting } from "../hooks/useSubscription";
import { LoadingOutlined } from "@ant-design/icons";
import { useApplicationStore } from "../../../store/applicationStore";

const SubscriptionsTrialSetting = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm();

  const { data, action } = useSubscriptionSetting();
  const { setLinks } = useApplicationStore();

  useEffect(() => {
    setLinks([
      renderNavLink("/admin/jobs-companies/coupons", "Coupons"),
      renderNavLink("/admin/jobs-companies/trial-setting", "Trial Setting"),
    ]);
  }, [setLinks]);

  useEffect(() => {
    if (data?.data) {
      setValue("trial_period_days", data?.data?.trial_period_days);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);
  return (
    <div>
      <div>
        <p className="text-xl font-extrabold text-backdrop mb-3">
          Manage Trial Subscriptions
        </p>

        <form className=" flex gap-5 items-center w-96 rounded-xl shadow-md p-5 bg-[#FAFAFA] mt-5">
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
              />
            }
            spinning={data?.isLoading}
          >
            <div className="flex gap-5 items-center text-center">
              <label htmlFor="trial_period_days" className=" w-40">
                Trial Duration Days
              </label>
              <Controller
                name="trial_period_days"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    id="trial_period_days"
                    status={errors?.trial_period_days ? "error" : ""}
                    className=" text-center"
                    placeholder="e.g. 30"
                    controls={false}
                    min={1}
                    max={90}
                  />
                )}
              />
              <Button
                className="bg-primary text-white"
                onClick={handleSubmit(action.handleUpdate)}
                disabled={!isDirty || action?.isLoading}
              >
                Save
              </Button>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default SubscriptionsTrialSetting;

import React from "react";

import { Layout } from "antd";

// import CustomHeader from './CustomHeader'
import CustomSidebar from "./CustomSidebar";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CustomFooter } from "./CustomFooter";
import { useApplicationStore } from "../../store/applicationStore";
import CustomHeader from "./CustomHeader";

const { Content } = Layout;

// eslint-disable-next-line react/prop-types
export default function PageLayout({ children }) {
  const { openSidebar } = useApplicationStore();
  return (
    <Layout className="h-screen">
      <CustomHeader />

      <Layout>
        <AuthenticatedTemplate>
          <div className="fixed">
            <CustomSidebar />
          </div>

          {/* hide = 3.2rem */}
          <Layout
            className={`h-full bg-bg ${
              openSidebar ? "ml-[13.2rem]" : "ml-[4.2rem]"
            } `}>
            <Content
              className="overflow-auto flex flex-col justify-between"
              style={{
                margin: 0,
                // minHeight: 280,
                marginTop: "50px",
              }}>
              {children}
              <CustomFooter isLogged={true} />
            </Content>
          </Layout>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Layout className="bg-bg h-screen">
            <Content
              style={{
                margin: 0,
                marginTop: "50px",
                // minHeight: 280,
              }}>
              {children}
              <CustomFooter isLogged={false} />
            </Content>
          </Layout>
        </UnauthenticatedTemplate>
      </Layout>
    </Layout>
  );
}

import { useMutation, useQuery, useQueryClient } from "react-query";
import { message } from "antd";
import httpRequest from "../../../config/config";
import { useNavigate } from "react-router-dom";

const fetchCourses = (params) => {
  return httpRequest.get("/skilledin/course-review-requests", { params });
};

const fetchCourseToReviewDetail = (id) => {
  return httpRequest.get(`/skilledin/course-review-requests/${id}`);
};

const confirmPublishCourse = (id) => {
  message.open({
    type: "loading",
    content: "loading...",
    key: "approved_review",
  });
  return httpRequest.patch(`/skilledin/course-review-requests/${id}/approve`);
};

const confirmRejectCourse = (body) => {
  message.open({
    type: "loading",
    content: "loading...",
    key: "rejected_review",
  });
  return httpRequest.patch(
    `/skilledin/course-review-requests/${body.id}/reject`,
    { reason: body.reason }
  );
};

const useCourses = (params = {}) => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching the courses: ${error}`);
  };

  return useQuery(["admin-courses", params], () => fetchCourses(params), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
};

const useCourseDetail = (id) => {
  const handleFetchError = (error) => {
    message.error(
      `An error occurred while fetching the course detail: ${error}`
    );
  };
  return useQuery(
    ["admin-course-detail", id],
    () => fetchCourseToReviewDetail(id),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    }
  );
};

const usePublishCourse = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleAddSuccess = () => {
    message.open({
      type: "success",
      content: "course successfully approved",
      key: "approved_review",
    });
    queryClient
      .refetchQueries(["admin-courses"])
      .then(() => navigate("/admin/courses"));
  };
  const handleAddError = (error) => {
    message.open({
      type: "error",
      content: error?.response?.data?.data || "something wrong",
      key: "approved_review",
    });
  };

  return useMutation((id) => confirmPublishCourse(id), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      handleAddSuccess();
    },
    onError: handleAddError,
  });
};

const useRejectCourse = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleAddSuccess = () => {
    message.open({
      type: "success",
      content: "course successfully rejected",
      key: "rejected_review",
    });
    queryClient
      .refetchQueries(["admin-courses"])
      .then(() => navigate("/admin/courses"));
  };
  const handleAddError = (error) => {
    message.open({
      type: "error",
      content: error?.response?.data?.data || "something wrong",
      key: "rejected_review",
    });
  };

  return useMutation((body) => confirmRejectCourse(body), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      handleAddSuccess();
    },
    onError: handleAddError,
  });
};

export { useCourses, useCourseDetail, usePublishCourse, useRejectCourse };

import React from "react";
import { useApplicationStore } from "../../../store/applicationStore";
import { useRecruiterList } from "../hooks/useRecruiter";
import { Divider, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useCoupon } from "../hooks/useCoupon";

const SubscriptionsList = () => {
  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([]);
  }, [setLinks]);
  const {
    dataList: data,
    isLoading,
    lastCardElementRef,
    actions,
  } = useRecruiterList();
  const { data: dataCoupon, isLoading: isLoadingCoupon } = useCoupon();

  return (
    <div>
      <h1 className="m-0 mb-5 text-2xl font-semibold">Recruiter</h1>
      <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
        <div className=" bg-white rounded-xl shadow-md p-5">
          <div>
            <div className=" grid grid-cols-11 px-2 gap-5 text-darkGrey">
              <div className=" col-span-3">Company Detail</div>
              <div className=" col-span-2">Company Size</div>
              <div className=" col-span-2">Register On</div>
              <div className=" col-span-2">Plan</div>
              <div className=" col-span-2">Offer</div>
            </div>
            <Divider className=" my-3 border-1 border-darkGrey" />
          </div>
          {data?.length > 0 ? (
            data?.map((company, i) => (
              <div
                key={i}
                ref={data?.length - 1 === i ? lastCardElementRef : null}
              >
                <div className=" grid grid-cols-11 px-2 items-center gap-5">
                  <div className=" col-span-3 capitalize py-1">
                    <div>{company?.name || "-"}</div>
                    <div className="  mt-2 text-darkGrey">
                      <div>{company?.headquarter}</div>
                    </div>
                  </div>

                  <div className=" col-span-2  py-1">
                    <div>{company?.size || "-"}</div>
                    <div className="  mt-2 text-darkGrey">
                      {company?.sector}
                    </div>
                  </div>
                  <div className=" col-span-2  py-1">
                    <div>
                      {company?.created_at
                        ? dayjs(company?.created_at).format("DD MMM YYYY")
                        : "-"}
                    </div>
                    <div className=" mt-2 text-darkGrey">{`${
                      company?.jobs?.length || 0
                    } jobs`}</div>
                  </div>

                  <div className=" col-span-2 py-1">
                    <div className="  flex gap-1">
                      <div className="capitalize">
                        {company?.plan?.name || "-"}
                      </div>
                      <div>
                        {company?.plan?.name
                          ? `(${
                              company?.subscriptions?.metadata_subscription
                                ?.items?.data?.[0]?.plan?.interval + "ly"
                            })`
                          : ""}
                      </div>
                    </div>
                    <div className=" truncate  mt-2 text-darkGrey">
                      {company?.subscriptions?.owner_email || ""}
                    </div>
                  </div>
                  <div className=" col-span-2 py-1">
                    {company?.subscriptions?.subscription_status === "active" ||
                    company?.subscriptions?.subscription_status ===
                      "trialing" ? (
                      actions?.currentSubs ===
                        company?.subscriptions?.stripe_subscription_id &&
                      actions.isLoading ? (
                        <div className=" flex gap-3 items-center">
                          <LoadingOutlined /> <div>please wait . . .</div>
                        </div>
                      ) : company?.subscriptions?.metadata_subscription
                          ?.discount ? (
                        <>
                          <div className=" capitalize">
                            {
                              company?.subscriptions?.metadata_subscription
                                ?.discount?.coupon?.name
                            }
                          </div>
                          <div className=" mt-2 text-darkGrey">
                            {company?.subscriptions?.metadata_subscription
                              ?.discount?.start
                              ? dayjs
                                  .unix(
                                    company?.subscriptions
                                      ?.metadata_subscription?.discount?.start
                                  )
                                  .format("DD MMM YYYY")
                              : ""}
                          </div>
                        </>
                      ) : (
                        <Select
                          className=" w-full"
                          placeholder="Select"
                          loading={isLoadingCoupon}
                          onSelect={(value) =>
                            actions.handleUpdate({
                              stripe_subscription_id:
                                company?.subscriptions?.stripe_subscription_id,
                              stripe_coupon_id: value,
                            })
                          }
                          options={dataCoupon?.data?.map((item) => ({
                            label: item.name,
                            value: item?.id,
                          }))}
                        />
                      )
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <Divider className=" my-3 border-1 border-backdrop" />
              </div>
            ))
          ) : (
            <div>No company available</div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default SubscriptionsList;

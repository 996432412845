import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAddTrainer } from "../hooks/useTrainers";
import { useState } from "react";
import { message } from "antd";
import { useGreenSkillForAdmin } from "./useSkills";
import dayjs from "dayjs";
import { useQueryClient } from "react-query";

const schema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  display_name: yup.string().required("Display name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  sharing_profit: yup
    .number()
    .required("School's share in the profit is required")
    .positive("School's share must be a positive number")
    .test(
      "is-between-1-100",
      "School's share must be between 1 and 100",
      (value) => {
        return value >= 1 && value <= 100;
      }
    ),
  contract_expired_at: yup.date().required("Contract expiry date is required"),
});

export const useAddTrainerDrawer = (onClose) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const addTrainer = useAddTrainer();
  // const [searchValueGS, setSearchValueGS] = useState("");
  // const [optionsGS, setOptionsGS] = useState([]);
  const [greenSkillData, setGreenSkillData] = useState([]);
  const queryClient = useQueryClient();

  // const {
  //   data: greenSkills,
  //   isFetchingNextPage: greenSkillLoading,
  //   fetchNextPage: fetchNextPageGS,
  // } = useGreenSkill(searchValueGS);

  const {
    params,
    handleClear,
    // handleOwnesSkill,
    dataList: optionsGS,
    handleSearch: handleSearchGS,
    isLoading: greenSkillLoading,
    handleScroll: handleScrollGS,
  } = useGreenSkillForAdmin();

  // const handleScrollGS = (e) => {
  //   const { target } = e;
  //   const maxScrollPosition =
  //     target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;

  //   if (target.scrollTop >= maxScrollPosition && !greenSkillLoading) {
  //     fetchNextPageGS();
  //   }
  // };
  // useEffect(() => {
  //   if (greenSkills?.pages) {
  //     setOptionsGS(() =>
  //       greenSkills.pages.flatMap((page) => page.data.data.list)
  //     );
  //   }
  // }, [greenSkills?.pages]);

  const onSelectGS = (value) => {
    if (optionsGS.length !== 0) {
      //   const selectedItem = optionsGS?.find((item) => item.name === value);

      //   const formattedData = {
      //     id: selectedItem?.id,
      //     name: selectedItem?.name,
      //   };
      //   // check if green skill data already has the selected item
      const isAlreadySelected = greenSkillData?.find(
        (item) => item.value === value.value
      );
      if (isAlreadySelected) {
        message.info({ content: "already selected" });
        return;
      }

      setGreenSkillData((prevSelectedItems) => [...prevSelectedItems, value]);
    }

    // setOptionsGS(optionsGS?.filter((opt) => opt.name !== formattedData.name));
    // setSearchValueGS("");
  };

  // const handleSearchGS = (value) => {
  //   setSearchValueGS(value);
  // };
  const handleDeleteGreenSkill = (id) => {
    const updatedData = greenSkillData.filter((item) => item.value !== id);
    setGreenSkillData(updatedData);
  };

  const onSaveSubmit = async (data) => {
    // if greenskilldata is empty array do message.error
    if (greenSkillData.length === 0) {
      message.error("Please select at least one green skill");
      return;
    }
    const green_skill_ids = greenSkillData.map((item) => item.value);
    try {
      message.open({
        key: "invite_admin",
        type: "loading",
        content: "loading...",
      });

      await addTrainer.mutateAsync({
        ...data,
        green_skill_ids,
        contract_expired_at: dayjs(data.contract_expired_at).unix(),
      });
      queryClient.invalidateQueries(["green-skills-list-add"]);
      reset();
      onClose();
    } catch (error) {
      return error;
    } // message.open({type:"loading", content:"inviting trainer admin"})
  };

  return {
    handleSubmit,
    control,
    errors,
    onSaveSubmit,
    handleScrollGS,
    onSelectGS,
    handleSearchGS,
    optionsGS,
    params,
    handleClear,
    greenSkillData,
    handleDeleteGreenSkill,
    greenSkillLoading,
  };
};

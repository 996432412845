import React from "react";
import { usePerkDetail, useAddPerk } from "../hooks/usePerks";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { UploadWithFocus } from "../../../common/components/UploadWithFocus";
import { beforeUpload } from "../utils/imageUploadFuncs";
import {
  PlusOutlined,
  LinkOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Input, Switch, DatePicker, Select, Button, Spin } from "antd";
import { RichTextV2 } from "../../../common/components/RichTextV2";
import dayjs from "dayjs";
import { usePerkUpdate } from "../hooks/usePerks";

/**
 * type Perk = {
    id: string;
    app: string;
    company_name: string;
    company_logo: string;
    offer_title: string;
    offer_desc: string;
    offer_link: string;
    visible: boolean;
    expire_at: string;
    created_at: string;
    updated_at: string | null;
};

 */

export const PerkDetail = ({ type }) => {
  const isUpdate = type !== "create";
  const [hasExpiration, setHasExpiration] = React.useState(false);
  const { id } = useParams();
  const perkDetailQuery = usePerkDetail(id, isUpdate && id !== undefined);
  const updatePerkMutation = usePerkUpdate();
  const addPerkMutation = useAddPerk();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  React.useEffect(() => {
    if (perkDetailQuery.data?.data?.data && id) {
      reset({
        company_name: perkDetailQuery.data?.data?.data?.company_name,
        offer_title: perkDetailQuery.data?.data?.data?.offer_title,
        offer_desc: perkDetailQuery.data?.data?.data?.offer_desc,
        offer_link: perkDetailQuery.data?.data?.data?.offer_link,
        app: perkDetailQuery.data?.data?.data?.app,
        expire_at: perkDetailQuery.data?.data?.data?.expire_at
          ? dayjs(perkDetailQuery.data?.data?.data?.expire_at)
          : dayjs(),
        company_image: {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: perkDetailQuery.data?.data?.data?.company_logo,
        },
      });
      if (perkDetailQuery.data?.data?.data?.expire_at) {
        setHasExpiration(true);
      } else {
        setHasExpiration(false);
      }
    }
    return () => {
      reset();
    };
  }, [perkDetailQuery.data?.data?.data, reset, id]);

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess((field) => field.onChange(file));
    }, 0);
  };

  const onSaveSubmit = async (data) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      console.log("Printing the key and value", key, value);
      if (value === undefined || value === null) continue;
      if (key === "company_image") {
        if (value?.uid === "-1") {
          formData.append(key, null);
          continue;
        } else {
          formData.append("file", value);
          continue;
        }
      } else if (key === "expire_at") {
        if (setHasExpiration) {
          const expire_at = dayjs(value).unix();
          formData.append(key, expire_at);
        }
        continue;
      } else {
        formData.append(key, value);
      }
    }

    if (isUpdate) {
      await updatePerkMutation.mutateAsync({
        id,
        data: formData,
      });
      navigate(-1);
    } else {
      await addPerkMutation.mutateAsync(formData);
      navigate(-1);
    }
  };
  const navigate = useNavigate();
  return (
    <Spin
      spinning={
        perkDetailQuery.isLoading ||
        perkDetailQuery.isFetching ||
        updatePerkMutation.isLoading ||
        addPerkMutation.isLoading
      }
    >
      <div className="flex items-center gap-5">
        <ArrowLeftOutlined
          onClick={() => {
            navigate(-1);
            reset({
              company_name: "",
              offer_title: "",
              offer_desc: "",
              offer_link: "",
              app: "",
              expire_at: 0,
              company_image: null,
            });
          }}
        />{" "}
        <div className="text-xl font-bold ">Perk Detail</div>
      </div>
      <form
        onSubmit={handleSubmit(onSaveSubmit)}
        className="grid md:grid-cols-2 gap-5 p-4"
      >
        <div className="basic-card bg-white-custom flex flex-col gap-4">
          <div className="flex items-center justify-center gap-5">
            <div className="w-[102px] h-full">
              <Controller
                name="company_image"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <UploadWithFocus
                    {...field}
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                    }}
                    beforeUpload={(file) => {
                      beforeUpload(file).then((filez) => {
                        if (filez) {
                          field.onChange(filez);
                        }
                      });
                    }}
                    onChange={async (file) => {
                      field.onChange();
                    }}
                    fileList={field.value ? [field.value] : []}
                    onRemove={() => field.onChange(null)}
                    maxCount={1}
                    customRequest={customRequest}
                    className="h-full"
                    listType="picture-card"
                  >
                    <div>
                      {<PlusOutlined />}
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Upload
                      </div>
                    </div>
                  </UploadWithFocus>
                )}
              />
              {errors.image && (
                <p className="text-danger w-full animate-pulse">
                  {errors.image.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center-justify-start gap-2">
            <Controller
              control={control}
              name="company_name"
              rules={{
                required: "Company Name is required",
                minLength: {
                  value: 2,
                  message: "Company Name must be at least 5 characters",
                },
                maxLength: {
                  value: 150,
                  message: "Company Name must be at most 150 characters",
                },
              }}
              render={({ field }) => (
                <Input placeholder="Company Name" {...field} />
              )}
            />
            {errors.company_name && (
              <p className="text-red-500">{errors.company_name.message}</p>
            )}
          </div>
          <div className="flex flex-col items-center-justify-start gap-2">
            <Controller
              control={control}
              name="offer_title"
              rules={{
                required: "Offer Title is required",
                minLength: {
                  value: 2,
                  message: "Offer Title must be at least 5 characters",
                },
                maxLength: {
                  value: 150,
                  message: "Offer Title must be at most 150 characters",
                },
              }}
              render={({ field }) => (
                <Input placeholder="Offer Title" {...field} />
              )}
            />
            {errors.offer_title && (
              <p className="text-red-500">{errors.offer_title.message}</p>
            )}
          </div>
          <div className="flex items-center gap-6">
            <div className="font-bold">Expiration</div>
            <Switch
              checked={hasExpiration}
              onChange={(checked) => {
                if (!checked) {
                  setValue("expire_at", null);
                }
                if (checked) {
                  setValue(
                    "expire_at",
                    perkDetailQuery.data?.data?.data?.expire_at
                      ? dayjs(perkDetailQuery.data?.data?.data?.expire_at)
                      : dayjs()
                  );
                }
                setHasExpiration(checked);
              }}
            />
            {hasExpiration && (
              // <Controller
              //   control={control}
              //   // defaultValue={0}
              //   name="expire_at"
              //   render={({ field }) => (
              //     <DatePicker {...field} placeholder="Expiration Date" />
              //   )}
              // />
              <Controller
                control={control}
                name="expire_at"
                render={({ field }) => {
                  return (
                    <DatePicker
                      allowClear={false}
                      placeholder="Expiration Date"
                      {...field}
                      value={field.value}
                      format="DD MMM YYYY"
                    />
                  );
                }}
              />
            )}
          </div>
          <div className="flex flex-col items-center-justify-start gap-2">
            <Controller
              control={control}
              name="offer_link"
              rules={{
                required: "Link to redeem is required",
              }}
              render={({ field }) => (
                <Input
                  prefix={<LinkOutlined className="" />}
                  placeholder="Link to redeem"
                  {...field}
                />
              )}
            />
            {errors.offer_link && (
              <p className="text-red-500">{errors.offer_link.message}</p>
            )}
          </div>
          <div className="flex flex-col justify-start gap-2 w-full">
            <Controller
              control={control}
              className="w-full"
              name="app"
              defaultValue="recruiter"
              rules={{
                required: "App is required",
              }}
              render={({ field }) => (
                <Select
                  placeholder="Select App"
                  {...field}
                  allowClear
                  options={[
                    { label: "Recruiter", value: "recruiter" },
                    { label: "Talent", value: "talent" },
                    {
                      label: "Trainer",
                      value: "trainer",
                    },
                  ]}
                  onChange={(value) => field.onChange(value)}
                ></Select>
              )}
            />
            {errors.app && <p className="text-red-500">{errors.app.message}</p>}
          </div>
        </div>
        <div className="basic-card bg-white-custom">
          <div className="font-bold text-lg">Offer Details</div>
          <Controller
            control={control}
            name="offer_desc"
            rules={{
              required: "Offer Description is required",
              minLength: {
                value: 2,
                message: "Offer Description must be at least 5 characters",
              },
            }}
            render={({ field }) => (
              <RichTextV2
                className={` mt-4 font-default h-[60%] rounded-md !bg-white-custom font-normal`}
                placeholder={"Start typing here..."}
                {...field}
              />
            )}
          />
          {errors.offer_desc && (
            <p className="text-red-500">{errors.offer_desc.message}</p>
          )}
        </div>
        <div className="col-span-full flex justify-end w-full mt-">
          <Button
            loading={updatePerkMutation.isLoading || addPerkMutation.isLoading}
            disabled={updatePerkMutation.isLoading || addPerkMutation.isLoading}
            type="primary"
            className="bg-primary "
            htmlType="submit"
          >
            {updatePerkMutation.isLoading || addPerkMutation.isLoading
              ? "Saving..."
              : isUpdate
              ? "Save"
              : "Create"}
          </Button>
        </div>
      </form>
    </Spin>
  );
};

import { NavLink } from 'react-router-dom'

export const renderNavLink = (to, children) => (
  <NavLink
    className={({ isActive }) =>
      isActive
        ? 'rounded-full py-1 bg-backdrop px-8 text-white leading-none max-h-8 hover:text-primary'
        : 'rounded-full py-1 bg-white px-8 text-backdrop leading-none max-h-8 hover:text-primary'
    }
    to={to}
  >
    {children}
  </NavLink>
)

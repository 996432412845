import React from "react";
import {
  FilePdfOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import dayjs from "dayjs";

export const CardVerificationSkill = ({ data, action, loadingAll }) => {
  return (
    <div className="my-5 shadow-md rounded-lg p-5 bg-white flex justify-between gap-5">
      <div className=" w-[calc(100%-16.25rem)]">
        <h3 className="text-lg font-semibold m-0 p-0 my-3">
          {data?.green_skill?.name || "Unknown skill"}
        </h3>
        <a
          href={data?.document_link}
          target="_blank"
          rel="noreferrer"
          className="my-3 text-backdrop hover:text-backdrop"
        >
          <FilePdfOutlined className="" />{" "}
          <span className="">{data?.document_name || "unknown"}</span>
        </a>
        <div
          className="my-3"
          dangerouslySetInnerHTML={{
            __html: data?.description || "No description",
          }}
        />
      </div>
      <div className=" w-60">
        <div>{`submitted: ${dayjs(data?.submitted_at).format(
          "DD MMM YYYY"
        )}`}</div>
        {data?.status === "ON_REVIEW" ? (
          <div className=" flex gap-3 my-3">
            <Button
              type="primary"
              size="small"
              onClick={() => {
                if (
                  !action?.isLoadingReject &&
                  !action?.isLoadingAccept &&
                  !loadingAll
                )
                  action?.handleAccept(data?.id);
              }}
            >
              <CheckOutlined />
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                if (
                  !action?.isLoadingReject &&
                  !action?.isLoadingAccept &&
                  !loadingAll
                )
                  action?.handleReject(data?.id);
              }}
            >
              <CloseOutlined />
            </Button>
          </div>
        ) : data?.status === "REJECTED" ? (
          <div className=" my-3">{`rejected: ${dayjs(data?.verified_at).format(
            "DD MMM YYYY"
          )}`}</div>
        ) : (
          <div className=" my-3">{`verified: ${dayjs(data?.verified_at).format(
            "DD MMM YYYY"
          )}`}</div>
        )}
        <div>{`${data?.talent?.first_name} ${data?.talent?.last_name}`}</div>
        <div className="mt-3">{data?.talent?.email}</div>
      </div>
    </div>
  );
};

import React from "react";
import { Layout, Menu, Image } from "antd";
import { useMatch, useLocation } from "react-router-dom";
import { RightCircleOutlined } from "@ant-design/icons";
import { sidebarParentItems, items } from "../../utils/sidebarItem";
import GreenSkillsLogoLoggedIn from "../../assets/images/skilledin_.png";
import { useApplicationStore } from "../../store/applicationStore";

const { Sider } = Layout;

export default function CustomSiderbar() {
  const { openSidebar, handleOpenSidebar } = useApplicationStore();
  const location = useLocation();
  const isGreenskills = useMatch(
    `${sidebarParentItems.greenSkills}/*`,
    location
  );
  const isJobsCompanies = useMatch(
    `${sidebarParentItems.jobsCompanies}/*`,
    location
  );
  const isTalent = useMatch(`${sidebarParentItems.talent}/*`, location);
  const isMarketing = useMatch(`${sidebarParentItems.marketing}/*`, location);
  const isDataPrivacy = useMatch(
    `${sidebarParentItems.dataPrivacy}/*`,
    location
  );

  const getSelectedKeys = () => {
    if (isGreenskills) {
      return [sidebarParentItems.greenSkills];
    }
    if (isJobsCompanies) {
      return [sidebarParentItems.jobsCompanies];
    }
    if (isTalent) {
      return [sidebarParentItems.talent];
    }
    if (isMarketing) {
      return [sidebarParentItems.marketing];
    }
    if (isDataPrivacy) {
      return [sidebarParentItems.dataPrivacy];
    }
    return [];
  };

  return (
    <div className="h-full  bg-backdrop">
      <Sider
        width={openSidebar ? 210 : 70}
        style={{
          width: openSidebar ? 210 : 70,
          backgroundColor: "#36505E",
        }}
        collapsed={!openSidebar}
        collapsible
        onCollapse={() => handleOpenSidebar(!openSidebar)}
        trigger={null}
        className={`shadow h-screen overflow-y-auto overflow-x-hidden  absolute ${
          openSidebar ? "" : "!w-[70px] !max-w-[70px] !min-w-[70px]"
        }`}>
        <div
          onClick={() => handleOpenSidebar(!openSidebar)}
          className={` flex text-white mt-4 w-full transition-transform duration-500 ease-in-out ${
            openSidebar ? "justify-end" : "justify-center"
          } `}>
          <RightCircleOutlined
            className={`text-xl cursor-pointer ${
              openSidebar ? "mr-4 rotate-180" : ""
            }`}
          />
        </div>
        <div className={` `}>
          <div
            className={`flex items-center text-primary  mt-3 ${
              openSidebar ? "rounded-xl flex-col" : ""
            }  border-primary border-[0.5px]   ${
              openSidebar ? "" : "justify-center"
            }`}>
            <Image
              className=""
              src={GreenSkillsLogoLoggedIn}
              preview={false}
              width={openSidebar ? 60 : 50}
            />
          </div>
        </div>

        <Menu
          mode="inline"
          className=" !bg-backdrop  h-full !text-white mt-6"
          selectedKeys={getSelectedKeys()}
          style={{
            height: "70%",
            borderRight: 0,
            maxWidth: "100%",
          }}
          items={items}
        />
      </Sider>
    </div>
  );
}

import React, { useState } from "react";
import { Drawer, Input, Select, Button, Tag, Spin } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEditSkillGroup, useSingleSkillGroup } from "../hooks/useSkillGroup";
const schema = yup.object().shape({
  name: yup.string().required("Skill Group Name is required"),
});
export function EditSkillGroup({ onClose, open, skillGroup, otherGroupId }) {
  const skillGroupQuery = useSingleSkillGroup(skillGroup?.id);
  const otherGroupQuery = useSingleSkillGroup(otherGroupId);
  const editSkillGroup = useEditSkillGroup();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [unselectedSkills, setUnselectedSkills] = useState([]);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: skillGroup?.name,
      skills_id: [],
    },
  });
  React.useEffect(() => {
    setSelectedSkills(skillGroupQuery.data?.data?.data?.skills ?? []);
  }, [skillGroupQuery.data]);

  React.useEffect(() => {
    setUnselectedSkills(otherGroupQuery.data?.data?.data?.skills ?? []);
  }, [otherGroupQuery.data]);

  const handleSkillSelect = (option) => {
    setSelectedSkills((prevSkills) => [...prevSkills, option]);
  };
  const handleRemoveSkill = (skillToRemove) => {
    setSelectedSkills((prevSkills) =>
      prevSkills.filter((skill) => skill.id !== skillToRemove.id)
    );
    setUnselectedSkills((prevSkills) => [...prevSkills, { ...skillToRemove }]);
  };

  const onSaveSubmit = async (data) => {
    console.log({ ...data, skills_id: selectedSkills });
    editSkillGroup.mutate({
      ...data,
      skills_id: selectedSkills.map((group) => group?.id),
      id: skillGroup?.id,
    });
    onClose();
  };
  return (
    <Drawer
      closable={false}
      placement='right'
      onClose={onClose}
      size='large'
      open={open}>
      <p className='font-bold text-xl text-backdrop mb-5'>Edit Skill Group</p>
      <form onSubmit={handleSubmit(onSaveSubmit)}>
        <div className='mb-5 space-y-6'>
          <div className='flex flex-col items-center-justify-start gap-2'>
            <Controller
              control={control}
              name='name'
              render={({ field }) => (
                <Input
                  placeholder='Skill Group Name'
                  {...field}
                />
              )}
            />
            {errors.name && (
              <p className='text-red-500'>{errors.name.message}</p>
            )}
          </div>
          <div className='flex items-center gap-4'>
            <div className='flex items-center gap-4'>
              <div className='flex flex-col items-center-justify-start gap-2 w-full'>
                {unselectedSkills.length > 0 && (
                  <Controller
                    control={control}
                    className='w-full'
                    name='skills_id'
                    render={({ field }) => (
                      <Select
                        {...field}
                        className='w-full'
                        mode='multiple'
                        showSearch
                        filterOption={(input, option) =>
                          option.name
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        fieldNames={{ label: "name", value: "id" }}
                        options={unselectedSkills.map((skill) => ({
                          id: skill?.id,
                          name: skill?.name,
                        }))}
                        placeholder='Select Skills'
                        loading={otherGroupQuery?.isLoading}
                        notFoundContent={
                          otherGroupQuery?.isError
                            ? "Error fetching skills"
                            : "No skills found"
                        }
                        onSelect={(value, option) =>
                          handleSkillSelect(option)
                        }></Select>
                    )}
                  />
                )}
                <Spin spinning={skillGroupQuery.isLoading}>
                  <div className='mt-2  flex justify-start gap-2 flex-wrap'>
                    {selectedSkills.map((skill) => (
                      <Tag
                        key={skill.id}
                        className='bg-[#D4F5C5]'
                        closable
                        onClose={() => handleRemoveSkill(skill)}>
                        {skill.name}
                      </Tag>
                    ))}
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-end gap-3'>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            loading={editSkillGroup.isLoading}
            type='primary'
            className='bg-primary'
            htmlType='submit'>
            Save
          </Button>
        </div>
      </form>
    </Drawer>
  );
}

import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { message } from "antd";
import httpRequest from "../../../config/config";

const addJobSector = (data) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (key === "image") {
      if (value?.uid === "-1") {
        formData.append(key, null);
        continue;
      } else {
        formData.append("file", value);
        continue;
      }
    }
    formData.append(key, value);
  }
  return httpRequest.post("/skilledin/job-sectors", formData);
};

const fetchJobSectors = (params) => {
  return httpRequest.get("/skilledin/job-sectors", {
    params,
  });
};
const fetchJobSectorslnfinity = ({ pageParam = 1, search }) => {
  return httpRequest.get("/skilledin/job-sectors", {
    params: {
      page: pageParam,
      limit: 10,
      name: search,
    },
  });
};

const editJobs = (data) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (key === "image") {
      if (value?.uid === "-1") {
        formData.append(key, null);
        continue;
      } else {
        formData.append("file", value);
        continue;
      }
    }
    formData.append(key, value);
  }
  return httpRequest.patch(`/skilledin/job-sectors/${data.slug}`, formData);
};
const deleteJob = (slug) => {
  return httpRequest.delete(`/skilledin/job-sectors/${slug}`);
};

// EXEC

const useJobSectors = (params = {}) => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching jobs: ${error}`);
  };

  return useQuery(
    ["admin-job-sectors", params],
    () => fetchJobSectors(params),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    }
  );
};

const useJobSectorsInfinity = (searchParam) => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching job-sectors: ${error}`);
  };

  return useInfiniteQuery(
    ["job-sectors-infinity", searchParam],
    ({ pageParam }) =>
      fetchJobSectorslnfinity({ pageParam, search: searchParam }),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.data?.pagination?.page <
        lastPage?.data?.data?.pagination?.total_page
          ? lastPage?.data?.data?.pagination?.page + 1
          : undefined,
    }
  );
};

const useAddJobSector = () => {
  const queryClient = useQueryClient();

  const handleAddSuccess = () => {
    message.success("Job successfully added");
  };

  const handleAddError = (error) => {
    message.error(`An error occurred while adding a job: ${error}`);
  };

  return useMutation((jobData) => addJobSector(jobData), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["admin-job-sectors"]);
      queryClient.invalidateQueries(["admin-jobs"]);
      handleAddSuccess();
    },
    onError: handleAddError,
  });
};

const useEditJobSector = () => {
  const queryClient = useQueryClient();

  const handleEditSuccess = () => {
    message.success("Job successfully edited");
  };

  const handleEditError = (error) => {
    message.error(`An error occurred while editing a job: ${error}`);
  };

  return useMutation((data) => editJobs(data), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["admin-job-sectors"]);
      queryClient.invalidateQueries(["admin-jobs"]);

      handleEditSuccess();
    },
    onError: handleEditError,
  });
};

const useDeleteJobSector = () => {
  const queryClient = useQueryClient();

  const handleDeleteSuccess = () => {
    message.success("Job successfully deleted");
  };

  const handleDeleteError = (error) => {
    message.error(`An error occurred while deleting a job: ${error}`);
  };

  return useMutation((data) => deleteJob(data), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["admin-job-sectors"]);
      queryClient.invalidateQueries(["admin-jobs"]);

      handleDeleteSuccess();
    },
    onError: handleDeleteError,
  });
};

export {
  useJobSectors,
  useJobSectorsInfinity,
  useAddJobSector,
  useEditJobSector,
  useDeleteJobSector,
};

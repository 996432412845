import { useState } from "react";
import { debounce } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { message } from "antd";
import httpRequest from "../../../config/config";

const addTrainer = (data) => {
  // console.log(data);
  return httpRequest.post("skilledin/school-admins", data);
};

const fetchTrainers = (params) => {
  return httpRequest.get("skilledin/school-admins", { params });
};

const fetchSchools = (params) => {
  return httpRequest.get("references/schools", { params });
};

// get single trainer by id
const fetchTrainer = (id) => {
  return httpRequest.get(`skilledin/school-admins/${id}`);
};

const pauseSchool = (id) => {
  return httpRequest.patch(`skilledin/school-admins/${id}/pause`);
};

const editTrainer = (data) => {
  return httpRequest.patch(`skilledin/school-admins/${data.id}`, data.data);
};

const useTrainers = () => {
  const [params, setParams] = useState({ name: "" });
  const handleSearch = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, name: e.target.value }));
  }, 500);
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching trainers: ${error}`);
  };

  const data = useQuery(["trainers", params], () => fetchTrainers(params), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
  return { data, params, handleSearch };
};

const useSchool = (params = {}) => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching schools list: ${error}`);
  };

  return useQuery(["school-list", params], () => fetchSchools(params), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
};

const useAddTrainer = () => {
  const queryClient = useQueryClient();

  const handleAddSuccess = () => {
    message.open({
      key: "invite_admin",
      type: "success",
      content: "Trainer successfully added",
    });
  };

  const handleAddError = (error) => {
    message.open({
      key: "invite_admin",
      type: "error",
      content: `An error occurred while adding a trainer: ${error}`,
    });
  };

  return useMutation((trainerData) => addTrainer(trainerData), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["trainers"]);
      handleAddSuccess();
    },
    onError: handleAddError,
  });
};

const useEditTrainer = () => {
  const queryClient = useQueryClient();

  const handleEditSuccess = () => {
    message.open({
      key: "edit_admin",
      type: "success",
      content: "Trainer successfully edited",
    });
  };

  const handleEditError = (error) => {
    message.open({
      key: "edit_admin",
      type: "error",
      content: `An error occurred while editing a trainer: ${error}`,
    });
  };

  return useMutation((data) => editTrainer(data), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["trainers"]);
      handleEditSuccess();
    },
    onError: handleEditError,
  });
};

const usePauseSchool = () => {
  const queryClient = useQueryClient();

  const handlePauseSuccess = () => {
    message.success("School successfully paused");
  };

  const handlePauseError = (error) => {
    message.error(`An error occurred while pausing a school: ${error}`);
  };

  return useMutation((id) => pauseSchool(id), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["trainers"]);
      handlePauseSuccess();
    },
    onError: handlePauseError,
  });
};

const useFetchTrainer = (id) => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching trainer: ${error}`);
  };

  return useQuery(["trainer", id], () => fetchTrainer(id), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
};

export {
  useTrainers,
  useAddTrainer,
  useEditTrainer,
  usePauseSchool,
  useFetchTrainer,
  useSchool,
};

import React from "react";
import { Controller } from "react-hook-form";
import { Button, Input, Spin } from "antd";
import ReactQuill from "react-quill";
import { formats, modules } from "../../../common/components/RichText";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export const Policies = ({
  fieldArray,
  control,
  handleSubmit,
  errors,
  isLoading,
  data,
  action,
}) => {
  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
      <div className=" flex gap-5">
        <div className=" w-[calc(100%-236px-1.25rem)]">
          {fieldArray.fields.map((item, index) => (
            <div
              className=" bg-[#FAFAFA] p-5 rounded-md shadow-md my-5"
              key={item.id}
            >
              <div className=" flex justify-between items-center gap-5 mb-3">
                <>
                  <Controller
                    name={`policy.${index}.title`}
                    control={control}
                    rules={{ required: "*this field is required" }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        id="name"
                        placeholder="section tittle"
                        className=" rounded-sm"
                      />
                    )}
                  />
                </>
                {fieldArray.fields.length > 1 && (
                  <DeleteOutlined onClick={() => fieldArray.remove(index)} />
                )}
              </div>
              {errors?.policy?.[index]?.title && (
                <small className=" text-danger">
                  {errors?.policy?.[index]?.title?.message}
                </small>
              )}
              <div>
                <Controller
                  control={control}
                  name={`policy.${index}.body`}
                  rules={{ required: "*this field is required" }}
                  render={({ field }) => (
                    <ReactQuill
                      {...field}
                      className={`h-[140px] pb-[2.43rem] bg-white my-5 font-default font-normal rounded-md`}
                      placeholder={
                        "Type content here. You can use the toolbar to format the text."
                      }
                      formats={formats}
                      modules={modules}
                    />
                  )}
                />
                {errors?.policy?.[index]?.body && (
                  <small className=" text-danger">
                    {errors?.policy?.[index]?.body?.message}
                  </small>
                )}
              </div>
            </div>
          ))}
          <div className=" bg-secondaryBlue p-3 rounded-md text-right">
            <Button
              type="primary"
              onClick={() => fieldArray.append({ title: "", body: "" })}
            >
              Add Section
            </Button>
          </div>
        </div>
        <div className=" w-[236px]">
          <div className="sticky top-3 bg-[#FAFAFA] p-5 rounded-md shadow-md my-5">
            <Button
              type="primary"
              className="w-full"
              onClick={handleSubmit(action.handleUpdate)}
              disabled={isLoading || action.isLoading}
            >
              Update
            </Button>
            {data?.updated_at && (
              <div className=" text-center  mt-3">{`Last updated on ${dayjs(
                data?.updated_at
              ).format("DD MMM YYYY")}`}</div>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

import React from "react";
import { Tabs } from "antd";
import { BitmovinPlayer } from "./BitmovinPlayer";

type Video = {
  dash: string;
  status: "FINISHED" | string;
};

interface LessonCardProps {
  name: string;
  existingData?: string | null;
  newData?: string | null;
  newVideo?: Video | null;
  oldVideo?: Video | null;
}

export const LessonCard: React.FC<LessonCardProps> = ({
  name,
  existingData = "--none--",
  newData = "--none--",
  newVideo = null,
  oldVideo = null,
}) => {
  return (
    <div className='my-5'>
      <p className='mb-3 capitalize'>{name}</p>
      <div className='w-full'>
        <Tabs
          type='card'
          destroyInactiveTabPane={false}
          tabBarStyle={{
            letterSpacing: "0.02em",
            height: "40px",
          }}
          items={[
            {
              label: `Text`,
              key: "text",
              children: (
                <>
                  <div className='grid grid-cols-2 gap-5 mt-4'>
                    <div
                      className='border min-h-[80px] rounded-sm p-2'
                      dangerouslySetInnerHTML={{
                        __html: existingData ?? "",
                      }}></div>
                    <div
                      className='border min-h-[80px] rounded-sm p-2'
                      dangerouslySetInnerHTML={{ __html: newData ?? "" }}></div>
                  </div>
                </>
              ),
            },
            {
              label: `Video`,
              key: "video",
              children: (
                <>
                  {/* <div className='my-2 mt-4 text-xs text-gray-500'>
                    Notes:
                    <ul className='list-disc list-inside'>
                      {videoNameHasChanged(oldVideo?.dash, newVideo?.dash) && (
                        <li>
                          The name of the video has changed indicating an
                          update. The content might not be updated but the video
                          sure is.{" "}
                        </li>
                      )}
                    </ul>
                  </div> */}
                  <div className='grid grid-cols-2 gap-5 mt-4'>
                    <div className='border min-h-[80px] rounded-sm p-2'>
                      <BitmovinPlayer
                        dash={newVideo?.dash}
                        hls={undefined}
                        poster={undefined}
                      />
                    </div>
                    <div className='border min-h-[80px] rounded-sm p-2'>
                      <BitmovinPlayer
                        dash={oldVideo?.dash}
                        hls={undefined}
                        poster={undefined}
                      />
                    </div>
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

import React from "react";
import { useApplicationStore } from "../../../store/applicationStore";
import { CardVerificationSkill } from "../components/CardVerificationSkill";
import { useSkillVerification } from "../hooks/useSkillVerification";
import { Pagination, Select } from "antd";

export const VerifiedSkill = () => {
  const { setLinks } = useApplicationStore();
  const { data, params, action } = useSkillVerification();

  const STATUS = ["ON_REVIEW", "APPROVED", "REJECTED"];

  React.useEffect(() => {
    setLinks([]);
  }, [setLinks]);
  return (
    <div>
      <div className=" flex justify-between">
        <h1 className="m-0 text-2xl font-semibold">Skill Verification</h1>
        <Select
          className=" w-40"
          allowClear
          placeholder="Select status"
          options={STATUS.map((item) => ({
            label: item.replace("_", " "),
            value: item,
          }))}
          onChange={(value) =>
            params.setParams((prev) => ({ ...prev, status: value, page: 1 }))
          }
        />
      </div>
      <div className="">
        {data?.data?.list?.length > 0 ? (
          data?.data?.list?.map((item, i) => (
            <CardVerificationSkill
              data={item}
              key={i}
              action={action}
              loadingAll={data?.isLoading}
            />
          ))
        ) : (
          <div className=" mt-5">
            {data?.isLoading ? "Loading..." : "No data available"}
          </div>
        )}
      </div>
      <div className="text-right mt-4">
        <Pagination
          current={data?.data?.pagination?.page || 1}
          showSizeChanger={false}
          hideOnSinglePage={true}
          onChange={(v) => params.setParams((prev) => ({ ...prev, page: v }))}
          total={data?.data?.total_data || 0}
          defaultPageSize={10}
        />
      </div>
    </div>
  );
};

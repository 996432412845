import React from "react";
import { useApplicationStore } from "../../../store/applicationStore";
import { Button, Divider, Drawer, InputNumber, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useCurrencies } from "../hooks/useCurrencies";
import { Controller } from "react-hook-form";
import { useCurrenciesReferences } from "../hooks/useCurrencyReferences";
import { CurrencyRow } from "./CurrencyRow";

export const ListCurrencies = () => {
  const { setLinks } = useApplicationStore();
  React.useEffect(() => {
    setLinks([]);
  }, [setLinks]);

  const {
    data,
    isLoading,
    form,
    modal,
    setModal,
    handleCreate,
    isLoadingCreate,
    handleRemove,
    isLoadingRemove,
    handleUpdate,
    isLoadingUpdate,
    handleAvailability,
    isLoadingAvailability,
    handleTaxAvailability,
    isLoadingTaxAvailability,
    handleuploadLogo,
  } = useCurrencies();
  const {
    data: dataRef,
    isLoading: isLoadingRef,
    handleScroll,
    handleSearch,
  } = useCurrenciesReferences();

  return (
    <div>
      <div className=" flex justify-between items-center mb-5">
        <h1 className="m-0 text-2xl font-semibold">Currencies</h1>
        <Button
          className=""
          type="primary"
          onClick={() => setModal({ isOpen: true, data: undefined })}
        >
          New Currencies
        </Button>
      </div>
      <Spin spinning={isLoading} indicator={<LoadingOutlined />}>
        <div className=" bg-white rounded-xl shadow-md p-5">
          <div className=" grid grid-cols-6 px-2 gap-5 text-darkGrey">
            <div className=" col-span-1">Logo</div>
            <div className=" col-span-1">Code</div>
            <div className=" col-span-1">Name</div>
            <div className=" col-span-1">Exchange Rate</div>
            <div className=" col-span-1">Tax</div>
            <div className=" col-span-1">Action</div>
          </div>
          <Divider className=" my-3 border-1 border-darkGrey" />
          {data?.length > 0 ? (
            data?.map((currency, i) => (
              <CurrencyRow
                key={i}
                currency={currency}
                setModal={setModal}
                handleRemove={handleRemove}
                isLoadingRemove={isLoadingRemove}
                handleAvailability={handleAvailability}
                isLoadingAvailability={isLoadingAvailability}
                handleTaxAvailability={handleTaxAvailability}
                isLoadingTaxAvailability={isLoadingTaxAvailability}
                handleuploadLogo={handleuploadLogo}
              />
            ))
          ) : (
            <div>No currency available</div>
          )}
        </div>
      </Spin>

      <Drawer
        open={modal.isOpen}
        title={!modal.data ? "Create new currency" : "Update currency"}
        onClose={() => setModal({ isOpen: false, data: undefined })}
      >
        <div className="my-3 flex flex-col">
          <label htmlFor="code">Currency code</label>
          <Controller
            control={form.control}
            name="code"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                id="code"
                disabled={modal.data}
                className=" w-full mt-2"
                placeholder="e.g SGD"
                filterOption={false}
                options={dataRef?.map((item) => ({
                  label: item.code,
                  value: item.name,
                }))}
                onPopupScroll={handleScroll}
                onSearch={handleSearch}
                showSearch
                onSelect={(_, b) => {
                  form.setValue("code", b);
                  handleSearch("");
                }}
                loading={isLoadingRef}
                {...field}
              />
            )}
          />
          {form.errors.code && (
            <p className="text-red-500">Currency field is required</p>
          )}
        </div>
        <div className="my-3">
          <label htmlFor="exchange">Exchange rate</label>
          <Controller
            control={form.control}
            name="exchange"
            rules={{ required: true }}
            render={({ field }) => (
              <InputNumber
                id="exchange"
                className=" mt-2 w-full"
                placeholder="e.g. 200"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                controls={false}
                {...field}
              />
            )}
          />
          {form.errors.exchange && (
            <p className="text-red-500">Exchange rate field is required</p>
          )}
        </div>
        <div className="my-3">
          <label htmlFor="tax">Tax</label>
          <Controller
            control={form.control}
            name="tax"
            render={({ field }) => (
              <InputNumber
                htmlFor="tax"
                className=" mt-2 w-full"
                placeholder="e.g. 5%"
                formatter={(value) => value && `${value}%`}
                parser={(value) => value?.replace("%", "")}
                onFocus={(e) => e.target.select()}
                controls={false}
                {...field}
              />
            )}
          />
        </div>
        <div className="mt-10 text-right">
          <Button
            type="primary"
            onClick={form.handleSubmit(
              !modal.data ? handleCreate : handleUpdate
            )}
            disabled={isLoadingCreate || isLoadingUpdate || !form.isDirty}
          >
            {!modal.data ? "Create" : "Update"}
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../../common/components/NotFound";
import ListTrainers from "./pages/ListTrainers";
import ListSkills from "./pages/ListSkills";
import { DetailSkill } from "./pages/DetailSkill";
import ListCourses from "./pages/ListCourses";
import SkillGroup from "./pages/SkillGroup";
import {
  CourseReview,
  loader as courseReviewLoader,
} from "./pages/CourseReview";
import { useQueryClient } from "react-query";
import ListJobs from "./pages/ListJobs";
// import AddJob from "./pages/AddJob";
import EditJobV2 from "./pages/EditJobV2";
import ListJobSectors from "./pages/ListJobSectors";
import AddJobV2 from "./pages/AddJobV2";
import SubscriptionsList from "./pages/SubscriptionsList";
import SubscriptionsTrialSetting from "./pages/SubscriptionsTrialSetting";
import { TalentUsers } from "./pages/TalentUsers";
import { UserRequests } from "./pages/UserRequests";
import { TalentPrivacy } from "./pages/TalentPrivacy";
import { TalentTerms } from "./pages/TalentTerms";
import { RecruiterPrivacy } from "./pages/RecruiterPrivacy";
import { TrainerTerms } from "./pages/TrainerTerms";
import { TrainerPrivacy } from "./pages/TrainerPrivacy";
import { RecruiterTerms } from "./pages/RecruiterTerms";
import { RecruiterListCoupon } from "./pages/RecruiterListCoupon";
import { Perks } from "./pages/Perks";
import { PerkDetail } from "./pages/PerkDetail";
import { VerifiedSkill } from "./pages/VerifiedSkill";
import { ListEmail } from "./pages/ListEmail";
import { AddEmail } from "./pages/AddEmail";
import { DetailEmail } from "./pages/DetailEmail";
import { ListCurrencies } from "./pages/ListCurrencies";
import ThisWeeksDiscussion from "./pages/community/ThisWeeksDiscussion.tsx";

const Index = () => {
  const queryClient = useQueryClient();
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/admin/trainers" />} />
      <Route
        path="/green-skills/trainers"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <ListTrainers />
          </div>
        }
      />
      <Route
        path="/green-skills/skills"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <ListSkills />
          </div>
        }
      />
      <Route
        path="/green-skills/skills/detail/:id"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <DetailSkill />
          </div>
        }
      />
      <Route
        path="/green-skills/skill-groups"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <SkillGroup />
          </div>
        }
      />
      <Route
        path="/green-skills/courses"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <ListCourses />
          </div>
        }
      />
      <Route
        path="/green-skills/currencies"
        element={
          <div className="my-6 mx-7 py-0 px-6">
            <ListCurrencies />
          </div>
        }
      />
      <Route
        path="/talent/users"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <TalentUsers />
          </div>
        }
      />
      <Route
        path="/talent/skill-verification"
        element={
          <div className="my-6 mx-7 py-0 px-6">
            <VerifiedSkill />
          </div>
        }
      />
      <Route
        path="/talent/requests"
        element={
          <div className="my-6 mx-7">
            <UserRequests />
          </div>
        }
      />
      <Route
        path="/courses/review/:id"
        element={
          <div div className="my-6 mx-7 bg-white py-7 px-6">
            <CourseReview />
          </div>
        }
        loader={courseReviewLoader(queryClient)}
      />
      <Route
        path="/jobs-companies/job-roles"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <ListJobs />
          </div>
        }
      />
      <Route
        path="/jobs-companies/jobs/add"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <AddJobV2 />
          </div>
        }
      />
      <Route
        path="/jobs-companies/jobs/edit/:slug"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <EditJobV2 />
          </div>
        }
      />
      <Route
        path="/jobs-companies/sectors"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <ListJobSectors />
          </div>
        }
      />
      <Route
        path="/jobs-companies/recruiters"
        element={
          <div className="my-6 mx-7 py-0 px-6">
            <SubscriptionsList />
          </div>
        }
      />
      <Route
        path="/jobs-companies/trial-setting"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <SubscriptionsTrialSetting />
          </div>
        }
      />
      <Route
        path="/jobs-companies/coupons"
        element={
          <div className="my-6 mx-7 py-0 px-6">
            <RecruiterListCoupon />
          </div>
        }
      />
      <Route
        path="/data-privacy/talent-policies/privacy"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <TalentPrivacy kind="privacy" app="talent" />
          </div>
        }
      />
      <Route
        path="/data-privacy/talent-policies/terms"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <TalentTerms kind="term" app="talent" />
          </div>
        }
      />
      <Route
        path="/data-privacy/recruiter-policies/privacy"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <RecruiterPrivacy kind="privacy" app="recruiter" />
          </div>
        }
      />
      <Route
        path="/data-privacy/recruiter-policies/terms"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <RecruiterTerms kind="term" app="recruiter" />
          </div>
        }
      />
      <Route
        path="/data-privacy/trainer-policies/privacy"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <TrainerPrivacy kind="privacy" app="trainer" />
          </div>
        }
      />
      <Route
        path="/data-privacy/trainer-policies/terms"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <TrainerTerms kind="term" app="trainer" />
          </div>
        }
      />
      <Route
        path="/marketing/perks"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <Perks />
          </div>
        }
      />
      <Route
        path="/marketing/perks/create"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <PerkDetail type="create" />
          </div>
        }
      />
      <Route
        path="/marketing/perks/:id"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <PerkDetail />
          </div>
        }
      />

      <Route
        path="/marketing/mail"
        element={
          <div className="my-6 mx-7 py-0 px-6">
            <ListEmail />
          </div>
        }
      />
      <Route
        path="/marketing/mail/add"
        element={
          <div className="my-6 mx-7 py-0 px-6">
            <AddEmail />
          </div>
        }
      />
      <Route
        path="/marketing/mail/detail/:id"
        element={
          <div className="my-6 mx-7 py-0 px-6">
            <DetailEmail />
          </div>
        }
      />
      <Route
        path="/community/this-weeks-discussion"
        element={
          <div className="my-6 mx-7 py-0 px-6">
            <ThisWeeksDiscussion />
          </div>
        }
      />

      <Route
        path="*"
        element={
          <div className="my-6 mx-7 bg-white py-7 px-6">
            <NotFound />
          </div>
        }
      />
    </Routes>
  );
};

export default Index;

/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useSkillGroupInfinite,
  useAddSkill,
  useCreateDataSource,
  useDataSource,
} from "../hooks/useSkills";
import React from "react";

const schema = yup.object().shape({
  name: yup.string().required("Skill Name is required"),
  group_id: yup.string().required("Skill Group is required"),
  school_id: yup.string(),
  description: yup.string().required("Description is required"),
  price_per_level: yup.number().typeError("Price must be a number"),
  data_source: yup.string().required("Data Source is required"),
  file: yup
    .mixed()
    .nullable()
    .test(
      "fileType",
      "Only jpg or png files are allowed",
      (value) =>
        value === null ||
        value.type === "image/jpeg" ||
        value.type === "image/png"
    )
    .test(
      "fileSize",
      "The maximum file size is 2 MB",
      (value) => value === null || value.size <= 2 * 1024 * 1024
    ),
});

export const useAddSkillDrawer = (onClose) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data, isLoading, fetchNextPage } = useSkillGroupInfinite();
  const addSkillMutate = useAddSkill();

  const handleScroll = (e) => {
    const { target } = e;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !isLoading
    ) {
      fetchNextPage();
    }
  };

  const onSaveSubmit = async (data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (key === "image") {
        if (value?.uid === "-1") {
          formData.append(key, null);
          continue;
        } else {
          formData.append("file", value);
          continue;
        }
      }
      formData.append(key, value);
    }
    try {
      await addSkillMutate.mutateAsync(formData);
      reset();
      onClose();
    } catch (error) {
      return error;
    }
  };
  const [dataSourceSearch, setDataSourceSearch] = React.useState(null);
  const createDataSourceMutate = useCreateDataSource();
  const { data: dataSource } = useDataSource();

  return {
    handleSubmit,
    control,
    errors,
    data,
    isLoading,
    handleScroll,
    onSaveSubmit,
    addSkillMutate,
    dataSourceSearch,
    setDataSourceSearch,
    createDataSourceMutate,
    dataSource,
  };
};

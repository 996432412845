import React from "react";

function Dot(props) {
  const { index, blackCount, status, handleClick } = props;
  const isBlack = index < blackCount;

  return (
    <div
      className={`w-4 h-4 rounded-full mx-1 cursor-pointer ${
        isBlack && status !== "LIVE"
          ? "bg-backdrop border"
          : isBlack && status === "LIVE"
          ? "bg-[#D4F5C5] border-backdrop border"
          : "bg-white border border-backdrop hover:bg-[#D4F5C5]"
      }`}
      onClick={() => handleClick(index + 1)}
    />
  );
}
const DotList = (props) => {
  const { value, status, handleClick } = props;
  const blackCount = Math.min(value, 5);

  return (
    <div className="flex">
      {[...Array(5)].map((_, index) => (
        <Dot
          key={index}
          index={index}
          blackCount={blackCount}
          status={status}
          handleClick={handleClick}
        />
      ))}
    </div>
  );
};

export default DotList;

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAddJobSector } from "./useJobSectors";

const schema = yup.object().shape({
  name: yup.string().required("Job Name is required"),
  // job_group_ids: yup.string().required('Job Group is required'),
  description: yup.string().required("Description is required"),
});

export const useAddJobSectorDrawer = (onClose) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const addJobMutate = useAddJobSector();

  const onSaveSubmit = async (data) => {
    data.slug = data?.name?.toLowerCase()?.replaceAll(" ", "_");
    addJobMutate.mutate(data);
    onClose();
    reset();
  };

  return {
    handleSubmit,
    control,
    errors,
    onSaveSubmit,
    addJobMutate,
  };
};

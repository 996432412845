import { Controller } from "react-hook-form";
import { Drawer, Input, Button } from "antd";
import { useAddJobSectorDrawer } from "../hooks/useAddJobSectorDrawer";
import { UploadWithFocus } from "../../../common/components/UploadWithFocus";
import { beforeUpload } from "../utils/imageUploadFuncs";
import { PlusOutlined } from "@ant-design/icons";

export function AddJobSectorDrawer({ onClose, open }) {
  const {
    handleSubmit,
    control,
    errors,
    onSaveSubmit,
    addJobMutate,
    customRequest,
  } = useAddJobSectorDrawer(onClose);

  return (
    <Drawer
      closable={false}
      placement='right'
      onClose={onClose}
      open={open}>
      <p className='font-extrabold text-xl text-backdrop mb-5'>
        Add New Sector
      </p>
      <form onSubmit={handleSubmit(onSaveSubmit)}>
        <div className='mb-5 space-y-6'>
          <div className='w-[102px] h-full'>
            <Controller
              name='image'
              control={control}
              render={({ field }) => {
                return (
                  <UploadWithFocus
                    {...field}
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                    }}
                    beforeUpload={(file) => {
                      beforeUpload(file).then((filez) => {
                        if (filez) {
                          field.onChange(filez);
                        }
                      });
                    }}
                    onChange={async (file) => {
                      field.onChange();
                    }}
                    fileList={field.value ? [field.value] : []}
                    onRemove={() => field.onChange(null)}
                    maxCount={1}
                    customRequest={customRequest}
                    className='h-full'
                    listType='picture-card'>
                    <div>
                      {<PlusOutlined />}
                      <div
                        style={{
                          marginTop: 8,
                        }}>
                        Upload
                      </div>
                    </div>
                  </UploadWithFocus>
                );
              }}
            />

            {errors.image && <p>{errors.image.message}</p>}
          </div>
          <div className='flex flex-col items-center-justify-start gap-2'>
            <Controller
              control={control}
              name='name'
              render={({ field }) => (
                <Input
                  placeholder='Sector Name'
                  {...field}
                />
              )}
            />
            {errors.name && (
              <p className='text-red-500'>{errors.name.message}</p>
            )}
          </div>

          {/* <div className='flex flex-col justify-start gap-2 w-full'>
            <Controller
              control={control}
              className='w-full'
              name='group_ids'
              render={({ field }) => (
                <Select
                  placeholder='Job Group'
                  {...field}
                  loading={isLoading}
                  onChange={(value) => field.onChange(value)}
                  onPopupScroll={handleScroll}
                >
                  {data?.pages.map((page) =>
                    page?.data?.data?.list.map((group) => (
                      <Select.Option key={group.id} value={group.id}>
                        {group.name}
                      </Select.Option>
                    ))
                  )}
                </Select>
              )}
            />
            {errors.group_ids && (
              <p className='text-red-500'>{errors.group_ids.message}</p>
            )}
          </div> */}
          <div className='flex flex-col items-center-justify-start gap-2'>
            <Controller
              control={control}
              name='description'
              render={({ field }) => (
                <Input.TextArea
                  rows={5}
                  style={{ resize: "none" }}
                  placeholder='Description'
                  {...field}
                />
              )}
            />
            {errors.description && (
              <p className='text-red-500'>{errors.description.message}</p>
            )}
          </div>
        </div>
        <div className='flex items-center justify-end gap-3'>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            loading={addJobMutate.isLoading}
            type='primary'
            className='bg-primary'
            htmlType='submit'>
            Save
          </Button>
        </div>
      </form>
    </Drawer>
  );
}

import { Button, Spin, Pagination, Modal, Empty } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { EditJobSectorDrawer } from './'
import './custom.css'

export function JobSectorTable({
  isJobSectorsLoading,
  jobSectorData,
  handlePageChange,
  handlePageSizeChange,
  selectedPage,
  itemsPerPage,
  openDrawerEdit,
  onCloseDrawerEditJobSectors,
  handleOpenDrawerEditJobSector,
  dataToEdit,
  modalOpen,
  setModalOpen,
  handleDeleteJobSector,
  handleConfirmDelete,
}) {
  console.log('jobSectorData', jobSectorData)

  return (
    <>
      <table className='w-full mt-6'>
        <thead className='grid grid-cols-12 bg-[#D4F5C5]'>
          <td className='font-bold border-t border-l border-b border-gray-300 py-3 px-5 text-backdrop col-span-8'>
            Sector Name
          </td>

          <td className='font-bold border-t border-b border-gray-300 py-3 px-5 text-backdrop col-span-2'>
            Job Roles
          </td>

          <td className='font-bold border-t border-b border-r border-gray-300 py-3 px-5 text-backdrop col-span-2'></td>
        </thead>
        <tbody>
          <Spin spinning={isJobSectorsLoading}>
            {isJobSectorsLoading && <div className='h-16 w-full'></div>}
            {jobSectorData?.data?.data === null ? (
              <tr className='grid grid-cols-12'>
                <td className='border col-span-12 w-full py-6'>
                  <Empty />
                </td>
              </tr>
            ) : (
              jobSectorData?.data?.data?.list?.map((item, index) => (
                <tr className='grid grid-cols-12' key={index}>
                  <td className='font-medium border-t border-l border-b border-gray-300 py-2 px-5 text-backdrop col-span-8 flex items-center'>
                    {item?.name}
                  </td>
                  <td className='font-medium border-t border-b border-gray-300 py-2 px-5 text-backdrop col-span-2 flex items-center'>
                    {item?.total_job || 0}
                  </td>

                  <td className='font-medium border-t border-b border-r border-gray-300 py-2 px-5 text-backdrop col-span-2'>
                    <div className='flex items-center gap-2'>
                      <Button
                        onClick={() => handleOpenDrawerEditJobSector(item)}
                      >
                        Edit
                      </Button>
                      {item?.name !== 'Other' ? (
                        <div onClick={() => handleConfirmDelete(item?.slug)}>
                          <CloseCircleOutlined className='text-xl text-red-500 cursor-pointer' />
                        </div>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </Spin>
        </tbody>
      </table>
      <div className='text-right mt-4'>
        <Pagination
          current={selectedPage}
          onChange={handlePageChange}
          onShowSizeChange={handlePageSizeChange}
          total={jobSectorData?.data?.data?.total_data}
          pageSize={itemsPerPage}
          showSizeChanger
          defaultPageSize={20}
          pageSizeOptions={[20, 50]}
        />
      </div>

      <EditJobSectorDrawer
        onClose={onCloseDrawerEditJobSectors}
        open={openDrawerEdit}
        data={dataToEdit}
      />

      <Modal
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        closeIcon={null}
        onCancel={() => setModalOpen(false)}
        closable={false}
        width={300}
        wrapClassName='custom-modal'
        className='rounded'
        footer={[
          <Button
            key='back'
            className='rounded border-primary'
            onClick={handleDeleteJobSector}
          >
            Yes, Delete
          </Button>,
          <Button
            key='submit'
            type='primary'
            className='bg-primary rounded'
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>,
        ]}
      >
        <p className='text-2xl my-3 text-center font-extrabold text-backdrop mb-3'>
          Confirm Delete?
        </p>
        <br />
      </Modal>
    </>
  )
}

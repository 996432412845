import { Button, Tooltip, Table, Popover } from 'antd'
import { useNavigate } from 'react-router-dom'
import { InfoCircleOutlined, StarFilled, StarOutlined } from '@ant-design/icons'

export function SkillsTable({
  searchValue,
  isSkillsLoading,
  skillsData,
  handlePageChange,
  handlePageSizeChange,
  selectedPage,
  itemsPerPage,
  handleSetFeatureSkill,
}) {
  const navigate = useNavigate()

  const columns = [
    {
      title: <span className='font-bold'>Skill Name</span>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <span className='font-bold'>School</span>,
      dataIndex: 'school',
      key: 'school',
      render: (school) => (school?.name ? school?.name : '-'),
    },
    {
      title: <span className='font-bold'>Skill Groups</span>,
      dataIndex: 'groups',
      key: 'groups',
      render: (groups) => (groups?.name ? groups?.name : '-'),
    },
    {
      title: <span className='font-bold'>Published Levels</span>,
      dataIndex: 'published_level',
      key: 'published_level',
      render: (text, record) =>
        `${record.published_level} of ${record.total_level}`,
    },
    {
      title: (
        <span className='font-bold'>
          PPL{' '}
          <Tooltip title='Price per Level'>
            <InfoCircleOutlined className='cursor-pointer text-sm' />
          </Tooltip>
        </span>
      ),
      dataIndex: 'price_per_level',
      key: 'price_per_level',
      render: (price_per_level = 0) => `$${price_per_level.toFixed(2)}`,
    },
    {
      title: '',
      dataIndex: 'is_feature_skill',
      key: 'edit',
      render: (value, record) => (
        <div className='flex items-center'>
          {value ? (
            <Popover content='feature skill' className='w-10'>
              <StarFilled className='w-10 text-lg hover:text-primary cursor-pointer' />
            </Popover>
          ) : (
            <Popover content='set as feature skill' className='w-10'>
              {' '}
              <StarOutlined
                className='text-lg hover:text-primary cursor-pointer'
                onClick={() => handleSetFeatureSkill(record?.id)}
              />{' '}
            </Popover>
          )}
          <Button onClick={() => navigate(`/admin/green-skills/skills/detail/${record.id}`)}>
            Edit
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <div className='admin-table mt-6'>
        <Table
          columns={columns}
          dataSource={skillsData?.data?.data?.list}
          rowKey='id'
          pagination={{
            current: selectedPage,
            onChange: handlePageChange,
            onShowSizeChange: handlePageSizeChange,
            total: skillsData?.data?.data?.total_data,
            pageSize: itemsPerPage,
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: [10, 20, 50],
          }}
          loading={isSkillsLoading}
        />
      </div>
    </>
  )
}

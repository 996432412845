import { useQuery } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";

export const useCoupon = () => {
  const { data, isLoading } = useQuery(
    "list-coupon",
    () => httpRequest.get("/skilledin/subscriptions/coupons"),
    {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching coupons"
        );
      },
    }
  );
  return {
    data: data?.data?.data,
    isLoading,
  };
};

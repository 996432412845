import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";

export const useSkillVerification = () => {
  const queryClient = useQueryClient();
  const [params, setParams] = React.useState({
    limit: 10,
    page: 1,
    status: "",
  });
  const { data, isLoading } = useQuery(
    ["verified-skill", params],
    () => httpRequest.get("/skilledin/verified-skill", { params }),
    {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching verified skills"
        );
      },
    }
  );

  const { mutateAsync: asyncReject, isLoading: isLoadingReject } = useMutation(
    "verified-skill-reject",
    (id) => {
      return httpRequest.patch(`/skilledin/verified-skill/rejected/${id}`);
    }
  );

  const handleReject = async (data) => {
    try {
      await asyncReject(data);
      queryClient.invalidateQueries("verified-skill");
      message.success("Rejected successfully");
    } catch (error) {
      message.error(
        error?.response?.data?.message || "An error occurred while rejected"
      );
    }
  };

  const { mutateAsync: asyncAccept, isLoading: isLoadingAccept } = useMutation(
    "verified-skill-accept",
    (id) => {
      return httpRequest.patch(`/skilledin/verified-skill/accepted/${id}`);
    }
  );

  const handleAccept = async (data) => {
    try {
      await asyncAccept(data);
      queryClient.invalidateQueries("verified-skill");
      message.success("Accepted successfully");
    } catch (error) {
      message.error(
        error?.response?.data?.data || "An error occurred while accepted"
      );
    }
  };

  return {
    data: {
      data: data?.data?.data,
      isLoading,
    },
    params: {
      params,
      setParams,
    },
    action: {
      handleReject,
      isLoadingReject,
      handleAccept,
      isLoadingAccept,
    },
  };
};

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState, useEffect } from "react";
import { message } from "antd";
import { useGreenSkillForAdminEdit } from "./useSkills";
import dayjs from "dayjs";
import { useEditTrainer } from "../hooks/useTrainers";
import { useQueryClient } from "react-query";

const schema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  display_name: yup.string().required("Display name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  sharing_profit: yup
    .number()
    .required("School's share in the profit is required")
    .positive("School's share must be a positive number")
    .test(
      "is-between-1-100",
      "School's share must be between 1 and 100",
      (value) => {
        return value >= 1 && value <= 100;
      }
    ),
  contract_expired_at: yup.date().required("Contract expiry date is required"),
});

export const useEditTrainerDrawer = (onClose, trainer) => {
  const queryClient = useQueryClient();
  // console.log("trainer", trainer);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // Pre-fill form inputs with the values of the selected skill
      first_name: trainer?.first_name || "",
      last_name: trainer?.last_name || "",
      display_name: trainer?.display_name || "",
      email: trainer?.email || "",
      sharing_profit: trainer?.school?.sharing_profit || null,
      contract_expired_at: trainer?.school?.contract_expired_at
        ? dayjs(new Date(trainer?.school?.contract_expired_at))
        : dayjs(new Date()),
      // Add more form inputs as needed
    },
  });
  const editTrainerMutate = useEditTrainer();

  const onSaveSubmit = async (data) => {
    // console.log({
    //   ...data,
    //   contract_expired_at: dayjs(data.contract_expired_at).unix(),
    // });
    if (greenSkillData.length === 0) {
      message.error("Please select at least one green skill");
      return;
    }
    const green_skill_ids = greenSkillData.map((item) => item.value);
    message.open({
      key: "edit_admin",
      type: "loading",
      content: "loading...",
    });
    await editTrainerMutate.mutateAsync({
      data: {
        ...data,
        green_skill_ids,
        contract_expired_at: dayjs(data.contract_expired_at).unix(),
        id: trainer?.id,
      },
      id: trainer?.id,
    });
    onClose();
    queryClient.refetchQueries("green-skills-list-add");
  };

  // const [searchValueGS, setSearchValueGS] = useState("");
  // const [optionsGS, setOptionsGS] = useState([]);
  const [greenSkillData, setGreenSkillData] = useState(
    trainer?.school?.green_skills ?? []
  );

  // const {
  //   data: greenSkills,
  //   isFetchingNextPage: greenSkillLoading,
  //   fetchNextPage: fetchNextPageGS,
  // } = useGreenSkill(searchValueGS);
  const {
    params,
    // setParams,
    // handleClear,
    handleOwnesSkill,
    dataList: optionsGS,
    handleSearch: handleSearchGS,
    isLoading: greenSkillLoading,
    handleScroll: handleScrollGS,
  } = useGreenSkillForAdminEdit();

  // const handleScrollGS = (e) => {
  //   const { target } = e;
  //   const maxScrollPosition =
  //     target.scrollHeight - target.clientHeight - target.clientHeight * 0.1;

  //   if (target.scrollTop >= maxScrollPosition && !greenSkillLoading) {
  //     fetchNextPageGS();
  //   }
  // };
  useEffect(() => {
    if (trainer?.school) {
      setGreenSkillData(
        trainer?.school?.green_skills?.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? []
      );
      handleOwnesSkill(trainer?.school?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainer?.school]);

  // useEffect(() => {
  //   if (greenSkills?.pages) {
  //     setOptionsGS(() =>
  //       greenSkills.pages.flatMap((page) => page.data.data.list)
  //     );
  //   }
  // }, [greenSkills?.pages]);

  const onSelectGS = (value) => {
    if (optionsGS.length !== 0) {
      //   const selectedItem = optionsGS?.find((item) => item.name === value);

      //   const formattedData = {
      //     id: selectedItem?.id,
      //     name: selectedItem?.name,
      //   };
      //   // check if green skill data already has the selected item
      const isAlreadySelected = greenSkillData?.find(
        (item) => item.value === value.value
      );
      if (isAlreadySelected) {
        message.info({ content: "already selected" });
        return;
      }

      setGreenSkillData((prevSelectedItems) => [...prevSelectedItems, value]);
    }

    // setOptionsGS(optionsGS?.filter((opt) => opt.name !== formattedData.name));
    // setSearchValueGS("");
  };

  // const handleSearchGS = (value) => {
  //   setSearchValueGS(value);
  // };
  const handleDeleteGreenSkill = (id) => {
    const updatedData = greenSkillData.filter((item) => item.value !== id);
    setGreenSkillData(updatedData);
  };

  return {
    handleSubmit,
    control,
    errors,
    onSaveSubmit,
    handleScrollGS,
    onSelectGS,
    handleSearchGS,
    optionsGS,
    params,
    // searchValueGS,
    greenSkillData,
    handleDeleteGreenSkill,
    greenSkillLoading,
  };
};

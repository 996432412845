import React, { useState } from "react";
import CustomHeader from "../../../common/layout/CustomHeader";
import { Link, useParams } from "react-router-dom";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { Row, Col, Button, Skeleton, Table } from "antd";
import EditSkillDrawer from "../components/EditSkillDrawer";
import { useSkillsDetail } from "../hooks/useSkills";
import ReactQuill from "react-quill";

export const DetailSkill = () => {
  const [openDrawerEdit, setOpenDrawerEdit] = useState(false);
  const { id } = useParams();
  const skillDetailQuery = useSkillsDetail(id);
  const columns = [
    {
      title: "Level",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Students (all)",
      dataIndex: "student",
      key: "student",
    },
    {
      title: "Current Month",
      dataIndex: "current_month",
      key: "current_month",
    },
    {
      title: "Published on",
      dataIndex: "published_on",
      key: "published_on",
      render: (text, record) => {
        return record.published_on?.datetime
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }).format(new Date(record.published_on.datetime))
          : "N/A";
      },
    },
    {
      title: "Updated on",
      dataIndex: "updated_on",
      key: "updated_on",
      render: (text, record) => {
        return record.updated_on?.datetime
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }).format(new Date(record.updated_on.datetime))
          : "N/A";
      },
    },
  ];
  const dataSource = skillDetailQuery.data?.data?.data?.levels?.map(
    (item, index) => {
      return {
        key: index,
        ...item,
      };
    }
  );

  return (
    <div className="">
      <div className="ml-[-16.5rem]">
        <CustomHeader />
      </div>
      {skillDetailQuery.isLoading ? (
        <Skeleton active title={false} paragraph={{ rows: 10 }} />
      ) : (
        <>
          <div className="flex items-center gap-3">
            <Link to="/admin/green-skills/skills">
              <ArrowLeftOutlined className="text-backdrop text-lg" />
            </Link>

            <span className="text-2xl font-bold pt-2 text-backdrop mb-3 flex-grow">
              {skillDetailQuery.data?.data?.data?.name}
            </span>

            <Button
              className="bg-primary text-white"
              onClick={() => setOpenDrawerEdit(true)}
              icon={<EditOutlined className="pb-3" />}
            >
              Edit Skill
            </Button>
          </div>

          <div className="mt-4 px-4">
            {skillDetailQuery.isLoading ? null : (
              <Row gutter={[16, 16]}>
                <Col span={10}>
                  <div className="flex items-center text-backdrop gap-4">
                    <div className="text-backdrop font-semibold">
                      Skill Name
                    </div>
                    {
                      <div className="flex-grow">
                        {skillDetailQuery.data?.data?.data?.name}
                      </div>
                    }
                  </div>
                  <div className="flex items-center text-backdrop gap-4 mt-4">
                    <div className="text-backdrop font-semibold">
                      Skill Group
                    </div>
                    {
                      <div>
                        {skillDetailQuery.data?.data?.data?.groups
                          ? skillDetailQuery.data?.data?.data?.groups?.name
                          : "N/A"}
                      </div>
                    }
                  </div>
                </Col>
                <Col span={10}>
                  <div className="flex items-center text-backdrop gap-4">
                    <div className="text-backdrop font-semibold">School</div>
                    {
                      <div>
                        {skillDetailQuery.data?.data?.data?.school
                          ? skillDetailQuery.data?.data?.data?.school?.name
                          : "-"}
                      </div>
                    }
                  </div>
                  <div className="flex items-center text-backdrop gap-4 mt-4">
                    <div className="text-backdrop font-semibold">
                      Price per level
                    </div>
                    {
                      <div>
                        {skillDetailQuery.data?.data?.data?.price_per_level}
                      </div>
                    }
                  </div>
                </Col>
              </Row>
            )}
          </div>

          <div className="admin-table">
            <Table
              className="w-full mt-6"
              columns={columns}
              dataSource={dataSource}
              loading={skillDetailQuery?.isLoading}
              pagination={false}
            />
          </div>

          <div className="mt-4">
            <div className="text-backdrop font-semibold text-lg">
              Skill Description
            </div>
            <ReactQuill
              readOnly
              className="text-backdrop text-base"
              value={skillDetailQuery.data?.data?.data?.description ?? "-"}
              theme="bubble"
            />
          </div>
          <div className="mt-4">
            <div className="text-backdrop font-semibold text-lg">
              Typical Activities
            </div>
            <ReactQuill
              readOnly
              className="text-backdrop text-base"
              value={
                skillDetailQuery.data?.data?.data?.typical_activities ?? "-"
              }
              theme="bubble"
            />
          </div>
          <div className="mt-4">
            <div className="text-backdrop font-semibold text-lg">
              Success Factors
            </div>
            <ReactQuill
              readOnly
              className="text-backdrop text-base"
              value={skillDetailQuery.data?.data?.data?.success_factor ?? "-"}
              theme="bubble"
            />
          </div>

          {skillDetailQuery.data?.data?.data && (
            <EditSkillDrawer
              onClose={() => setOpenDrawerEdit(false)}
              open={openDrawerEdit}
              skill={skillDetailQuery.data?.data?.data}
            />
          )}
        </>
      )}
    </div>
  );
};

import React from "react";
import InputSearch from "../../../common/components/InputSearch";
import { Button, Select, Switch } from "antd";
import { Link } from "react-router-dom";
import { SkillsTable, AddSkillDrawer } from "../components";
import { useListSkills } from "../hooks/useListSkills";
import { useSchool } from "../hooks/useTrainers";
import { renderNavLink } from "../../../common/components/NavLink";
import { useApplicationStore } from "../../../store/applicationStore";
const ListSkills = () => {
  const schoolsQuery = useSchool();

  const {
    openDrawerAdd,
    skillSearch,
    handleOpenDrawerAddSkill,
    onCloseDrawerAdd,
    handleSkillSearch,
    handleFilterBySchoolAssigned,
    schoolSelected,
    setSchoolSelected,
    // publishedLevel,
    // setPublishedLevel,
    isSkillsLoading,
    skillsData,
    handlePageChange,
    handlePageSizeChange,
    selectedPage,
    itemsPerPage,
    isSchoolAssigned,
    handleSetFeatureSkill,
    group,
  } = useListSkills();

  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([
      renderNavLink("/admin/green-skills/skills", "Skills"),
      renderNavLink("/admin/green-skills/skill-groups", "Skill Group"),
    ]);
  }, [setLinks]);
  return (
    <div>
      <p className="text-xl font-extrabold text-backdrop mb-3">Manage Skills</p>
      <div className="grid grid-cols-12 gap-7 mt-7">
        <div className="col-span-8">
          <InputSearch
            value={skillSearch}
            handleSearchChange={handleSkillSearch}
            placeholder={"search skill"}
          />
        </div>
        <div className="col-span-2">
          <Select
            loading={schoolsQuery?.isLoading}
            placeholder="Select School"
            allowClear
            className="w-full"
            defaultValue={schoolSelected}
            onChange={(value) => setSchoolSelected(value)}
            options={schoolsQuery?.data?.data?.data.map((school) => ({
              value: school?.id,
              label: school?.name || "unknown",
            }))}
          />
        </div>
        <div className="col-span-2">
          <Button
            className="bg-primary text-white w-full"
            onClick={handleOpenDrawerAddSkill}>
            Add New Skill
          </Button>
        </div>
      </div>
      <div className="flex items-center gap-12 mt-4">
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-3">
            <span>School not assigned</span>
            <Switch
              checked={isSchoolAssigned === 1}
              onChange={(e) => handleFilterBySchoolAssigned(e)}
              className={"border"}
            />
          </div>
          <Select
            placeholder="Select Groups"
            // defaultValue={publishedLevel}
            allowClear
            filterOption={false}
            options={group?.data?.dataList?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            listHeight={200}
            style={{ width: 200 }}
            loading={group?.data?.isLoading}
            onChange={(e, v) => group.action(v)}
            onPopupScroll={group?.data?.handleScroll}
          />
        </div>
        <Link to="/admin/green-skills/skill-groups">
          <p className="text-blue-400 font-bold">Manage Groups</p>
        </Link>
      </div>

      {/* TABLE */}
      <SkillsTable
        searchValue={skillSearch}
        isSkillsLoading={isSkillsLoading}
        skillsData={skillsData}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        handleSetFeatureSkill={handleSetFeatureSkill}
        selectedPage={selectedPage}
        itemsPerPage={itemsPerPage}
      />
      <AddSkillDrawer onClose={onCloseDrawerAdd} open={openDrawerAdd} />
    </div>
  );
};

export default ListSkills;

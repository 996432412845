import React from "react";
import InputSearch from "../../../common/components/InputSearch";
import { Button, Select } from "antd";
import {
  JobsTable,
  // AddJobDrawer
} from "../components";
import { useListJobs } from "../hooks/useListJobs";
import { renderNavLink } from "../../../common/components/NavLink";
import { useNavigate } from "react-router-dom";
// import CustomInputDropdownNew from "../../../common/components/CustomInputDropdownNew";
import { useClone } from "../hooks/useJobs";
import { useApplicationStore } from "../../../store/applicationStore";

const ListJobs = () => {
  const navigate = useNavigate();

  const {
    isJobsLoading,
    jobsData,
    handlePageChange,
    handlePageSizeChange,
    selectedPage,
    itemsPerPage,
    jobSearch,
    handleJobSearch,
    handleRemoveFilter,

    // sector
    // sectorName,
    // handleFilterBySector,
    // optionJobSectors,
    // handleScrollJobSectors,
    // isLoadingFetchingJobSectorsNextPage,
    // isLoadingJobSectors,
    // searchJobSector,
    // setSearchJobSector,
    // isDropdownSectorOpen,
    // setIsDropdownSectorOpen,
    dataSector,
    handleSelectSector,
  } = useListJobs();

  const clone = useClone();
  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([
      renderNavLink("/admin/jobs-companies/job-roles", "Jobs"),
      renderNavLink("/admin/jobs-companies/sectors", "Sectors"),
    ]);
  }, [setLinks]);

  return (
    <div>
      <p className="text-xl font-extrabold text-backdrop mb-3">
        Manage Job Roles
      </p>
      <div className="grid grid-cols-12 gap-7 mt-7">
        <div className="col-span-8">
          <InputSearch
            value={jobSearch}
            handleSearchChange={handleJobSearch}
            placeholder={"Search by name"}
          />
        </div>

        <div className="col-span-2">
          <Select
            className="w-full"
            placeholder="Job Sector"
            loading={dataSector?.isLoading}
            onSelect={handleSelectSector}
            // onChange={(value) => field.onChange(value)}
            onDropdownVisibleChange={dataSector?.handleClear}
            onPopupScroll={dataSector?.handleScroll}
            filterOption={false}
            onSearch={dataSector?.handleSearch}
            allowClear
            showSearch
            onClear={handleRemoveFilter}
            options={dataSector?.dataList?.map((item) => ({
              label: item.name,
              value: item.id,
            }))}></Select>
        </div>

        <div className="col-span-2">
          <Button
            className="bg-primary text-white w-full"
            onClick={() => navigate("/admin/jobs-companies/job-roles/add")}>
            Add New Job
          </Button>
        </div>
      </div>

      {/* TABLE */}
      <JobsTable
        isJobsLoading={isJobsLoading}
        jobsData={jobsData}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        selectedPage={selectedPage}
        itemsPerPage={itemsPerPage}
        clone={clone}
      />
    </div>
  );
};

export default ListJobs;

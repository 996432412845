import { useMutation, useQuery, useQueryClient } from "react-query";
import { Table, Space, Popconfirm, message, Select } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import {
  deleteTopicDiscussion,
  getDiscussionList,
  ThisWeeksDiscussion,
} from "../api/this-weeks-discussion.ts";
import { DeleteFilled, EditOutlined, EyeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

interface DiscussionTableProps {
  handleOpenDetailDiscussion: (discussion?: ThisWeeksDiscussion) => void;
  handleOpenModal: (discussion?: ThisWeeksDiscussion) => void;
}

type FilterType = "upcoming" | "ongoing" | "expire" | "all";

const DiscussionTable: React.FC<DiscussionTableProps> = ({
  handleOpenDetailDiscussion,
  handleOpenModal,
}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState<FilterType>("all");

  const { data, isLoading } = useQuery(
    ["discussions", filter, limit, page],
    () => getDiscussionList(limit, page, filter === "all" ? undefined : filter),
    { keepPreviousData: true }
  );

  const deleteDiscussion = useMutation(deleteTopicDiscussion);
  const queryClient = useQueryClient();

  const handleDelete = async (discussion_id: string) => {
    await deleteDiscussion.mutateAsync(discussion_id, {
      onSuccess: () => {
        queryClient.invalidateQueries("discussions");
        message.success("Comment deleted successfully");
      },
      onError: (error: any) => {
        message.error({
          content:
            error?.response?.data?.message ||
            "Something went wrong whilst deleting comment, try again!",
        });
        console.error(error);
      },
    });
  };

  const tableData = data?.data?.data?.list as ThisWeeksDiscussion[] | undefined;
  console.log(data?.data?.data?.pagination?.total_page)

  const columns: ColumnsType<ThisWeeksDiscussion> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Display At",
      dataIndex: "display_at",
      key: "display_at",
      render: (timestamp) => dayjs(timestamp).format("DD/MM/YYYY"),
    },
    {
      title: "Expired At",
      dataIndex: "expired_at",
      key: "expired_at",
      render: (timestamp) => dayjs(timestamp).format("DD/MM/YYYY"),
    },
    {
      title: "Hashtags",
      dataIndex: "hashtags",
      key: "hashtags",
      render: (hashtags) => (hashtags ? hashtags.join(", ") : "None"),
    },
    {
      title: "Total Comments",
      dataIndex: "total_comments",
      key: "total_comments",
    },
    {
      title: "Total Reactions",
      dataIndex: "total_reaction",
      key: "total_reaction",
    },
    {
      title: "Total Views",
      dataIndex: "total_views",
      key: "total_views",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="large">
          <EyeOutlined
            className="text-lg cursor-pointer"
            onClick={() => handleOpenDetailDiscussion(record)}
          />
          <EditOutlined
            className="text-lg cursor-pointer"
            onClick={() => handleOpenModal(record)}
          />
          <Popconfirm
            title="Before deleting this please understand everything related to this discussion will also be deleted, this includes the comments, likes, and any other information you find in the table."
            onConfirm={() => handleDelete(record?.id)}
            okText="Delete"
            okButtonProps={{
              className: "!bg-danger !text-white",
            }}
            cancelText="No">
            <DeleteFilled className="transition-all duration-300 cursor-pointer  text-backdrop hover:text-danger hover:scale-110" />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Select
        className="rounded min-w-[100px] mb-5"
        placeholder="Filter"
        onSelect={(value: FilterType) => {
          setFilter(value);
        }}>
        <Select.Option value="all">All</Select.Option>
        <Select.Option value="upcoming">Upcoming</Select.Option>
        <Select.Option value="ongoing">Ongoing</Select.Option>
        <Select.Option value="expire">Expire</Select.Option>
      </Select>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          current: page,
          pageSize: limit,
          total: data?.data?.data?.total_data,
          onChange: (page, pageSize) => {
            setPage(page);
            setLimit(pageSize);
          },
        }}
        loading={isLoading}
        rowKey={(record) => record.id}
      />
    </>
  );
};

export default DiscussionTable;

import React from "react";
import { usePolicies } from "../hooks/usePolicies";
import { renderNavLink } from "../../../common/components/NavLink";
import { Policies } from "../components/Policies";
import { useApplicationStore } from "../../../store/applicationStore";

export const RecruiterTerms = ({ kind = "privacy", app = "recruiter" }) => {
  const { fieldArray, control, handleSubmit, errors, isLoading, data, action } =
    usePolicies(kind, app);
  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([
      renderNavLink(
        "/admin/data-privacy/recruiter-policies/privacy",
        "Privacy"
      ),
      renderNavLink("/admin/data-privacy/recruiter-policies/terms", "Terms"),
    ]);
  }, [setLinks]);
  return (
    <div>
      <div>
        <p className="text-xl font-extrabold text-backdrop mb-3 capitalize">
          {`${kind === "term" ? "terms" : kind} Policy`}
        </p>
        <Policies
          fieldArray={fieldArray}
          control={control}
          handleSubmit={handleSubmit}
          errors={errors}
          isLoading={isLoading}
          data={data}
          action={action}
        />
      </div>
    </div>
  );
};

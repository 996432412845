import { useMutation, useQuery, useQueryClient } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";

export const useSubscriptionSetting = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(
    "trial-setting",
    () => httpRequest.get("/skilledin/subscriptions/trial"),
    {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching trial settings"
        );
      },
    }
  );

  const { mutateAsync, isLoading: isLoadingMutate } = useMutation(
    "trial-setting",
    (data) => {
      return httpRequest.patch("/skilledin/subscriptions/trial", data);
    }
  );

  const handleUpdate = async (data) => {
    try {
      await mutateAsync(data);
      queryClient.invalidateQueries("trial-setting");
      message.success("Trial settings updated successfully");
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "An error occurred while updating trial settings"
      );
    }
  };

  return {
    data: {
      data: data?.data?.data,
      isLoading,
    },
    action: {
      handleUpdate,
      isLoading: isLoadingMutate,
    },
  };
};

import React, { useEffect } from "react";
import { Button } from "antd";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import dayjs from "dayjs";
import { useHeader } from "../../../common/hooks/useCustomHeader";

const Login = () => {
  const { handleLoginRedirect } = useHeader();
  // const navigate = useNavigate()
  useEffect(() => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    const onboard = queryParams.get("first_login");
    if (onboard === "true") {
      window.localStorage.setItem("first_login", onboard);
    }
  }, []);
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
      ></MsalAuthenticationTemplate>
      <div className="w-full bg-[#E2EBFA]  h-full flex flex-col ">
        <div className="w-full h-full flex justify-center items-center">
          <div className="w-1/3 -mt-10 flex flex-col justify-center items-center">
            <div className=" text-xl font-bold text-center mb-5">
              Login to your Account
            </div>
            <div className="my-3 text-semibold text-center text-lg text-primary">
              You should be redirected automatically for login. If you have not
              yet, please press the button below
            </div>
            <Button
              className=" text-left bg-primary-bg text-primary"
              onClick={handleLoginRedirect}
            >
              Sign In
            </Button>

            <div className="mt-3">
              Want to be part of the Skilledin Trainers family?
              <span className="text-[#009ACE] ml-1 cursor-pointer">
                Find out more!
              </span>
            </div>
          </div>
        </div>
        <div className="h-10 mx-auto">
          {`Copyright ©`}
          <span className="text-[#009ACE]">{`Skilledin`}</span>
          {` ${dayjs().format("YYYY")}`}
        </div>
      </div>
    </>
  );
};

export default Login;

import React from "react";
import { CardQuestion } from "./CardQuestion";

const Assesment = ({ existingData, newData }) => {
  return newData?.map((item, index) => (
    <CardQuestion
      key={index}
      name={`Question ${index + 1}`}
      newData={item}
      existingData={existingData?.find((itm) => itm.id === item.id)}
    />
  ));
};

export default Assesment;

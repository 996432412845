import React from "react";
import { useApplicationStore } from "../../../../store/applicationStore";
import DiscussionTable from "../../components/DiscussionTable.tsx";
import CreateDiscussionModal from "../../components/CreateDiscussionModal.tsx";
import { ThisWeeksDiscussion as ThisWeeksDiscussionType } from "../../api/this-weeks-discussion.ts";
import { Button } from "antd";
import DetailDiscussionDrawer from "../../components/DetailDiscussionDrawer.tsx";

const ThisWeeksDiscussion = () => {
  const { setLinks } = useApplicationStore();
  const [selectedDiscussion, setSelectedDiscussion] =
    React.useState<null | ThisWeeksDiscussionType>(null);

  const [openModal, setOpenModal] = React.useState(false);
  const [openDetailDiscussion, setOpenDetailDiscussion] = React.useState(false);

  const closeModal = () => {
    setSelectedDiscussion(null);
    setOpenModal(false);
  };

  const handleOpenModal = (discussion?: ThisWeeksDiscussionType) => {
    setSelectedDiscussion(discussion ?? null);
    setOpenModal(true);
  };

  const handleOpenDetailDiscussion = (discussion?: ThisWeeksDiscussionType) => {
    setOpenDetailDiscussion(true);
    setSelectedDiscussion(discussion ?? null);
  };

  const handleCloseDetailDiscussion = () => {
    setOpenDetailDiscussion(false);
    setSelectedDiscussion(null);
  };

  React.useEffect(() => {
    setLinks([]);
  }, [setLinks]);

  React.useEffect(() => {
    if (!openModal) {
      setSelectedDiscussion(null);
    }
  }, [openModal]);

  return (
    <section className="bg-white p-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold">This Weeks Discussion</h1>
        <Button
          className="bg-primary text-white"
          onClick={() => {
            setSelectedDiscussion(null);
            handleOpenModal();
          }}>
          Create Discussion
        </Button>
      </div>
      <DiscussionTable
        handleOpenDetailDiscussion={handleOpenDetailDiscussion}
        handleOpenModal={handleOpenModal}
      />

      {selectedDiscussion && (
        <DetailDiscussionDrawer
          thisWeekDiscussion={selectedDiscussion}
          handleCloseDetailDiscussion={handleCloseDetailDiscussion}
          isOpen={openDetailDiscussion}
        />
      )}
      <CreateDiscussionModal
        thisWeekDiscussion={selectedDiscussion}
        closeModal={closeModal}
        openModal={openModal}
      />
    </section>
  );
};

export default ThisWeeksDiscussion;

import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../modules/auth/page/Login";
import Logout from "../modules/auth/page/Logout";
import NotFound from "../modules/auth/page/NotFound";
import { useMsal } from "@azure/msal-react";
import React from "react";
import Admin from "../modules/admin";
// import { message } from "antd";

function Index() {
  const { instance } = useMsal();
  const activeAccount = instance.getAllAccounts()[0];
  function ProtectRoutes({ children }) {
    return activeAccount ? children : <Navigate to="/login" />;
  }
  function PreventRoutes({ children }) {
    return activeAccount ? (
      <>
        {/* {contextHolder} */}
        <Navigate to="/admin/green-skills/trainers" />
      </>
    ) : (
      children
    );
  }
  function AdminRoutes({ children }) {
    const isAdmin = activeAccount?.idTokenClaims?.roles?.includes("Lms.Admin");
    return isAdmin ? <>{children}</> : <Navigate to="/admin/green-skills/trainers" />;
  }
  return (
    <Routes>
      <Route
        path="/"
        element={
          activeAccount ? (
            <PreventRoutes>
              <ProtectRoutes>
                <AdminRoutes>
                  <Admin />
                </AdminRoutes>
              </ProtectRoutes>
            </PreventRoutes>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/login"
        element={
          <PreventRoutes>
            <Login />
          </PreventRoutes>
        }
      />
      <Route
        path="/logout"
        element={
          <PreventRoutes>
            <Logout />
          </PreventRoutes>
        }
      />

      <Route
        path="/admin/*"
        element={
          <ProtectRoutes>
            <AdminRoutes>
              <Admin />
            </AdminRoutes>
          </ProtectRoutes>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Index;

import { useMutation, useQuery, useQueryClient } from "react-query";
import { message } from "antd";
import httpRequest from "../../../config/config";

const addSkillGroup = (data) => {
  return httpRequest.post("/skilledin/green-skill-groups", { ...data });
};

const fetchSkillGroups = (params) => {
  return httpRequest.get("/skilledin/green-skill-groups?limit=100");
};

const fetchSingleSkillGroup = (id) => {
  return httpRequest.get(`/skilledin/green-skill-groups/${id}`);
};

const deleteSkillGroup = (id) => {
  return httpRequest.delete(`/skilledin/green-skill-groups/${id}`);
};

const updateSkillGroup = (data) => {
  const { id, ...skilGroupData } = data;
  return httpRequest.patch(`/skilledin/green-skill-groups/${data.id}`, {
    ...skilGroupData,
  });
};

const useSkillGroups = (params = {}) => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching skill groups: ${error}`);
  };

  return useQuery(
    ["skill-groups-fetch", params],
    () => fetchSkillGroups(params),
    {
      refetchOnWindowFocus: false,
      onError: handleFetchError,
    }
  );
};

const useDeleteSkillGroup = () => {
  const queryClient = useQueryClient();

  const handleDeleteSuccess = () => {
    message.success("Skill Group successfully deleted");
  };

  const handleDeleteError = (error) => {
    message.error(`An error occurred while editing the skill group: ${error}`);
  };

  return useMutation((data) => deleteSkillGroup(data), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["skill-groups-fetch"]);
      handleDeleteSuccess();
    },
    onError: handleDeleteError,
  });
};

const useSingleSkillGroup = (id) => {
  const handleFetchError = (error) => {
    message.error(`An error occurred while fetching the skill group: ${error}`);
  };
  return useQuery(["skill-group", id], () => fetchSingleSkillGroup(id), {
    refetchOnWindowFocus: false,
    onError: handleFetchError,
  });
};

const useAddSkillGroup = () => {
  const queryClient = useQueryClient();

  const handleAddSuccess = () => {
    message.success("Skill Group successfully added.");
  };

  const handleAddError = (error) => {
    message.error(`An error occurred while adding the skill group: ${error}`);
  };

  return useMutation((skillGroupData) => addSkillGroup(skillGroupData), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["skill-groups-fetch"]);
      handleAddSuccess();
    },
    onError: handleAddError,
  });
};

const useEditSkillGroup = () => {
  const queryClient = useQueryClient();

  const handleEditSuccess = () => {
    message.success("Skill Group successfully edited");
  };

  const handleEditError = (error) => {
    message.error(`An error occurred while editing the skill group: ${error}`);
  };

  return useMutation((data) => updateSkillGroup(data), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      queryClient.invalidateQueries(["skill-groups-fetch"]);
      handleEditSuccess();
    },
    onError: handleEditError,
  });
};

export {
  useSkillGroups,
  useAddSkillGroup,
  useEditSkillGroup,
  useSingleSkillGroup,
  useDeleteSkillGroup,
};

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Table, Popconfirm } from "antd";
import { useSkillGroups, useDeleteSkillGroup } from "../hooks/useSkillGroup";
import { EditSkillGroup, AddSkillGroupDrawer } from "../components";
import { renderNavLink } from "../../../common/components/NavLink";
import { useApplicationStore } from "../../../store/applicationStore";

const SkillGroup = () => {
  // const { Option } = Select;

  const skillGroupsQuery = useSkillGroups();
  const deleteMutation = useDeleteSkillGroup();

  const [openDrawerEdit, setOpenDrawerEdit] = useState(false);
  const [openDrawerAdd, setOpenDrawerAdd] = useState(false);
  const [selectedSkillGroup, setSelectedSkillGroup] = useState(null);

  const onCloseDrawerEdit = () => {
    setOpenDrawerEdit(false);
    setSelectedSkillGroup(null);
  };
  const handleEditDrawerSkillGroup = (skillGroup) => {
    console.log(skillGroup);
    setSelectedSkillGroup(skillGroup);
    setOpenDrawerEdit(true);
  };

  const columns = [
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="font-medium">{text}</span>,
    },
    {
      title: "Skills In Group",
      dataIndex: "total_skill",
      key: "total_skill",
      render: (text) => <span className="font-medium">{text}</span>,
    },
    {
      key: "actions",
      width: "18%",
      align: "left",
      render: (text, record) => {
        return (
          <div className="flex gap-5 justify-start">
            <Button
              className="bg-primary text-white "
              onClick={() => handleEditDrawerSkillGroup(record)}>
              Edit
            </Button>
            <Popconfirm
              title="Delete the Skill Group?"
              description="Are you sure to delete this Skill Group?"
              onConfirm={() => deleteMutation.mutate(record.id)}
              okText="Yes"
              okButtonProps={{
                className: "bg-primary text-white",
              }}
              cancelText="No">
              <Button disabled={record?.name === "Other"} danger>
                Delete
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const { setLinks } = useApplicationStore();

  React.useEffect(() => {
    setLinks([
      renderNavLink("/admin/green-skills/skills", "Skills"),
      renderNavLink("/admin/green-skills/skill-groups", "Skill Group"),
    ]);
  }, [setLinks]);

  return (
    <div>
      <div className="flex items-center gap-3">
        <Link to="/admin/green-skills/skills">
          <ArrowLeftOutlined className="text-backdrop text-lg" />
        </Link>
        <span className="text-2xl font-bold pt-2 text-backdrop mb-3">
          Skill Groups
        </span>
      </div>
      <div className="flex mt-2 justify-start items-start">
        <Button
          className="bg-primary text-white "
          onClick={() => setOpenDrawerAdd(true)}>
          Add New Group
        </Button>
      </div>
      <div className="admin-table">
        <Table
          columns={columns}
          dataSource={skillGroupsQuery.data?.data?.data?.list || []}
          rowKey="id"
          className="mt-4 border"
          loading={skillGroupsQuery.isLoading}
          pagination={false}
        />
      </div>
      {selectedSkillGroup && (
        <EditSkillGroup
          onClose={onCloseDrawerEdit}
          open={openDrawerEdit}
          otherGroupId={
            skillGroupsQuery.data?.data?.data?.list?.find(
              (item) => item.name === "Other"
            )?.id
          }
          skillGroup={selectedSkillGroup}
        />
      )}
      {
        <AddSkillGroupDrawer
          onClose={() => setOpenDrawerAdd(false)}
          open={openDrawerAdd}
          otherGroupId={
            skillGroupsQuery.data?.data?.data?.list?.find(
              (item) => item.name === "Other"
            )?.id
          }
        />
      }
    </div>
  );
};

export default SkillGroup;

import { useMutation, useQuery, useQueryClient } from "react-query";
import httpRequest from "../../../config/config";
import { message } from "antd";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

export const useCurrencies = () => {
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm();
  const [modal, setModal] = useState({ isOpen: false, data: undefined });
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    "list-currencies",
    () => httpRequest.get("/skilledin/currencies"),
    {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "An error occurred while fetching currencies"
        );
      },
    }
  );

  // create
  const { mutateAsync: create, isLoading: isLoadingCreate } = useMutation(
    `currencies-create`,
    (data) => {
      return httpRequest.post(`/skilledin/currencies`, data);
    }
  );

  const handleCreate = async (data) => {
    message.open({
      key: "currencies-create",
      type: "loading",
      content: "creating new currency",
      duration: 0,
    });
    try {
      await create({
        ...data,
        code: data?.code?.label,
        name: data?.code?.value,
        tax: data?.tax || 0,
      });
      message.open({
        key: "currencies-create",
        type: "success",
        content: "created!",
      });

      setModal({ isOpen: false, data: undefined });
      reset();
      queryClient.invalidateQueries("list-currencies");
    } catch (error) {
      message.open({
        key: "currencies-create",
        type: "error",
        content:
          error?.response?.data?.data ||
          "An error occurred while creating new currency",
      });
    }
  };

  // update
  const { mutateAsync: update, isLoading: isLoadingUpdate } = useMutation(
    `currencies-update`,
    ({ data, id }) => {
      return httpRequest.patch(`/skilledin/currencies/${id}`, data);
    }
  );

  const handleUpdate = async (data) => {
    message.open({
      key: "currencies-update",
      type: "loading",
      content: "updating currency",
      duration: 0,
    });
    try {
      await update({
        data: { ...data, tax: data?.tax || 0, name: modal?.data?.name },
        id: modal.data?.id,
      });
      message.open({
        key: "currencies-update",
        type: "success",
        content: "updated!",
      });

      setModal({ isOpen: false, data: undefined });

      queryClient.invalidateQueries("list-currencies").then(() => reset());
    } catch (error) {
      message.open({
        key: "currencies-update",
        type: "error",
        content:
          error?.response?.data?.data ||
          "An error occurred while updating currency",
      });
    }
  };

  // delete
  const { mutateAsync: remove, isLoading: isLoadingRemove } = useMutation(
    `currencies-remove`,
    (id) => {
      return httpRequest.delete(`/skilledin/currencies/${id}`);
    }
  );

  const handleRemove = async (data) => {
    message.open({
      key: "currencies-remove",
      type: "loading",
      content: "removing currency",
      duration: 0,
    });
    try {
      await remove(data);
      message.open({
        key: "currencies-remove",
        type: "success",
        content: "removed!",
      });
      queryClient.invalidateQueries("list-currencies");
    } catch (error) {
      message.open({
        key: "currencies-remove",
        type: "error",
        content:
          error?.response?.data?.data ||
          "An error occurred while removing currency",
      });
    }
  };

  // update availability
  const { mutateAsync: availability, isLoading: isLoadingAvailability } =
    useMutation(`currencies-availability`, (id) => {
      return httpRequest.patch(`/skilledin/currencies/availability/${id}`);
    });

  const handleAvailability = async (data) => {
    message.open({
      key: "currencies-availability",
      type: "loading",
      content: "updating availability",
      duration: 0,
    });
    try {
      await availability(data);
      message.open({
        key: "currencies-availability",
        type: "success",
        content: "updated!",
      });
      queryClient.invalidateQueries("list-currencies");
    } catch (error) {
      message.open({
        key: "currencies-availability",
        type: "error",
        content:
          error?.response?.data?.data ||
          "An error occurred while updating currency",
      });
    }
  };

  // update tax availability
  const { mutateAsync: taxAvailability, isLoading: isLoadingTaxAvailability } =
    useMutation(`currencies-availability`, (id) => {
      return httpRequest.patch(`/skilledin/currencies/availability-tax/${id}`);
    });

  const handleTaxAvailability = async (data) => {
    message.open({
      key: "currencies-tax-availability",
      type: "loading",
      content: "updating tax availability",
      duration: 0,
    });
    try {
      await taxAvailability(data);
      message.open({
        key: "currencies-tax-availability",
        type: "success",
        content: "updated!",
      });
      queryClient.invalidateQueries("list-currencies");
    } catch (error) {
      message.open({
        key: "currencies-tax-availability",
        type: "error",
        content:
          error?.response?.data?.data ||
          "An error occurred while updating currency",
      });
    }
  };

  // update availability
  const { mutateAsync: uploadLogo, isLoading: isLoadinguploadLogo } =
    useMutation(`currencies-availability`, (datas) => {
      const data = new FormData();
      data.append("file", datas.file.originFileObj);
      return httpRequest.patch(`/skilledin/currencies/logo/${datas.id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    });

  const handleuploadLogo = async (data) => {
    message.open({
      key: "currencies-uploadLogo",
      type: "loading",
      content: "updating uploadLogo",
      duration: 0,
    });
    try {
      await uploadLogo(data);
      message.open({
        key: "currencies-uploadLogo",
        type: "success",
        content: "updated!",
      });
      data.success();
      // queryClient.invalidateQueries("list-currencies");
    } catch (error) {
      message.open({
        key: "currencies-uploadLogo",
        type: "error",
        content:
          error?.response?.data?.data ||
          "An error occurred while updating currency",
      });
    }
  };

  useEffect(() => {
    if (modal.data) {
      reset({
        code: modal.data?.code?.toLocaleUpperCase(),
        tax: modal.data?.tax,
        exchange: modal.data?.exchange,
      });
    } else {
      reset({
        code: undefined,
        tax: "",
        exchange: "",
      });
    }
  }, [modal.data, reset]);

  return {
    data: data?.data?.data,
    isLoading,
    modal,
    setModal,
    form: {
      control,
      handleSubmit,
      errors,
      setValue,
      isDirty,
    },
    handleCreate,
    isLoadingCreate,
    handleRemove,
    isLoadingRemove,
    handleUpdate,
    isLoadingUpdate,
    handleAvailability,
    isLoadingAvailability,
    handleTaxAvailability,
    isLoadingTaxAvailability,
    handleuploadLogo,
    isLoadinguploadLogo,
  };
};

import { useEffect, useState } from "react"
import { debounce } from "lodash"
import { useSetFeaturekill, useSkills } from "./useSkills"
import { useQuery } from "react-query"
import httpRequest from "../../../config/config"

export const useListSkills = () => {
  const [openDrawerAdd, setOpenDrawerAdd] = useState(false)
  const [skillSearch, setSkillSearch] = useState(null)
  const [selectedPage, setSelectedPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [schoolSelected, setSchoolSelected] = useState(null)
  // const [trainerMissing, setTrainerMissing] = useState(0);
  const [publishedLevel, setPublishedLevel] = useState(null)
  const [isSchoolAssigned, setIsSchoolAssigned] = useState(1)
  const [groupName, setGroupName] = useState(null)
  // console.log(trainerMissing);

  const { data: skillsData, isLoading: isSkillsLoading } = useSkills({
    limit: itemsPerPage,
    page: selectedPage,
    search: skillSearch,
    categories: groupName,
    school_id: schoolSelected === "All" ? null : schoolSelected,
    // trainer_missing: trainerMissing === "All Skills" ? null : trainerMissing,
    published_level: publishedLevel === "All" ? null : publishedLevel,
    school_not_assigned: isSchoolAssigned,
  })

  const handlePageChange = (page) => {
    setSelectedPage(page)
  }
  const handlePageSizeChange = (current, size) => {
    setSelectedPage(1)
    setItemsPerPage(size)
  }

  const handleOpenDrawerAddSkill = () => {
    setOpenDrawerAdd(true)
  }

  const onCloseDrawerAdd = () => {
    setOpenDrawerAdd(false)
  }

  const handleFilterBySchoolAssigned = (value) => {
    setIsSchoolAssigned(value ? 1 : 0)
  }

  const handleSkillSearch = debounce((value) => {
    setSkillSearch(
      value?.target?.value === null ||
        value?.target?.value?.trim() === "" ||
        value?.target?.value === undefined
        ? null
        : value?.target?.value
    )
  }, 1000)

  const setFeatureSkillMutate = useSetFeaturekill()
  const handleSetFeatureSkill = (id) => {
    setFeatureSkillMutate.mutate({ id: id })
  }

  const group = useSkillGroups()
  const handleSelect = (v) => {
    setGroupName(v?.label || null)
  }

  return {
    openDrawerAdd,
    skillSearch,
    handleOpenDrawerAddSkill,
    onCloseDrawerAdd,
    selectedPage,
    handlePageChange,
    handlePageSizeChange,
    handleFilterBySchoolAssigned,
    itemsPerPage,
    setItemsPerPage,
    handleSkillSearch,
    schoolSelected,
    setSchoolSelected,
    isSchoolAssigned,
    // trainerMissing,
    // setTrainerMissing,
    publishedLevel,
    setPublishedLevel,
    skillsData,
    isSkillsLoading,
    handleSetFeatureSkill,
    group: { data: group, action: handleSelect },
  }
}

const useSkillGroups = () => {
  const [params, setParams] = useState({
    limit: 20,
    page: 1,
    name: null,
  })
  const [total, setTotal] = useState(0)
  const [dataList, setDataList] = useState([])

  const { data, isLoading } = useQuery(["green-skill-groups", params], () =>
    httpRequest.get("/skilledin/green-skill-groups", {
      params,
    })
  )
  const handleSearch = debounce((e) => {
    setParams((prevParams) => ({ ...prevParams, name: e, page: 1 }))
  }, 1000)

  const handleClear = (e) => {
    if (params?.page !== 1 || params?.name !== "") {
      setParams((prevParams) => ({ ...prevParams, name: "", page: 1 }))
    }
  }

  const handleScroll = (e) => {
    const { target } = e
    const maxScrollPosition =
      target.scrollHeight - target.clientHeight - target.clientHeight * 0.1

    if (
      target.scrollTop >= maxScrollPosition &&
      !isLoading &&
      total !== dataList.length
    ) {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }))
    }
  }
  useEffect(() => {
    if (!isLoading) {
      if (params?.page === 1) {
        setDataList(data?.data?.data?.list)
        setTotal(data?.data?.data?.total_data)
      } else {
        setDataList((prev) => [...prev, ...(data?.data?.data?.list || [])])
        setTotal(data?.data?.data?.total_data)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.data?.list])
  return {
    dataList,
    setDataList,
    isLoading,
    handleSearch,
    handleClear,
    handleScroll,
    params,
  }
}

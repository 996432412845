import httpRequest from "../../../config/config";
import { ApiResponsePaginatedInfinite } from "../../../types/ApiResponse";

export interface ThisWeeksDiscussionPost {
  description: string;
  display_at: number;
  expires_at: number;
  hashtags: string[] | string;
  title: string;
}

export interface UpdateCommunityData {
  description?: string;
  display_at?: number;
  expires_at?: number;
  hashtags?: string[];
  title?: string;
}

export interface ICommunityUser {
  id: string;
  avatar: string;
  username: string;
  location: string;
}

export interface CommunityComment {
  body: string;
  created_at: string;
  id: string;
  is_liked: boolean;
  post_id: string;
  total_responses: number;
  updated_at: string | null;
  user: ICommunityUser;
  user_id: string;
}

export interface ThisWeeksDiscussion {
  created_at: string;
  description: string;
  display_at: string;
  expired_at: string;
  hashtags: string[] | null;
  id: string;
  title: string;
  total_comments: number;
  total_reaction: number;
  total_views: number;
}

export interface ICommunityComment {
  id: string;
  user_id: string;
  post_id: string;
  body: string;
  created_at: string;
  updated_at: string | null;
  user: ICommunityUser;
  is_liked: boolean;
}

export const getDiscussionList = (
  limit: number,
  page: number,
  filter?: "upcoming" | "ongoing" | "expire"
) => {
  return httpRequest.get<ApiResponsePaginatedInfinite<ThisWeeksDiscussion>>(
    "skilledin/community-topic-discussions",
    {
      params: { filter, limit, page },
    }
  );
};

export const createDiscussion = (data: ThisWeeksDiscussionPost) => {
  return httpRequest.post("skilledin/community-topic-discussions", data);
};

export const getTopicDiscussions = (discussion_id: string) => {
  return httpRequest.get(
    `skilledin/community-topic-discussions/${discussion_id}`
  );
};

export const deleteTopicDiscussion = (discussion_id: string) => {
  return httpRequest.delete(
    `skilledin/community-topic-discussions/${discussion_id}`
  );
};

export const updateDiscussion = (data: ThisWeeksDiscussionPost, id: string) => {
  return httpRequest.patch(`skilledin/community-topic-discussions/${id}`, data);
};

export const getTopicComments = (id: string, limit: number, page: number) => {
  return httpRequest.get<ApiResponsePaginatedInfinite<ICommunityComment>>(
    `skilledin/community-topic-discussions/${id}/comments`,
    {
      params: {
        limit,
        page,
      },
    }
  );
};

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEditJobSector } from "./useJobSectors";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
const schema = yup.object().shape({
  name: yup.string().required("Job Name is required"),
  description: yup.string().required("Description is required"),
  image: yup.mixed().nullable(),
  // .test("fileType", "Only jpg or png files are allowed", (value) => {
  //   console.log("value:", value);
  //   return (
  //     value === null ||
  //     typeof value?.url === "string" ||
  //     value?.type === "image/jpeg" ||
  //     value?.type === "image/png"
  //   );
  // })
  // .test(
  //   "fileSize",
  //   "The maximum file size is 2 MB",
  //   (value) =>
  //     value === null ||
  //     typeof value?.url === "string" ||
  //     value?.size <= 2 * 1024 * 1024
  // ),
});

export const useEditJobSectorDrawer = (onClose, dataToEdit) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("name", dataToEdit?.name);
    setValue("description", dataToEdit?.description);
    setValue("slug", dataToEdit?.slug);
    setValue("image", {
      uid: "-1",
      name: dataToEdit?.image?.split("/")?.[
        dataToEdit?.image?.split("/")?.length - 1
      ],
      status: "done",
      url: dataToEdit?.image,
    });
  }, [
    setValue,
    dataToEdit?.name,
    dataToEdit?.description,
    dataToEdit?.slug,
    dataToEdit?.image,
  ]);

  const editJobMutate = useEditJobSector();
  const queryClient = useQueryClient();
  console.log("values:", getValues());

  const onSaveSubmit = async (data) => {
    try {
      await editJobMutate.mutateAsync(data, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["admin-job-sectors"]);
          reset();
          onClose();
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess((field) => field.onChange(file));
    }, 0);
  };

  return {
    handleSubmit,
    control,
    errors,
    isDirty,
    onSaveSubmit,
    editJobMutate,
    customRequest,
  };
};

function dataURLtoFile(dataURL, fileName) {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
}

export function beforeUpload(file) {
  const allowedTypes = ["image/jpeg", "image/png"];
  const maxSize = 2 * 1024 * 1024; // 2MB
  return new Promise((resolve, reject) => {
    // Check file type
    if (!allowedTypes.includes(file.type)) {
      reject(new Error("Invalid file type"));
      return;
    }

    // Check file size
    if (file.size > maxSize) {
      reject(new Error("File size exceeded"));
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 800;

        let width = img.width;
        let height = img.height;

        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }

        canvas.width = width;
        canvas.height = height;

        ctx?.drawImage(img, 0, 0, width, height);

        const compressedDataURL = canvas.toDataURL(file.type); // Use original image format
        const compressedFile = dataURLtoFile(compressedDataURL, file.name);

        resolve(compressedFile);
      };
    };

    reader.onerror = (error) => reject(error);
  });
}

import React, { useState } from "react";
import { Drawer, Input, Select, Button, Tag } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSingleSkillGroup, useAddSkillGroup } from "../hooks/useSkillGroup";

const schema = yup.object().shape({
  name: yup.string().required("Skill Group Name is required"),
});
export function AddSkillGroupDrawer({ onClose, open, otherGroupId }) {
  const otherGroupQuery = useSingleSkillGroup(otherGroupId);
  const addSkillGroup = useAddSkillGroup();
  //   const [skillSearch, setSkillSearch] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSkillSelect = (option) => {
    setSelectedSkills((prevSkills) => [...prevSkills, option]);
  };
  const handleRemoveSkill = (skillToRemove) => {
    setSelectedSkills((prevSkills) =>
      prevSkills.filter((skill) => skill.id !== skillToRemove.id)
    );
  };

  const onSaveSubmit = async (data) => {
    addSkillGroup.mutate({
      ...data,
      skills_id: selectedSkills.map((group) => group?.id),
    });
    onClose();
  };
  return (
    <Drawer
      closable={false}
      placement='right'
      onClose={onClose}
      size='large'
      open={open}>
      <p className='font-bold text-xl text-backdrop mb-5'>Add Skill Group</p>
      <form onSubmit={handleSubmit(onSaveSubmit)}>
        <div className='mb-5 space-y-6'>
          <div className='flex flex-col items-center-justify-start gap-2'>
            <Controller
              control={control}
              name='name'
              render={({ field }) => (
                <Input
                  placeholder='Skill Group Name'
                  {...field}
                />
              )}
            />
            {errors.name && (
              <p className='text-red-500'>{errors.name.message}</p>
            )}
          </div>
          <div className='flex items-center gap-4 w-full'>
            <div className='flex items-center gap-4 w-full'>
              <div className='flex flex-col items-center-justify-start gap-2 w-full'>
                {otherGroupQuery.data?.data?.data?.skills?.length > 0 && (
                  <Controller
                    control={control}
                    className='w-full'
                    name='skills_id'
                    value={[]}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className='w-full min-w-[200px]'
                        mode='multiple'
                        value={[]}
                        showSearch
                        filterOption={(input, option) =>
                          option.name
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        fieldNames={{ label: "name", value: "id" }}
                        options={otherGroupQuery.data?.data?.data?.skills?.map(
                          (skill) => ({
                            id: skill?.id,
                            name: skill?.name,
                          })
                        )}
                        placeholder='Select Skills'
                        loading={otherGroupQuery?.isLoading}
                        notFoundContent={
                          otherGroupQuery?.isError
                            ? "Error fetching skills"
                            : "No skills found"
                        }
                        onSelect={(value, option) =>
                          handleSkillSelect(option)
                        }></Select>
                    )}
                  />
                )}

                <div className='mt-2  flex justify-start gap-2 flex-wrap'>
                  {selectedSkills.map((skill) => (
                    <Tag
                      key={skill.id}
                      className='bg-[#D4F5C5]'
                      closable
                      onClose={() => handleRemoveSkill(skill)}>
                      {skill.name}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-end gap-3'>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            loading={addSkillGroup.isLoading}
            type='primary'
            className='bg-primary'
            htmlType='submit'>
            Save
          </Button>
        </div>
      </form>
    </Drawer>
  );
}

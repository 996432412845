import React from "react";
import { Drawer, Input, Button, Select } from "antd";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useSkillGroupInfinite,
  useEditSkill,
  useDataSource,
  useCreateDataSource,
} from "../hooks/useSkills";
import { useQueryClient } from "react-query";
import { formats, modules } from "../../../common/components/RichText";
import { PlusOutlined } from "@ant-design/icons";
import { beforeUpload } from "../utils/imageUploadFuncs";
import ReactQuill from "react-quill";
import { UploadWithFocus } from "../../../common/components/UploadWithFocus";

const schema = yup.object().shape({
  name: yup.string().required("Skill Name is required"),
  group_id: yup.string().required("Skill Group is required"),
  description: yup.string().required("Description is required"),
  price_per_level: yup.number().typeError("Price must be a number"),
  pre_discount_price: yup
    .number()
    .typeError("Pre Discount Price must be a number"),
  typical_activities: yup.string(),
  success_factor: yup.string(),
  data_source: yup.string().required("Data Source is required"),
  image: yup
    .mixed()
    .nullable()
    .test(
      "fileType",
      "Only jpg or png files are allowed",
      (value) =>
        value === null ||
        typeof value?.url === "string" ||
        value.type === "image/jpeg" ||
        value.type === "image/png"
    )
    .test(
      "fileSize",
      "The maximum file size is 2 MB",
      (value) =>
        value === null ||
        typeof value?.url === "string" ||
        value.size <= 2 * 1024 * 1024
    ),
});

export default function EditSkillDrawer({ onClose, open, skill }) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: skill?.name,
      group_id: skill?.groups?.id,
      description: skill?.description,
      price_per_level: skill?.price_per_level,
      pre_discount_price: skill?.pre_discount_price,
      typical_activities: skill?.typical_activities,
      data_source: skill?.data_source,
      success_factor: skill?.success_factor,
      image: {
        uid: "-1",
        name: skill?.image?.split("/")?.[skill?.image?.split("/")?.length - 1],
        status: "done",
        url: skill?.image,
      },
    },
  });
  const queryClient = useQueryClient();
  const { data, isLoading, fetchNextPage } = useSkillGroupInfinite();
  // this is not really being used, onChange on component is the one actually being called
  // we define it here to stop the automatic call that component does
  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess((field) => field.onChange(file));
    }, 0);
  };

  const handleScroll = (e) => {
    const { target } = e;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      !isLoading
    ) {
      fetchNextPage();
    }
  };
  const editSkillMutate = useEditSkill();
  const [dataSourceSearch, setDataSourceSearch] = React.useState(null);
  const createDataSourceMutate = useCreateDataSource();
  const { data: dataSource } = useDataSource();

  const onSaveSubmit = async (data) => {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (key === "image") {
          if (value?.uid === "-1") {
            formData.append(key, null);
            continue;
          } else {
            formData.append("file", value);
            continue;
          }
        }
        formData.append(key, value);
      }
      await editSkillMutate.mutateAsync(
        { id: skill?.id, skillData: formData },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([
              "admin-skills-detail",
              skill?.id,
            ]);
            onClose();
            reset();
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      closable={false}
      placement='right'
      onClose={onClose}
      size='large'
      open={open}>
      <p className='font-bold text-xl text-backdrop mb-5'>Edit a Skill</p>
      <form onSubmit={handleSubmit(onSaveSubmit)}>
        <div className='mb-5 space-y-6'>
          <div className='w-[102px] h-full'>
            {/* {!image && (
              <Image
                src={skill?.image}
                className='w-[102px] h-[102px] object-cover'
                preview={false}
              />
            )} */}
            <Controller
              name='image'
              control={control}
              render={({ field }) => {
                return (
                  <UploadWithFocus
                    {...field}
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                    }}
                    beforeUpload={(file) => {
                      beforeUpload(file).then((filez) => {
                        if (filez) {
                          field.onChange(filez);
                        }
                      });
                    }}
                    onChange={async (file) => {
                      field.onChange();
                    }}
                    fileList={field.value ? [field.value] : []}
                    onRemove={() => field.onChange(null)}
                    maxCount={1}
                    customRequest={customRequest}
                    className='h-full'
                    listType='picture-card'>
                    <div>
                      {<PlusOutlined />}
                      <div
                        style={{
                          marginTop: 8,
                        }}>
                        Upload
                      </div>
                    </div>
                  </UploadWithFocus>
                );
              }}
            />

            {errors.image && <p>{errors.image.message}</p>}
          </div>
          <div className='flex flex-col items-center-justify-start gap-2'>
            <Controller
              control={control}
              name='name'
              render={({ field }) => (
                <Input
                  placeholder='Skill Name'
                  {...field}
                />
              )}
            />
            {errors.name && (
              <p className='text-red-500'>{errors.name.message}</p>
            )}
          </div>
          <div className='flex items-center gap-4'>
            <div className='flex flex-col items-center-justify-start gap-2 w-1/2'>
              <Controller
                control={control}
                name='price_per_level'
                render={({ field }) => (
                  <Input
                    placeholder='Price per level'
                    {...field}
                  />
                )}
              />
              {errors.price_per_level && (
                <p className='text-red-500'>{errors.price_per_level.message}</p>
              )}
            </div>
            <div className='flex flex-col items-center-justify-start gap-2 w-1/2'>
              <Controller
                control={control}
                name='pre_discount_price'
                render={({ field }) => (
                  <Input
                    placeholder='Pre Discount Price (optional)'
                    className='!bg-secondaryYellow !text-backdrop'
                    {...field}
                  />
                )}
              />
              {errors.pre_discount_price && (
                <p className='text-red-500'>
                  {errors.pre_discount_price.message}
                </p>
              )}
            </div>
          </div>

          <div className='flex items-center gap-4'>
            <div className='flex flex-col items-center-justify-start gap-2 w-1/2'>
              <Controller
                control={control}
                className='w-full'
                name='data_source'
                render={({ field }) => (
                  <Select
                    placeholder={"Data Source"}
                    {...field}
                    loading={isLoading}
                    showSearch
                    searchValue={dataSourceSearch}
                    onSearch={(value) => {
                      setDataSourceSearch(value);
                    }}
                    notFoundContent={
                      <Button
                        className='w-full'
                        onClick={async () => {
                          const data = await createDataSourceMutate.mutateAsync(
                            { name: dataSourceSearch },
                            {
                              onSuccess: (data) => {
                                setDataSourceSearch(data?.data?.data?.name);
                              },
                            }
                          );
                          field.onChange(data);
                        }}>
                        Click To Add : {dataSourceSearch}
                      </Button>
                    }
                    onChange={async (value) => {
                      field.onChange(value);
                    }}
                    onPopupScroll={handleScroll}>
                    {dataSource?.data?.data?.map((data) => (
                      <Select.Option
                        key={data}
                        value={data}>
                        {data}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
              {errors?.group_id && (
                <p className='text-red-500'>{errors?.group_id?.message}</p>
              )}
            </div>
            <div className='flex flex-col items-center-justify-start gap-2 w-1/2'>
              <Controller
                control={control}
                className='w-full'
                name='group_id'
                render={({ field }) => (
                  <Select
                    defaultValue={skill?.groups?.name}
                    // placeholder={skill?.groups?.name}
                    {...field}
                    loading={isLoading}
                    onChange={(value) => field.onChange(value)}
                    onPopupScroll={handleScroll}>
                    {data?.pages.map((page) =>
                      page?.data?.data?.list.map((group) => (
                        <Select.Option
                          key={group.id}
                          value={group.id}>
                          {group.name}
                        </Select.Option>
                      ))
                    )}
                  </Select>
                )}
              />
              {errors?.group_id && (
                <p className='text-red-500'>{errors?.group_id?.message}</p>
              )}
            </div>
          </div>

          <div className='flex flex-col items-center-justify-start gap-2'>
            <Controller
              control={control}
              name='description'
              render={({ field }) => (
                <Input.TextArea
                  rows={5}
                  style={{ resize: "none" }}
                  placeholder='Description'
                  {...field}
                />
              )}
            />
            {errors.description && (
              <p className='text-red-500'>{errors.description.message}</p>
            )}
          </div>
          <div className=''>
            <div className='text-backdrop font-semibold text-lg'>
              Typical Activities
            </div>
            <Controller
              control={control}
              name='typical_activities'
              render={({ field }) => (
                <ReactQuill
                  {...field}
                  className={`h-40 pb-[2.43rem] mb-1 mt-5 font-default font-normal`}
                  placeholder={
                    "Type lesson content or include a link to a private video on YouTube or Vimeo"
                  }
                  formats={formats}
                  modules={modules}
                />
                // <RichText
                //   {...field}
                //   handleChange={field.onChange}
                //   value={field.value}
                //   className={`h-40 pb-[2.43rem] mb-1 mt-5 font-default font-normal`}
                //   placeholder={
                //     "Type lesson content or include a link to a private video on YouTube or Vimeo"
                //   }
                // />
              )}
            />
            {errors.typical_activities && (
              <p className='text-red-500'>
                {errors.typical_activities.message}
              </p>
            )}
          </div>
          <div className=''>
            <div className='text-backdrop font-semibold text-lg'>
              Success Factors
            </div>
            <Controller
              control={control}
              name='success_factor'
              render={({ field }) => (
                <ReactQuill
                  {...field}
                  className={`h-40 pb-[2.43rem] mb-1 mt-5 font-default font-normal`}
                  placeholder={
                    "Type lesson content or include a link to a private video on YouTube or Vimeo"
                  }
                  formats={formats}
                  modules={modules}
                />
              )}
            />
            {errors.success_factor && (
              <p className='text-red-500'>{errors.success_factor.message}</p>
            )}
          </div>
        </div>
        <div className='flex items-center justify-end gap-3'>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            loading={false}
            type='primary'
            className='bg-primary'
            htmlType='submit'>
            Save
          </Button>
        </div>
      </form>
    </Drawer>
  );
}
